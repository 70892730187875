import { useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync, fetchPublicIdoUserDataAsync } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { getAddress } from 'utils/addressHelpers'
import { getBep20Contract } from 'utils/contractHelpers'
import { Address } from 'config/constants/types'
import { useMasterchef, useIFAMasterContract, useIFASaleContract, useSolvMarketContract } from './useContract'
import useWeb3 from './useWeb3'

// Approve a Farm
export const useApprove = (lpContract: Contract) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, masterChefContract])

  return { onApprove: handleApprove }
}

// Approve an IFO
export const useIfoApprove = (tokenContract: Contract, spenderAddress: string) => {
  const { account } = useWeb3React()
  const onApprove = useCallback(async () => {
    const tx = await tokenContract.methods.approve(spenderAddress, ethers.constants.MaxUint256).send({ from: account })
    return tx
  }, [account, spenderAddress, tokenContract])

  return onApprove
}

// Approve a Farm
export const useIdoApprove = (masterAddress: string, address: Address) => {
  const web3 = useWeb3()
  const { account, chainId } = useWeb3React()
  const tokenAdress = getAddress(address, chainId)
  const tokenContract = getBep20Contract(tokenAdress, web3)
  const dispatch = useDispatch()
  const IFAMasterContract = useIFAMasterContract(masterAddress)
  const [isLoading, setIsLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    try {
      setIsLoading(true)
      const tx = await approve(tokenContract, IFAMasterContract, account)
      dispatch(fetchPublicIdoUserDataAsync(account, chainId))
      setIsLoading(false)
      return tx
    } catch (e) {
      setIsLoading(false)
      return false
    }
  }, [account, dispatch, tokenContract, IFAMasterContract, chainId])

  return { onApprove: handleApprove, isLoading }
}

export const useSolvSaleApprove = (address: Address, marketAddress: string) => {
  const web3 = useWeb3()
  const { account, chainId } = useWeb3React()
  const dispatch = useDispatch()
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const tokenAdress = getAddress(address, chainId)
  const tokenContract = getBep20Contract(tokenAdress, web3)
  const [isLoading, setIsLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    try {
      setIsLoading(true)
      const tx = await approve(tokenContract, solvMarketContract, account)
      dispatch(fetchPublicIdoUserDataAsync(account, chainId))
      setIsLoading(false)
      return tx
    } catch (e) {
      setIsLoading(false)
      return false
    }
  }, [account, dispatch, tokenContract, solvMarketContract, setIsLoading, chainId])

  return { onApprove: handleApprove, isLoading }
}

export const useSaleApprove = (address: Address, saleAddress: string) => {
  const web3 = useWeb3()
  const { account, chainId } = useWeb3React()
  const tokenAdress = getAddress(address, chainId)
  const tokenContract = getBep20Contract(tokenAdress, web3)
  const dispatch = useDispatch()
  const IFASaleContract = useIFASaleContract(saleAddress)
  const [isLoading, setIsLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    try {
      setIsLoading(true)
      const tx = await approve(tokenContract, IFASaleContract, account)
      dispatch(fetchPublicIdoUserDataAsync(account, chainId))
      setIsLoading(false)
      return tx
    } catch (e) {
      setIsLoading(false)
      return false
    }
  }, [account, dispatch, tokenContract, IFASaleContract, chainId])

  return { onApprove: handleApprove, isLoading }
}
