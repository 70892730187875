import React from 'react'
import { Modal } from 'uikit'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { sizes } from 'uikit/components/Heading/types'

import { Box } from '@material-ui/core'
import { useTrackTotalStake } from 'hooks/useIFAMaster'
import { convertFromWei } from 'utils/formatBalance'
import { BorderedInfoCard } from './StyledComponent'

interface EstimatedAllocationModalProps {
  onDismiss?: () => void
  masterAddress: string
  trackId: number
  tokenImage: string
}

const ModalContent = styled.div`
  margin-bottom: 16px;
  max-width: 350px;
  max-height: 400px;
`

const EstimatedNumber = styled.div`
  color: #3b4346;
  font-size: 34px;
  font-weight: 500;
`

const AllocationDesc = styled.div`
  color: #3b4346;
  font-size: 13px;
  line-height: 16px;
`

const EstimatedAllocationModal: React.FC<EstimatedAllocationModalProps> = ({
  onDismiss,
  masterAddress,
  trackId,
  tokenImage,
}) => {
  const totalStaked = new BigNumber(convertFromWei(useTrackTotalStake(masterAddress, trackId)))

  return (
    <Modal
      title="Estimated Allocation"
      onDismiss={onDismiss}
      style={{ borderRadius: '16px' }}
      bodyPadding="0px 16px 16px 16px"
      titleFontSize={sizes.LG}
    >
      <ModalContent>
        <BorderedInfoCard>
          <Box>Total staked in this project (real-time)</Box>
          <Box marginTop={2} display="flex" flexDirection="row">
            <img src={tokenImage} alt="tokenimg" width="36px" />
            <Box marginLeft={2} />
            <EstimatedNumber>
              {totalStaked.isGreaterThan(0) ? totalStaked.decimalPlaces(2).toNumber().toLocaleString() : 'Loading...'}
            </EstimatedNumber>
          </Box>
        </BorderedInfoCard>
        <Box marginTop={2}>
          <AllocationDesc>
            Your allocation can increase or decrease, relative to the amount and time that it remains staked by other
            users.
          </AllocationDesc>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default EstimatedAllocationModal
