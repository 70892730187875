import { isAddress } from '@ethersproject/address'
import { idosConfig } from 'config/constants'
import { Chain } from 'config/constants/types'
import { BonusSaleConfig, IDOConfig, IDOType, PublicSaleConfig } from 'state/types'

export const getIdos = async (): Promise<IDOConfig[]> => {
  const idos = await idosConfig()

  // sanity check of addresses
  return idos.filter((idoConfig) => {
    if (idoConfig.isDisabled) return true
    if (idoConfig.type === IDOType.PUBLIC_SALE) {
      const publicSale = idoConfig as PublicSaleConfig
      if (publicSale.saleAddress) {
        return isAddress(publicSale.masterAddress) && isAddress(publicSale.saleAddress)
      }
      return isAddress(publicSale.masterAddress)
    }
    return true
  })
}

export const getBonusSales = async (): Promise<BonusSaleConfig[]> => {
  const data = await getIdos()
  return data
    .filter((val) => val.type === IDOType.BONUS_SALE)
    .map((ido) => {
      const bonusIdo = ido as BonusSaleConfig
      return bonusIdo
    })
}

export const getPublicSales = async (): Promise<PublicSaleConfig[]> => {
  const data = await getIdos()
  return data
    .filter((val) => val.type === IDOType.PUBLIC_SALE)
    .map((ido) => {
      const publicIdo = ido as PublicSaleConfig
      return publicIdo
    })
}

export const getActiveIdos = (idos: IDOConfig[]): IDOConfig[] => {
  return idos.filter((val) => !val.isDisabled)
}

export const filterNullSaleAddress = (idos: PublicSaleConfig[]): PublicSaleConfig[] => {
  return idos.filter((val) => val.saleAddress)
}

export const getIdosByChain = (idos: IDOConfig[], chainId: Chain): IDOConfig[] => {
  return idos.filter((val) => val.chainId === chainId)
}

export default getIdos
