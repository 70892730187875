import React from 'react'
import { Box, Typography } from '@material-ui/core'
import UnlockButton from 'components/UnlockButton'

type LimitedAccessProps = {
  isConnected: boolean
}

const LimitedAccess: React.FC<LimitedAccessProps> = ({ isConnected }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={22}>
      <Typography color="textSecondary" variant="h3" align="center">
        {isConnected ? 'Oops! Limited access.' : 'Please connect to your wallet.'}
      </Typography>
      <Box marginTop={4}>
        <Typography color="textSecondary" variant="body2" align="center">
          You can only access this pool if your address is whitelisted.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" marginTop={11}>
        <img src="/images/idos/gleam-rocket.svg" width="100px" alt="rocket" />
      </Box>
      {!isConnected && (
        <Box marginTop={9}>
          {' '}
          <UnlockButton />
        </Box>
      )}
    </Box>
  )
}

export default LimitedAccess
