import { Button, withStyles, Box } from '@material-ui/core'

const RoundedButton = withStyles({
  root: {
    borderRadius: '20px',
    width: '133px',
    height: '40px',
  },
})(Button)

const VerifiedLabel = withStyles((theme) => ({
  root: {
    borderRadius: '20px',
    height: '40px',
    background: '#D900FF',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 500,
    padding: '10px 14px',
  },
}))(Box)

const BorderedInfoCard = withStyles({
  root: {
    border: '1px solid #E9EBEC',
    borderRadius: '8px',
    padding: '16px',
  },
})(Box)

export { RoundedButton, VerifiedLabel, BorderedInfoCard }
