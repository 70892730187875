import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { TokenClass } from 'swap-sdk/entities/token'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'

import { useSingleCallResult } from '../state/multicall/hooks'
import { useTokenContract } from './useContract'

function useTokenAllowance(token?: TokenClass, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address)
  const { chainId } = useWeb3React()
  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, chainId, 'allowance', inputs).result

  return useMemo(() => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined), [
    token,
    allowance,
  ])
}

export default useTokenAllowance
