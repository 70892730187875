import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useToast } from 'state/hooks'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'
import SolvVoucherAbi from 'config/abi/SolvICVoucher.json'
import VestingPoolAbi from 'config/abi/SolvVestingPool.json'
import { useSolvMarketContract, useSolvVoucherContract } from './useContract'
import useRefresh from './useRefresh'

// Returns current price of tokens in sale, given input saleId number
export const useCurrentPrice = (saleId: number, marketAddress: string): BigNumber => {
  const [currentPrice, setCurrentPrice] = useState(new BigNumber(0))
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const { threeSecondsRefresh } = useRefresh()

  useEffect(() => {
    const getPrice = async () => {
      try {
        let res = await solvMarketContract.methods.getPrice(saleId)
        res = await res.call()
        setCurrentPrice(new BigNumber(res))
      } catch (err) {
        console.error(err)
      }
    }
    getPrice()
  }, [threeSecondsRefresh, saleId, solvMarketContract.methods])

  return currentPrice
}

// Returns number of nft token ids a user has (number of discrete purchases made by user)
// If balance = 0, display no buttons
// If balance = 1, display claim button
// If balance = 2, display merge button
export const useSolvNftBalance = (voucherAddress: string) => {
  const [balance, setBalance] = useState(0)
  const solvVoucherContract = useSolvVoucherContract(voucherAddress)
  const { chainId, account } = useWeb3React()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const getBalance = async () => {
      try {
        let num = await solvVoucherContract.methods.balanceOf(account)
        num = await num.call()
        const tokenIdCalls = Array(parseInt(num))
          .fill(0)
          .map((_, idx) => ({
            address: voucherAddress,
            name: 'tokenOfOwnerByIndex',
            params: [account, idx],
          }))
        let res = await multicall(SolvVoucherAbi.abi, tokenIdCalls, chainId)
        const claimableAmountCalls = res.map((arr) => ({
          address: voucherAddress,
          name: 'claimableAmount',
          params: [arr[0]],
        }))
        res = await multicall(VestingPoolAbi.abi, claimableAmountCalls, chainId)
        setBalance(res.filter((amt) => parseInt(amt[0].toString()) > 0).length)
      } catch (err) {
        console.error(err)
      }
    }
    getBalance()
  }, [account, solvVoucherContract.methods, fastRefresh, voucherAddress, chainId])

  return balance
}

// Returns the total number of underlying auction tokens that are claimable by the user
// If user does merge + claim, he will receive this many underlying tokens
// Tokens currently vesting are not counted
export const useTotalAmountClaimable = (voucherAddress: string) => {
  const [amountClaimable, setAmountClaimable] = useState(new BigNumber(0))
  const { chainId, account } = useWeb3React()
  const solvVoucherContract = useSolvVoucherContract(voucherAddress)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const getTotalClaimable = async () => {
      try {
        let num = await solvVoucherContract.methods.balanceOf(account)
        num = await num.call()
        const tokenIdCalls = Array(parseInt(num))
          .fill(0)
          .map((_, idx) => ({
            address: voucherAddress,
            name: 'tokenOfOwnerByIndex',
            params: [account, idx],
          }))
        let res = await multicall(SolvVoucherAbi.abi, tokenIdCalls, chainId)
        const claimableAmountCalls = res.map((arr) => ({
          address: voucherAddress,
          name: 'claimableAmount',
          params: [arr[0]],
        }))
        res = await multicall(VestingPoolAbi.abi, claimableAmountCalls, chainId)
        setAmountClaimable(res.reduce((acc, elem) => acc.plus(new BigNumber(elem)), new BigNumber(0)))
      } catch (err) {
        console.error(err)
      }
    }
    getTotalClaimable()
  }, [account, solvVoucherContract.methods, fastRefresh, voucherAddress, chainId])

  return amountClaimable
}

// Same as above, but tokens that are vesting are counted
export const useTotalAmountOwned = (voucherAddress: string) => {
  const [totalAmountOwned, setTotalAmountOwned] = useState(new BigNumber(0))
  const { chainId, account } = useWeb3React()
  const solvVoucherContract = useSolvVoucherContract(voucherAddress)

  useEffect(() => {
    const getTotalOwned = async () => {
      try {
        let num = await solvVoucherContract.methods.balanceOf(account)
        num = await num.call()
        const tokenIdCalls = Array(parseInt(num))
          .fill(0)
          .map((_, idx) => ({
            address: voucherAddress,
            name: 'tokenOfOwnerByIndex',
            params: [account, idx],
          }))
        let res = await multicall(SolvVoucherAbi.abi, tokenIdCalls, chainId)
        const claimableAmountCalls = res.map((arr) => ({
          address: voucherAddress,
          name: 'vestingById',
          params: [arr[0]],
        }))
        res = await multicall(VestingPoolAbi.abi, claimableAmountCalls, chainId)
        setTotalAmountOwned(
          res.reduce((accum, elem) => {
            const { principal } = elem
            return principal.add(accum.toString())
          }, new BigNumber(0)),
        )
      } catch (err) {
        console.error(err)
      }
    }
    getTotalOwned()
  }, [account, solvVoucherContract.methods, voucherAddress, chainId])

  return totalAmountOwned
}

// Use this when user sets fixed input amount of tokens to spend. When prices are declining, user can potentially get more output than quoted
export const usePurchaseByInput = (saleId: number, marketAddress: string) => {
  const { account } = useWeb3React()
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const { toastError } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handlePurchaseByInput = useCallback(
    async (inputAmount: string, onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        const tx = await solvMarketContract.methods.buyByAmount(saleId, inputAmount).send({ from: account })
        setIsLoading(false)
        onSuccess()
        return tx.transactionHash
      } catch (err: any) {
        console.error(err)
        setIsLoading(false)
        toastError('Error', err.message)
        return null
      }
    },
    [account, solvMarketContract.methods, toastError, saleId],
  )

  return { onPurchase: handlePurchaseByInput, isLoading }
}

// Use this when user sets fixed output of tokens to buy. When prices are declining, user can potentially spend less input than quoted
export const usePurchaseByOutput = (saleId: number, marketAddress: string) => {
  const { account } = useWeb3React()
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const { toastError } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handlePurchaseByOutput = useCallback(
    async (outputAmount: string, onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        const tx = await solvMarketContract.methods.buyByUnits(saleId, outputAmount).send({ from: account })
        setIsLoading(false)
        onSuccess()
        return tx.transactionHash
      } catch (err: any) {
        console.error(err)
        setIsLoading(false)
        toastError('Error', err.message)
        return null
      }
    },
    [account, solvMarketContract.methods, toastError, saleId],
  )

  return { onPurchase: handlePurchaseByOutput, isLoading }
}

// Returns a handle for user to perform merge operation over all their tokenIds. After merging, user will only need to perform claim once
export const useMerge = (voucherAddress: string) => {
  const { chainId, account } = useWeb3React()
  const solvVoucherContract = useSolvVoucherContract(voucherAddress)
  const { toastError } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handleMerge = useCallback(
    async (onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        let num = await solvVoucherContract.methods.balanceOf(account)
        num = await num.call()
        const calls = Array(parseInt(num))
          .fill(0)
          .map((_, idx) => ({
            address: voucherAddress,
            name: 'tokenOfOwnerByIndex',
            params: [account, idx],
          }))
        const res = await multicall(SolvVoucherAbi.abi, calls, chainId)
        const tokenIds = res.map((arr) => arr[0])
        const first = tokenIds.shift()
        await solvVoucherContract.methods.merge(tokenIds, first).send({ from: account })
        setIsLoading(false)
        onSuccess()
      } catch (err: any) {
        setIsLoading(false)
        toastError('Error', err.message)
        console.error(err)
      }
    },
    [account, solvVoucherContract.methods, toastError, voucherAddress, chainId],
  )

  return { onMerge: handleMerge, isLoading }
}

// Returns a handle that claims all tokens associated with the *first tokenId* of an account
export const useClaim = (voucherAddress: string) => {
  const { account } = useWeb3React()
  const solvVoucherContract = useSolvVoucherContract(voucherAddress)
  const { toastError } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const handleClaimAll = useCallback(
    async (onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        let num = await solvVoucherContract.methods.tokenOfOwnerByIndex(account, 0)
        num = await num.call()
        const tx = await solvVoucherContract.methods.claimAll(num).send({ from: account })
        setIsLoading(false)
        onSuccess()
        return tx.transactionHash
      } catch (err: any) {
        console.info(err)
        setIsLoading(false)
        toastError('Error', err.message)
        return null
      }
    },
    [account, solvVoucherContract.methods, toastError],
  )

  return { onClaim: handleClaimAll, isLoading }
}

// for purchasedUnits in market contract to update, we need to set max tokens purchaseable per user
export const usePurchasedUnits = (saleId: number, marketAddress: string): BigNumber => {
  const { account } = useWeb3React()
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const [purchasedUnits, setPurchasedUnits] = useState(new BigNumber(1))

  useEffect(() => {
    const getPurchasedUnits = async () => {
      try {
        const purchasedAmtCall = await solvMarketContract.methods.purchasedUnits(saleId, account)
        setPurchasedUnits(await purchasedAmtCall.call())
      } catch (err) {
        console.error(err)
      }
    }
    getPurchasedUnits()
  }, [account, solvMarketContract.methods, marketAddress, saleId])

  return purchasedUnits
}
