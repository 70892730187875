import React, { useCallback } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { Chain } from 'config/constants/types'
import networkParams from 'config/constants/networkParams'
import { setupNetwork } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'

type WrongNetworkProps = {
  expectedChain: Chain
}

const WrongNetwork: React.FC<WrongNetworkProps> = ({ expectedChain }) => {
  const switchNetwork = useCallback(() => {
    setupNetwork(expectedChain)
  }, [expectedChain])

  const { t } = useTranslation()

  return (
    <Box marginTop={13} paddingX={0}>
      <Box display="flex" justifyContent="center">
        <img src="/images/warning.png" width="100px" alt="Warning" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Wrong Network Connected')}
        </Typography>
      </Box>
      <Box marginTop={6}>
        <Typography color="textPrimary" variant="body2" align="center">
          This project is supported by {networkParams[expectedChain][0].chainName}, please check your wallet is
          connected to the {networkParams[expectedChain][0].chainName} to participate.
        </Typography>
      </Box>
      <Box marginTop={6} marginBottom={6} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" size="large" onClick={switchNetwork} disableElevation>
          {t('Switch to %chainName%', { chainName: networkParams[expectedChain][0].chainName })}
        </Button>
      </Box>
    </Box>
  )
}

export default WrongNetwork
