import getTimePeriods from './getTimePeriods'

/**
 * @param {Object} periods Return value from getTimePeriods
 * @return {string} '14h 3m 4s'
 */
const formatTimePeriod = (periods: ReturnType<typeof getTimePeriods>, excludePeriods = []) => {
  const textArr = []

  Object.keys(periods).forEach((period) => {
    if (periods[period] > 0 && !excludePeriods.includes(period)) {
      textArr.push(`${periods[period]}${period.substr(0, 1)}`)
    }
  })

  if (textArr.length === 0) {
    return null
  }

  return textArr.join(' ')
}

/**
 * @param {Object} periods Return first value from getTimePeriods
 * @return {string} if '14h 3m 4s' returns '14h'
 */
export const formatLargestTimePeriod = (periods: ReturnType<typeof getTimePeriods>) => {
  const periodsArray = Object.keys(periods)
  for (let i = 0; i < periodsArray.length; i++) {
    if (periods[periodsArray[i]] > 0) {
      return `${periods[periodsArray[i]]}${periodsArray[i].substr(0, 1)}`
    }
  }
  return ''
}

export default formatTimePeriod
