import React from 'react'
import { PublicSaleConfig, KYCStatus } from 'state/types'
import Steps from 'components/Steps/Steps'
import styled from 'styled-components'
import { useTotalStakeWeight, useUserStakeWeight } from 'hooks/useIFAMaster'
import BigNumber from 'bignumber.js'
import { Box } from '@material-ui/core'
import { Spinner } from 'uikit'
import { useKycStatus } from 'hooks/useKycStatus'
import { useCurrentBlock } from 'state/block/hooks'
import PreSale from '../PublicSale/PreSale'
import SubscribeClosed from '../PublicSale/SubscribeClosed'
import PublicSaleEnded from '../PublicSale/PublicSaleEnded'
import SubscriptionPeriod from '../PublicSale/SubscriptionPeriod'
import NoStakeSubscription from '../PublicSale/NoStakeSubscription'
import Restricted from '../PublicSale/Restricted'
import PreAirdrop from '../PublicSale/PreAirdrop'

type PublicSaleProps = {
  project: PublicSaleConfig
}

const STEPS = [
  {
    title: 'Subscribe',
    status: 'DONE',
  },
  {
    title: 'Airdrop',
    status: 'NOT_DONE',
  },
]

const StepsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FreeAirdropSale: React.FC<PublicSaleProps> = ({ project }) => {
  const { id, purchasePeriod, masterAddress, subscribePeriod, claimPeriod, chainId } = project

  const now = new Date()
  const blockNumber = useCurrentBlock(project.chainId)

  const { allocSnapshotBlock } = purchasePeriod
  const validBlockNumber = allocSnapshotBlock
    ? Math.min(allocSnapshotBlock, Math.max(0, blockNumber - 10))
    : Math.max(0, blockNumber - 10)

  const userStakeWeight = new BigNumber(useUserStakeWeight(masterAddress, project.trackId, validBlockNumber))
  const totalStakeWeight = new BigNumber(useTotalStakeWeight(masterAddress, project.trackId, validBlockNumber))

  const hasStaked = userStakeWeight.isGreaterThan(0)
  const estimatedAllocation = userStakeWeight.div(totalStakeWeight).times(purchasePeriod.saleAmount)

  const { status: kycStatus, country } = useKycStatus()

  if (blockNumber === 0) {
    return (
      <Box display="flex" marginTop="15vw" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  if (
    kycStatus === KYCStatus.VERIFIED &&
    project.restrictedCountries?.length > 0 &&
    (project.restrictedCountries?.includes(country) || !country)
  ) {
    return <Restricted />
  }
  if (now < new Date(subscribePeriod.startTime)) {
    return (
      <PreSale
        endTime={new Date(subscribePeriod.startTime)}
        subscriptionEndTime={new Date(subscribePeriod.endTime)}
        hasCompletedKYC={kycStatus === KYCStatus.VERIFIED}
        token={project.stakingToken}
        projectToken={project.token.symbol}
      />
    )
  }
  let currentStep
  let lastDoneStep
  let Component

  // missed KYC or staking
  if (
    (!hasStaked || kycStatus !== KYCStatus.VERIFIED) &&
    now > new Date(subscribePeriod.endTime) &&
    now < new Date(claimPeriod.startTime)
  ) {
    currentStep = 0
    lastDoneStep = -1
    Component = <SubscribeClosed token={project.stakingToken} />
  } else if (
    // Subscription Period but have not kyced
    kycStatus !== KYCStatus.VERIFIED &&
    now > new Date(subscribePeriod.startTime) &&
    now < new Date(subscribePeriod.endTime)
  ) {
    currentStep = undefined
    lastDoneStep = undefined
    Component = (
      <NoStakeSubscription
        endTime={new Date(subscribePeriod.endTime)}
        hasCompletedKYC={false}
        token={project.stakingToken}
      />
    )
  } else if (
    // Subscription Period
    now > new Date(subscribePeriod.startTime) &&
    now < new Date(subscribePeriod.endTime)
  ) {
    currentStep = 0
    lastDoneStep = hasStaked ? 0 : -1
    Component = (
      <SubscriptionPeriod
        id={id}
        masterAddress={masterAddress}
        chainId={chainId}
        trackId={project.trackId}
        token={project.stakingToken}
        endTime={new Date(subscribePeriod.endTime)}
        estimatedAllocation={estimatedAllocation}
        saleToken={project.token}
        maxTotalStake={subscribePeriod?.isLimited && new BigNumber(subscribePeriod?.maxTotalStake)}
      />
    )
  } else if (
    // Waiting for alocation period to purchasing period
    now > new Date(subscribePeriod.endTime) &&
    now < new Date(claimPeriod.startTime)
  ) {
    currentStep = 1
    lastDoneStep = 0
    Component = (
      <PreAirdrop
        shares={estimatedAllocation.decimalPlaces(5).toString()}
        token={project.token.symbol}
        endTime={new Date(project.claimPeriod.startTime)}
        airdropInfo={project.airdropInfo}
      />
    )
  } else {
    Component = (
      <PublicSaleEnded
        token={project.token.symbol}
        address={project.token.address}
        paymentToken={project.paymentToken.symbol}
        airdropInfo={project.airdropInfo}
        claimedShare={estimatedAllocation.decimalPlaces(5).toString()}
      />
    )
  }

  return (
    <Box>
      {currentStep !== undefined && lastDoneStep !== undefined && (
        <StepsBox>
          <Steps steps={STEPS} currentStep={currentStep} lastDoneStep={lastDoneStep} />
        </StepsBox>
      )}
      {Component}
    </Box>
  )
}

export default FreeAirdropSale
