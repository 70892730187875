import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from 'uikit'
import { BloctoConnector } from '@blocto/blocto-connector'
import BloctoSDK from '@blocto/sdk'
import { Chain } from 'config/constants/types'
import Web3 from 'web3'
import networkModalOptions from 'config/constants/networkModalOptions'
import getNodeUrl from './getRpcUrl'

const POLLING_INTERVAL = 12000
export const chainIds = [
  ...networkModalOptions.map((val) => val.chainID),

  process.env.REACT_APP_STAGING && Chain.BSC_TESTNET,
].filter((v) => v)

// Blocto doesn't support Moonriver
export const bloctoChainIds = [
  Chain.BSC_MAINNET,
  // Chain.POLYGON_MAINNET,
  process.env.REACT_APP_STAGING && Chain.BSC_TESTNET,
].filter((v) => v)

const injected = new InjectedConnector({ supportedChainIds: chainIds })

const walletconnect = new WalletConnectConnector({
  rpc: chainIds.reduce((acc, val) => {
    acc[val] = getNodeUrl(val)
    return acc
  }, {}),
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const walletlink = new WalletLinkConnector({
  url: getNodeUrl[Chain.BSC_MAINNET],
  appName: 'Impossible Finance',
  supportedChainIds: chainIds,
})

// TODO: Support multichain for blocto
const bloctoConnector = new BloctoConnector({
  chainId: Chain.BSC_MAINNET,
  rpc: getNodeUrl(Chain.BSC_MAINNET),
})

const bloctoSDKs = bloctoChainIds.reduce((acc, val) => {
  acc[val] = new BloctoSDK({
    ethereum: {
      chainId: val,
    },
  })
  return acc
}, {})

export const getBloctoSDK = (chainId: number): BloctoSDK => {
  return bloctoSDKs[chainId]
}

const bscConnector = new BscConnector({ supportedChainIds: chainIds })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.Blocto]: bloctoConnector,
  [ConnectorNames.CoinbaseWallet]: walletlink,
}

export const getLibrary = (provider): Web3 => {
  return provider
}
