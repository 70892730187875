import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { KYCStatus } from 'state/types'
import useSWR from 'swr'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'

const STORAGE_KEY = 'KYC_STATUS_LS'
// kyc status URL
const endpoint = 'https://api.impossible.finance/.netlify/functions/kycStatus'

type KYCResponse = {
  status: KYCStatus
  country?: string
}

const KYC_CACHE_VERSION = '1'

export const useKycStatus = (): KYCResponse => {
  const { account } = useWeb3React()

  const getStatus = useCallback(async () => {
    if (!account) {
      console.info('Wallet not connected')
      return false
    }

    const cachedStatus = getWithExpiry(`${STORAGE_KEY}-${account}`, KYC_CACHE_VERSION)
    if (cachedStatus) {
      return cachedStatus
    }

    try {
      // get kyc status
      const response = await (
        await fetch(`${endpoint}?address=${account}`, {
          method: 'GET',
        })
      ).json()

      let ttl
      if (response?.status === KYCStatus.VERIFIED) {
        ttl = 24 * 60 * 60 * 1000 // 1 day
      } else {
        ttl = 5 * 60 * 1000 // 5 minutes
      }

      setWithExpiry(`${STORAGE_KEY}-${account}`, response, ttl, KYC_CACHE_VERSION)
      return response
    } catch (e) {
      console.info('KYC status error', e)
      return false
    }
  }, [account])

  const { data = {} } = useSWR(`useKycStatus|${account}`, getStatus, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  return data
}

export default useKycStatus
