import BigNumber from 'bignumber.js'
import IFAllocationSale from 'config/abi/IFAllocationSale.json'
import IFAllocationMaster from 'config/abi/IFAllocationMaster.json'
import SolvICMarketV2 from 'config/abi/SolvICMarketV2.2.json'
import { multicallv2 } from 'utils/multicall'
import { convertFromWei } from 'utils/formatBalance'
import { BlockStateMap, BonusSaleConfig, PublicSaleConfig } from 'state/types'
import { AVG_BLOCK_TIME } from 'config'

export const fetchPublicIdos = async (publicSales: PublicSaleConfig[], blocks: BlockStateMap) => {
  const data = await Promise.allSettled(
    publicSales.map(async (idoConfig) => {
      const { saleAddress, masterAddress, purchasePeriod, subscribePeriod, claimPeriod, chainId } = idoConfig

      if (idoConfig.isDisabled || !idoConfig.saleAddress) {
        return idoConfig
      }

      const calls = [
        {
          address: saleAddress,
          name: 'startTime',
        },
        // end block
        {
          address: saleAddress,
          name: 'endTime',
        },
        // minimum payment
        {
          address: saleAddress,
          name: 'minTotalPayment',
        },
        // maximum payment
        {
          address: saleAddress,
          name: 'maxTotalPayment',
        },
        // sale amount
        {
          address: saleAddress,
          name: 'saleAmount',
        },
        // sale price
        {
          address: saleAddress,
          name: 'salePrice',
        },
        {
          address: saleAddress,
          name: 'allocSnapshotBlock',
        },
        {
          address: saleAddress,
          name: 'withdrawDelay',
        },
        {
          address: saleAddress,
          name: 'purchaserCount',
        },
        {
          address: saleAddress,
          name: 'saleTokenAllocationOverride',
        },
        {
          address: saleAddress,
          name: 'totalPaymentReceived',
        },
      ]
      const [
        // saleToken,
        startTime,
        endTime,
        minTotalPayment,
        maxTotalPayment,
        saleAmountInWei,
        salePriceInWei,
        // trackId,
        allocSnapshotBlock,
        withdrawDelay,
        purchaserCount,
        saleTokenAllocationOverrideInWei,
        totalPaymentReceivedInWei,
      ] = await multicallv2(IFAllocationSale, calls, chainId)
      const { currentBlock } = blocks[chainId]

      const allocSnapshotBlockNum = parseInt(allocSnapshotBlock, 10)
      const purchaserCountNum = parseInt(purchaserCount, 10)
      const blockTime = AVG_BLOCK_TIME[chainId]

      const startTimeInMillis = parseInt(startTime, 10) * 1000
      const endTimeInMillis = parseInt(endTime, 10) * 1000
      const withdrawDelayInMillis = parseInt(withdrawDelay, 10) * 1000

      const secondsUntilSnapshot = (allocSnapshotBlockNum - currentBlock) * blockTime
      const minimumPurchase = new BigNumber(minTotalPayment)
        .div(new BigNumber(10).pow(idoConfig.paymentToken.decimals || 18))
        .toNumber()
      const maximumPurchase = maxTotalPayment
        ? new BigNumber(maxTotalPayment).div(new BigNumber(10).pow(idoConfig.paymentToken.decimals || 18)).toNumber()
        : idoConfig.purchasePeriod.maximumPurchase

      const saleAmount = new BigNumber(saleAmountInWei).isGreaterThan(0)
        ? new BigNumber(saleAmountInWei).div(new BigNumber(10).pow(idoConfig.token.decimals || 18)).toNumber()
        : idoConfig.purchasePeriod.saleAmount
      const salePrice = salePriceInWei
        ? convertFromWei(new BigNumber(salePriceInWei), 18) // 18 here is hardcoded value in SC for sale price
            .div(new BigNumber(10).pow((idoConfig.paymentToken.decimals || 18) - (idoConfig.token.decimals || 18)))
            .toNumber()
        : idoConfig.purchasePeriod.salePrice
      const saleTokenAllocationOverride = new BigNumber(saleTokenAllocationOverrideInWei)
        .div(new BigNumber(10).pow(idoConfig.token.decimals || 18))
        .toNumber()
      const totalPaymentReceived = new BigNumber(totalPaymentReceivedInWei)
        .div(new BigNumber(10).pow(idoConfig.paymentToken.decimals || 18))
        .toNumber()

      const afterEffectCalls = [
        // tracks information
        {
          address: masterAddress,
          name: 'tracks',
          params: [idoConfig.trackId],
        },
      ]

      const [trackInformation] = await multicallv2(IFAllocationMaster.abi, afterEffectCalls, chainId)
      const maxTotalStake = convertFromWei(new BigNumber([trackInformation.maxTotalStake] as any))

      return {
        ...idoConfig,
        // trackId: parseInt(trackId, 10),
        token: {
          ...idoConfig.token,
          // address: saleToken[0],
        },
        purchasePeriod: {
          ...purchasePeriod,
          startTime: startTime ? new Date(startTimeInMillis).toISOString() : idoConfig.purchasePeriod.startTime,
          endTime: endTime ? new Date(endTimeInMillis).toISOString() : idoConfig.purchasePeriod.endTime,
          minimumPurchase,
          maximumPurchase,
          saleAmount,
          salePrice,
          allocSnapshotBlock: allocSnapshotBlockNum,
          saleTokenAllocationOverride,
          totalPaymentReceived,
        },
        subscribePeriod: {
          ...subscribePeriod,
          endTime: new Date(new Date().getTime() + secondsUntilSnapshot * 1000).toISOString(),
          maxTotalStake,
        },
        claimPeriod: {
          ...claimPeriod,
          purchaserCount: purchaserCountNum,
          startTime:
            withdrawDelayInMillis && endTime
              ? new Date(endTimeInMillis + withdrawDelayInMillis)
              : idoConfig.claimPeriod.startTime,
        },
        bonusSale: {},
      }
    }),
  )
  return data.filter((v) => v.status === 'fulfilled').map((v) => (v as PromiseFulfilledResult<PublicSaleConfig>).value)
}

export const fetchBonusIdos = async (bonusSales: BonusSaleConfig[]) => {
  const data = await Promise.all(
    bonusSales.map(async (idoConfig) => {
      const { saleId, marketAddress, chainId } = idoConfig
      const calls = [
        {
          address: marketAddress,
          name: 'sales',
          params: [saleId],
        },
      ]

      const [saleInfo] = await multicallv2(SolvICMarketV2.abi, calls, chainId)
      const { tokenId, startTime, seller, total, units, min, max, icToken, currency, useAllowList } = saleInfo

      return {
        ...idoConfig,
        saleInfo: {
          tokenId,
          startTime,
          seller,
          totalInWei: new BigNumber([total] as any),
          unitsInWei: new BigNumber([units] as any),
          minInWei: new BigNumber([min] as any),
          maxInWei: new BigNumber([max] as any),
          icToken,
          currency,
          useAllowList,
        },
      }
    }),
  )

  return data
}
export default fetchPublicIdos
