import React, { useCallback, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import InputWithTimer from 'components/InputWithTimer'
import { Chain, Token } from 'config/constants/types'
import { useToast } from 'state/hooks'
import { useStake, useStakeAmount } from 'hooks/useIFAMaster'
import BigNumber from 'bignumber.js'
import { convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import { useIdoApprove } from 'hooks/useApprove'
import { useWeb3React } from '@web3-react/core'
import UnlockButton from 'components/UnlockButton'
import GetToken from 'components/GetToken'
import { getAddress } from 'utils/addressHelpers'
import { IDOSaleToken } from 'state/types'
import { Link, useModal } from 'uikit'
import Tooltip from 'views/Farms/components/Tooltip/Tooltip'
import { useTranslation } from 'contexts/Localization'
import { useIdoUser } from 'state/idos/hooks'
import { BorderedInfoCard } from '../../StyledComponent'
import EstimatedAllocationModal from '../../EstimatedAllocationModal'

type SubscriptionPeriodProps = {
  id: string
  masterAddress: string
  chainId: Chain
  trackId: number
  endTime: Date
  token: Token
  saleToken: IDOSaleToken
  estimatedAllocation: BigNumber
  maxTotalStake?: BigNumber
}

const SubscriptionPeriod: React.FC<SubscriptionPeriodProps> = ({
  id,
  masterAddress,
  chainId,
  trackId,
  endTime,
  token,
  saleToken,
  estimatedAllocation,
  maxTotalStake,
}) => {
  const { stakingTokenAllowanceInWei, stakingTokenBalanceInWei } = useIdoUser(id) || {}
  const { account } = useWeb3React()
  const [value, setValue] = useState('')
  const [error, setError] = useState(true)

  const { onStake, isLoading: stakeLoading } = useStake(masterAddress, trackId)
  const stakedAmountInWei = useStakeAmount(masterAddress, trackId)
  const stakedAmount = convertFromWei(stakedAmountInWei, token.decimals)
  const { onApprove, isLoading: approveLoading } = useIdoApprove(masterAddress, token.address)

  const [onPresentEstimated] = useModal(
    <EstimatedAllocationModal
      masterAddress={masterAddress}
      trackId={trackId}
      tokenImage={`/images/tokens/${token.symbol}.png`}
    />,
  )

  let stakingTokenBalance: BigNumber
  if (stakingTokenBalanceInWei) {
    stakingTokenBalance = convertFromWei(stakingTokenBalanceInWei, token.decimals)
  }

  const checkError = useCallback(
    (inputValue) => {
      const parsedValue = new BigNumber(inputValue)
      if (
        inputValue === '' ||
        parsedValue.isLessThanOrEqualTo(0) ||
        parsedValue.isGreaterThan(stakingTokenBalance) ||
        (maxTotalStake && parsedValue.isGreaterThan(maxTotalStake.minus(stakedAmount)))
      ) {
        setError(true)
      } else {
        setError(false)
      }
    },
    [setError, stakingTokenBalance, stakedAmount, maxTotalStake],
  )

  const onSelectMax = useCallback(() => {
    const balance = maxTotalStake
      ? BigNumber.min(maxTotalStake.minus(stakedAmount), stakingTokenBalance)
      : stakingTokenBalance
    const val = balance.decimalPlaces(5, BigNumber.ROUND_FLOOR).toString()
    setValue(val)
    checkError(val)
  }, [setValue, stakingTokenBalance, checkError, maxTotalStake, stakedAmount])

  const onChange = useCallback(
    (evt) => {
      setValue(evt.target.value)
      checkError(evt.target.value)
    },
    [setValue, checkError],
  )

  const handleApprove = useCallback(onApprove, [onApprove])
  const { toastSuccess } = useToast()

  const onSupplySuccess = useCallback(() => {
    toastSuccess(`You've staked ${value} ${token.symbol}`)
    setValue('')
    setError(true)
  }, [toastSuccess, value, token.symbol])

  const onSupply = useCallback(() => {
    onStake(getBalanceInWei(new BigNumber(value), token.decimals), onSupplySuccess)
  }, [onStake, value, token.decimals, onSupplySuccess])

  const stakeText = stakedAmount.isGreaterThan(0) ? 'Stake More' : 'Stake'
  const theme = useTheme()
  const { t } = useTranslation()

  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        {estimatedAllocation.isGreaterThan(0) ? (
          <BorderedInfoCard padding={5.5} marginTop={2} marginX={matches ? 10 : 0}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6} lg={5}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex">
                    <Typography variant="body2" color="textPrimary">
                      {t('Estimated Allocation')}
                    </Typography>
                    <Box marginLeft={2} onClick={onPresentEstimated}>
                      <img src="/images/calculate.png" alt="calculate" width="22px" />
                    </Box>
                  </Box>
                  <Box marginTop={2} display="flex" flexDirection="row">
                    <img src={saleToken.image} alt="token" width="36px" />
                    <Box marginLeft={2} />
                    <Typography variant="h3" color="textPrimary">
                      {estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={7}>
                <Typography variant="caption" color="textSecondary" component="span">
                  This shows the estimated allocation you will receive based on the amount and time you staked versus
                  global staked amount and time.
                  <Link
                    href="https://impossible.freshdesk.com/en/support/solutions/articles/80000801997-if-launchpad-format-faq#2.-How-is-Allocation-calculated?"
                    target="_blank"
                    style={{ color: '#0AC6E5' }}
                  >
                    {t('How is it calculated?')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </BorderedInfoCard>
        ) : (
          <>
            <Typography variant="body2" color="textPrimary" align="center">
              {t('Subscribe by staking %symbol% tokens to receive token purchase allocation.', {
                symbol: token.symbol,
              })}
            </Typography>
            <Tooltip
              content={
                <div>
                  The allocation will be calculated by the total number of participants and the amount of the tokens
                  staked verses your staking amount and period. We recommend you stake early to secure your allocation.
                </div>
              }
            >
              <img src="/images/HelpIcon.svg" alt="help" style={{ marginLeft: '30px' }} />
            </Tooltip>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="center" marginTop={estimatedAllocation.isGreaterThan(0) ? 9 : 19}>
        <InputWithTimer
          timerLabel={t('Subscription pool closes in')}
          timerEndTime={endTime}
          token={{
            name: token.symbol,
            balance: stakingTokenBalance?.decimalPlaces(5, BigNumber.ROUND_FLOOR).toNumber().toLocaleString(),
          }}
          inputLabel={t('Amount')}
          placeholder="0"
          editable={!stakeLoading}
          showMax
          value={value}
          onSelectMax={onSelectMax}
          onChange={onChange}
        />
      </Box>
      {maxTotalStake && maxTotalStake && maxTotalStake.minus(stakedAmount).isGreaterThan(0) && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Typography variant="body2" color="textSecondary">
            {stakedAmount.isGreaterThan(0)
              ? `You have staked ${stakedAmount.toNumber().toLocaleString()} ${
                  token.symbol
                } out of maximum of ${maxTotalStake.toNumber().toLocaleString()} ${token.symbol}. `
              : `You can stake up to ${maxTotalStake.toNumber().toLocaleString()} ${token.symbol}.`}
          </Typography>
        </Box>
      )}
      {maxTotalStake && stakedAmount.isGreaterThan(0) && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Box style={{ backgroundColor: '#F2F4F5' }} px={2.5} py="1px" borderRadius={4}>
            <Typography variant="body2" color="textSecondary" component="div">
              {maxTotalStake.minus(stakedAmount).isGreaterThan(0) ? (
                <>
                  You can stake <b>{maxTotalStake.minus(stakedAmount).toNumber().toLocaleString()}</b> more{' '}
                  {token.symbol}.
                </>
              ) : (
                <>
                  You have reached <b>maximum</b> staking cap
                </>
              )}
            </Typography>
          </Box>
        </Box>
      )}
      {!account ? (
        <Box display="flex" justifyContent="center" marginTop={6}>
          <UnlockButton mt="8px" width="300px" />
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="center" marginTop={6}>
            <Box width="300px">
              {stakingTokenAllowanceInWei?.isGreaterThan(0) && (
                <Button
                  onClick={onSupply}
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  fullWidth
                  disabled={stakeLoading || error}
                >
                  {stakeLoading ? t('Staking...') : stakeText}
                </Button>
              )}
              {(!stakingTokenAllowanceInWei || stakingTokenAllowanceInWei?.isEqualTo(0)) && (
                <Button
                  onClick={handleApprove}
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  fullWidth
                  disabled={approveLoading || error}
                >
                  {!approveLoading ? t('Approve to Stake') : t('Approving')}
                </Button>
              )}
            </Box>
          </Box>
          <GetToken symbol={token.symbol} address={getAddress(token.address, chainId)} />
        </Box>
      )}
    </Box>
  )
}

export default SubscriptionPeriod
