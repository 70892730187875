import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import toastsReducer from './toasts'
import idosReducer from './idos'
import blockReducer from './block'
import appReducer from './app'
import lists from './lists/reducer'
import multicall from './multicall/reducer'
import user from './user/reducer'
import transactions from './transactions/reducer'
import { updateVersion } from './global/actions'
import swap from './swap/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'profile']

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    app: appReducer,
    farms: farmsReducer,
    blocks: blockReducer,
    toasts: toastsReducer,
    idos: idosReducer,

    // EXCHANGE
    swap,
    transactions,
    user,
    multicall,
    lists,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
