import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import { BorderedInfoCard } from '../../StyledComponent'

type PreSaleProps = {
  endTime: Date
  saleTokenSymbol: string
  saleTokenImage: string
  paymentTokenSymbol: string
  totalCoin: BigNumber
  allocation: BigNumber
}

const PreSale: React.FC<PreSaleProps> = ({
  endTime,
  saleTokenSymbol,
  saleTokenImage,
  paymentTokenSymbol,
  totalCoin,
  allocation,
}) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography color="textSecondary" variant="h3" align="center">
        {t('Congratulations!')}
      </Typography>
      <Box marginTop={4}>
        <Typography color="textSecondary" variant="body2" align="center">
          {t(
            'You have won the %symbol% Token Whitelisting Campaign! Make sure you have completed KYC before you make the purchase. You can only enter via whitelisted URL.',
            { symbol: saleTokenSymbol },
          )}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" marginTop={11}>
        <img src="/images/idos/gleam-rocket.svg" width="100px" alt="rocket" />
      </Box>
      <Box marginTop={4}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Purchase starts in')}
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={endTime} />
      </Box>
      <Box marginTop={6} display="flex" justifyContent="center">
        <BorderedInfoCard width="370px">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              {t('Your Allocation')}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {t('You will need')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginTop="6px">
            <Box display="flex" alignItems="center" flexDirection="row">
              <img src={saleTokenImage} alt="token" width="26px" />
              <Box marginRight="6px" />
              <Typography variant="h2" color="textPrimary">
                {totalCoin.decimalPlaces(5).toString()}
              </Typography>
            </Box>

            <Typography variant="body1" color="textPrimary">
              {allocation.decimalPlaces(5).toString()} {paymentTokenSymbol}
            </Typography>
          </Box>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default PreSale
