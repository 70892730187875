import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BonusSaleConfig,
  BonusSaleUserDataType,
  IDOConfig,
  IDOType,
  PublicSaleConfig,
  PublicSaleUserDataType,
  State,
} from 'state/types'
import { fetchInitIdosPublicDataAsync } from '.'

export const useIdosInitiated = (): boolean => {
  return useSelector((state: State) => state.idos.initialised)
}

export const useIdosSyncedOnchain = (): boolean => {
  return useSelector((state: State) => state.idos.syncedOnchain)
}

export const useFetchInitIdosPublicData = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchInitIdosPublicDataAsync())
  }, [dispatch])
}

// IDOs
export const useIdos = (): IDOConfig[] => {
  const idos = useSelector((state: State) => state.idos.data)
  return Object.keys(idos).flatMap((key) => idos[key])
}

export const usePublicSaleFromId = (id): PublicSaleConfig => {
  const ido = useSelector((state: State) =>
    state.idos.data[IDOType.PUBLIC_SALE].find((f) => {
      const publicIdo = f as PublicSaleConfig
      return publicIdo.id === id
    }),
  )
  return ido as PublicSaleConfig
}

export const useIdoUser = (id): PublicSaleUserDataType | null => {
  const ido = usePublicSaleFromId(id)
  if (!ido || !ido.userData) {
    return null
  }
  return {
    paymentTokenBalanceInWei: new BigNumber(ido.userData.paymentTokenBalanceInWei),
    stakingTokenBalanceInWei: new BigNumber(ido.userData.stakingTokenBalanceInWei),
    paymentReceivedInWei: new BigNumber(ido.userData.paymentReceivedInWei),
    paymentTokenAllowanceInWei: new BigNumber(ido.userData.paymentTokenAllowanceInWei),
    stakingTokenAllowanceInWei: new BigNumber(ido.userData.stakingTokenAllowanceInWei),
    hasWithdrawn: ido.userData.hasWithdrawn,
    userStakeWeight: ido.userData?.userStakeWeight,
    solvPaymentTokenAllowance: new BigNumber(0),
    isWhitelisted: ido.userData?.isWhitelisted,
  }
}

export const useBonusSaleFromId = (id): BonusSaleConfig => {
  const ido = useSelector((state: State) => state.idos.data[IDOType.BONUS_SALE].find((f) => id === f.id))
  return ido as BonusSaleConfig
}

export const useBonusIdoUser = (id): BonusSaleUserDataType | null => {
  const ido = useBonusSaleFromId(id)
  if (!ido || !ido.userData) return null

  return {
    paymentTokenBalanceInWei: new BigNumber(ido.userData?.paymentTokenBalanceInWei),
    paymentTokenAllowanceInWei: new BigNumber(ido.userData?.paymentTokenAllowanceInWei),
    isWhitelisted: ido.userData?.isWhitelisted,
    purchasedUnits: new BigNumber(ido.userData?.purchasedUnits),
  }
}
