import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Chain } from 'config/constants/types'

type ImageWithNetworkProps = {
  src: string
  chainId?: Chain
  alt: string
  width?: string
  height?: string
}

const ChainIcon = styled.img`
  border: solid 4px white;
  border-radius: 100%;
  line-height: 0;
  align-item: flex-end;
  background: white;
`

const ImageWithNetwork: React.FC<ImageWithNetworkProps> = ({ src, alt, width, height, chainId }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box width={width} height={height}>
        <img src={src} alt={alt} width="100%" style={{ height }} />
      </Box>
      {chainId && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          marginLeft={-5}
          marginBottom={-2}
          width="45%"
        >
          <ChainIcon src={`/images/icons/networks/${chainId}.svg`} alt={`${chainId}`} />
        </Box>
      )}
    </Box>
  )
}

export default ImageWithNetwork
