import { Chain } from 'config/constants/types'
import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = {
  [Chain.BSC_MAINNET]: [
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed.binance.org',
  ],
  [Chain.BSC_TESTNET]: [
    'https://data-seed-prebsc-1-s1.binance.org:8545/',
    'https://data-seed-prebsc-2-s3.binance.org:8545/',
    'https://data-seed-prebsc-1-s2.binance.org:8545/',
  ],
  [Chain.AVAX_MAINNET]: ['https://api.avax.network/ext/bc/C/rpc'],
  [Chain.ETH_MAINNET]: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  [Chain.MOONRIVER_MAINNET]: ['https://rpc.api.moonriver.moonbeam.network'],
  [Chain.MOONBEAM_MAINNET]: ['https://rpc.api.moonbeam.network'],
  [Chain.AURORA_MAINNET]: ['https://mainnet.aurora.dev'],
  [Chain.BOBA_MAINNET]: ['https://mainnet.boba.network/'],
  // [Chain.POLYGON_MAINNET]: ['https://rpc-mainnet.maticvigil.com', 'https://rpc-mainnet.matic.quiknode.pro'],
}

const getNodeUrl = (chainId) => {
  return sample(nodes[chainId])
}

export default getNodeUrl
