import { BonusSaleConfig } from 'state/types'
import SolvICMarketV2 from 'config/abi/SolvICMarketV2.2.json'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import { getAddress } from 'utils/addressHelpers'
import { Chain } from 'config/constants/types'

export const fetchBonusUserWhitelisted = async (bonusSales: BonusSaleConfig[], account: string, chainId: Chain) => {
  const calls = bonusSales.map((idoConfig) => {
    const { marketAddress, voucherAddress } = idoConfig
    return { address: marketAddress, name: 'isBuyerAllowed', params: [voucherAddress, account] }
  })

  const userWhitelisted = await multicall(SolvICMarketV2.abi, calls, chainId)

  return userWhitelisted
}

export const fetchPurchasedUnits = async (bonusSales: BonusSaleConfig[], account: string, chainId: Chain) => {
  const calls = bonusSales.map((idoConfig) => {
    const { marketAddress, saleId } = idoConfig
    return { address: marketAddress, name: 'purchasedUnits', params: [saleId, account] }
  })

  const rawPurchasedUnits = await multicall(SolvICMarketV2.abi, calls, chainId)
  const parsedPurchasedUnits = rawPurchasedUnits.map((purchasedUnits) => {
    return new BigNumber(purchasedUnits).toJSON()
  })
  return parsedPurchasedUnits
}

export const fetchBonusIdoUserPaymentTokenBalances = async (
  publicSales: BonusSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const calls = publicSales.map((idoConfig) => {
    const tokenAddress = getAddress(idoConfig.paymentToken.address, idoConfig.chainId)
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls, chainId)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchBonusIdoPaymentUserAllowances = async (
  publicSales: BonusSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const calls = publicSales.map((idoConfig) => {
    const lpContractAddress = getAddress(idoConfig.paymentToken.address, idoConfig.chainId)
    const { marketAddress } = idoConfig
    return { address: lpContractAddress, name: 'allowance', params: [account, marketAddress] }
  })

  const rawAllowances = await multicall(erc20ABI, calls, chainId)
  const parsedAllowances = rawAllowances.map((balance) => {
    return new BigNumber(balance).toJSON()
  })
  return parsedAllowances
}
