import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M23.9016 3.70265V19.736C23.9016 21.3619 22.5835 22.68 20.9574 22.68H2.38305C1.06691 22.68 0 21.6133 0 20.2972V3.70288C0 2.38674 1.06691 1.31982 2.38305 1.31982H21.5186C22.8347 1.31982 23.9016 2.38674 23.9016 3.70265Z"
        fill="#D0D5D6"
      />
      <path
        d="M22.521 7.67852V20.6168C22.521 21.7562 21.5972 22.68 20.4576 22.68H2.06338C0.924022 22.68 0 21.7566 0 20.6168V3.70264C0 4.87079 0.947062 5.81785 2.11588 5.81785H20.6603C21.688 5.81785 22.521 6.65105 22.521 7.67852Z"
        fill="#0074FF"
      />
      <path
        d="M21.701 16.167H15.3535C14.6078 16.167 14.0034 15.535 14.0034 14.7554V11.9605C14.0034 11.1809 14.6078 10.549 15.3535 10.549H21.701C22.9161 10.549 23.9015 9.51901 23.9015 8.24854V13.8665C23.9015 15.137 22.9161 16.167 21.701 16.167Z"
        fill="white"
      />
      <path
        d="M17.9626 13.6922C17.9626 14.5121 17.2978 15.1769 16.4777 15.1769C15.6579 15.1769 14.9932 14.5121 14.9932 13.6922C14.9932 12.8723 15.6579 12.2075 16.4777 12.2075C17.2978 12.2075 17.9626 12.8723 17.9626 13.6922Z"
        fill="#0074FF"
      />
    </Svg>
  )
}

export default Icon
