import React from 'react'
import { KYCStatus, PublicSaleConfig } from 'state/types'
import Steps from 'components/Steps/Steps'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Box } from '@material-ui/core'
import { convertFromWei } from 'utils/formatBalance'
import { useKycStatus } from 'hooks/useKycStatus'
import { useWhitelistedPrivateSale } from 'hooks/useWhitelistedPrivateSale'
import { useWeb3React } from '@web3-react/core'
import { useIdoUser } from 'state/idos/hooks'
import PreSale from './PreSale'
import LimitedAccess from './LimitedAccess'
import PurchasePeriod from '../PublicSale/PurchasePeriod'
import Restricted from '../PublicSale/Restricted'
import PreClaim from './PreClaim'
import MissedPurchase from './MissedPurchase'
import PrivateSaleEnded from './PrivateSaleEnded'
import Claim from './Claim'

type PublicSaleProps = {
  project: PublicSaleConfig
}

const STEPS = [
  {
    title: 'Purchase',
    status: 'NOT_DONE',
  },
  {
    title: 'Claim',
    status: 'NOT_DONE',
  },
]

const StepsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PublicSale: React.FC<PublicSaleProps> = ({ project }) => {
  const { id, purchasePeriod, claimPeriod, chainId, airdropInfo } = project
  const userData = useIdoUser(id)
  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)
  const paymentReceived = convertFromWei(paymentReceivedInWei)
  const hasWithdrawn = (userData && userData.hasWithdrawn) || false

  const now = new Date()
  const { account } = useWeb3React()
  const whitelistedAddresses = useWhitelistedPrivateSale(project.saleAddress)
  const { saleTokenAllocationOverride } = purchasePeriod
  const { status: kycStatus, country } = useKycStatus()

  if (!purchasePeriod || !saleTokenAllocationOverride) return <div>No sale available</div>
  const { salePrice } = purchasePeriod
  const saleTokenAllocation = new BigNumber(saleTokenAllocationOverride)
  const allocationInPayment = saleTokenAllocation.times(salePrice)
  if (
    !account ||
    (account && whitelistedAddresses.findIndex((val) => val.toLowerCase() === account.toLowerCase()) === -1)
  ) {
    return <LimitedAccess isConnected={!!account} />
  }

  if (
    kycStatus === KYCStatus.VERIFIED &&
    project.restrictedCountries?.length > 0 &&
    (project.restrictedCountries?.includes(country) || !country)
  ) {
    return <Restricted />
  }

  if (now < new Date(purchasePeriod.startTime)) {
    return (
      <PreSale
        endTime={new Date(purchasePeriod.startTime)}
        saleTokenSymbol={project.token.symbol}
        saleTokenImage={project.token.image}
        paymentTokenSymbol={project.paymentToken.symbol}
        totalCoin={saleTokenAllocation}
        allocation={allocationInPayment}
      />
    )
  }
  let currentStep
  let lastDoneStep
  let Component

  // allocation calculation
  const fullDisplayshares = paymentReceived.dividedBy(salePrice).decimalPlaces(5).toString()

  if (
    !airdropInfo?.isAirdropped &&
    now > new Date(purchasePeriod.startTime) &&
    now < new Date(claimPeriod.startTime) &&
    paymentReceived.isGreaterThan(0) &&
    allocationInPayment.minus(paymentReceived).isLessThanOrEqualTo(0.01)
  ) {
    currentStep = 0
    lastDoneStep = 0
    Component = (
      <PreClaim token={project.token.symbol} shares={fullDisplayshares} endTime={new Date(claimPeriod.startTime)} />
    )
  } else if (now > new Date(purchasePeriod.startTime) && now < new Date(purchasePeriod.endTime)) {
    currentStep = 0
    lastDoneStep = -1
    Component = (
      <PurchasePeriod
        id={id}
        saleToken={project.token}
        airdropInfo={airdropInfo}
        address={project.saleAddress}
        paymentToken={project.paymentToken}
        publicSalePurchase={purchasePeriod}
        allocation={allocationInPayment}
        paymentReceived={paymentReceived}
        passedKYC={kycStatus === KYCStatus.VERIFIED}
        chainId={chainId}
        isWhitelistSale
      />
    )
  } else if (paymentReceived.isGreaterThan(0)) {
    currentStep = 3
    lastDoneStep = hasWithdrawn ? 3 : 2
    if (hasWithdrawn || project.airdropInfo?.isAirdropped) {
      Component = (
        <PrivateSaleEnded
          claimedShare={fullDisplayshares}
          address={project.token.address}
          token={project.token.symbol}
          airdropInfo={airdropInfo}
        />
      )
    } else {
      Component = <Claim saleAddress={project.saleAddress} token={project.token.symbol} shares={fullDisplayshares} />
    }
  } else {
    Component = <MissedPurchase endTime={new Date(purchasePeriod.endTime)} />
  }

  return (
    <Box>
      {currentStep !== undefined && lastDoneStep !== undefined && (
        <StepsBox>
          <Steps steps={STEPS} currentStep={currentStep} lastDoneStep={lastDoneStep} />
        </StepsBox>
      )}
      {Component}
    </Box>
  )
}

export default PublicSale
