import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

const ExternalLinkWrap = styled.a`
  text-decoration: none;
  margin: auto;
  color: #0ac6e5;
`

const LegalText = styled.div`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #a6a8aa;
`

const LegalLink: React.FC = () => {
  return (
    <Box marginTop={8} paddingBottom={8}>
      <LegalText>
        Access this link for the{' '}
        <ExternalLinkWrap target="_blank" rel="noopener noreferrer" href="https://impossible.finance/terms">
          Terms of Service
        </ExternalLinkWrap>
        . You should ensure that your usage and activities in this site comply with your local laws and regulations as
        applicable.
      </LegalText>
    </Box>
  )
}

export default LegalLink
