import React, { useCallback, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import { Flex, Text, Link, LinkExternal } from 'uikit'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { useTranslation } from 'contexts/Localization'
import { PublicSaleConfig, IDOConfig, IDOType } from 'state/types'
import getTimePeriods from 'utils/getTimePeriods'
import { formatLargestTimePeriod } from 'utils/formatTimePeriod'
import IFTooltip from 'components/IFTooltip'
import ImageWithNetwork from 'components/ImageWithNetwork'
import networkModalOptions from 'config/constants/networkModalOptions'
import ParticipatedProjectCard from './ParticipatedProjectCard'

interface ProjectCardProps {
  showStatus?: boolean
  showParticipated?: boolean
  projectDetail: IDOConfig
  showLimit?: boolean
  isInvestPage?: boolean
  isUpcoming?: boolean
}

const ProjectCardBox = styled.div`
  padding: 24px;
  background: ${({ theme }) => theme.colors.card};
  border-radius: 22px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.09);
  position: relative;
  height: 100%;
`

const ProjectIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ProjectTitle = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 16px;
`

const ProjectDescription = styled.div`
  text-align: left;
  color: #616568;
  font-size: 16px;
  line-height: 21.5px;
  margin-top: 25px;
`

const StatusContainer = styled.div<{ bgColor: string; textColor: string }>`
  background: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border-radius: 20px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
`
const StatusCircle = styled.div<{ bgColor: string }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${(props) => props.bgColor};
  margin-right: 4px;
`

const ExternalLinkWrap = styled(LinkExternal)`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #0ac6e5;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0 10px;
  height: 30px;
  margin: auto;
  color: #0ac6e5;
  & > svg {
    fill: #0ac6e5;
  }
`

const SOCIAL_MEDIAS = {
  MEDIUM: {
    iconSrc: '/images/icons/btn_medium.svg',
    hoverIconSrc: '/images/icons/btn_medium_hover.svg',
  },
  TWITTER: {
    iconSrc: '/images/icons/btn_twitter.svg',
    hoverIconSrc: '/images/icons/btn_twitter_hover.svg',
  },
  WEB: {
    iconSrc: '/images/icons/btn_sitelink.svg',
    hoverIconSrc: '/images/icons/btn_sitelink_hover.svg',
  },
  TELEGRAM: {
    iconSrc: '/images/icons/btn_tg.svg',
    hoverIconSrc: '/images/icons/btn_tg_hover.svg',
  },
  DISCORD: {
    iconSrc: '/images/icons/btn_discord.svg',
    hoverIconSrc: '/images/icons/btn_discord_hover.svg',
  },
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  showStatus = true,
  projectDetail,
  showParticipated = false,
  showLimit = false,
  isInvestPage = false,
  isUpcoming = false,
}) => {
  const { t } = useTranslation()
  const getStatus = useCallback(() => {
    const now = new Date()
    const { startTime, endTime } = projectDetail
    if (new Date(startTime) > now) {
      const seconds = Math.trunc((new Date(startTime).getTime() - now.getTime()) / 1000)
      const periods = getTimePeriods(seconds)
      return (
        <StatusContainer bgColor="#C086EC" textColor="#FFFFFF">
          <StatusCircle bgColor="#FFFFFF" /> in {formatLargestTimePeriod(periods)}
        </StatusContainer>
      )
    }
    if (new Date(endTime) < now) {
      return (
        <StatusContainer bgColor="#F2F4F5" textColor="#A6A8AA">
          <StatusCircle bgColor="#A6A8AA" /> {t('Closed')}
        </StatusContainer>
      )
    }
    return (
      <StatusContainer bgColor="#00FFB9" textColor="#1C9976">
        <StatusCircle bgColor="#1C9976" /> {t('Live')}
      </StatusContainer>
    )
  }, [projectDetail, t])

  let tagColor
  let tagTitle
  let projectDescription

  const publicProjectDetail = projectDetail as PublicSaleConfig
  const { projectInformation } = publicProjectDetail

  if (isUpcoming) {
    tagColor = '#D0D5D6'
    tagTitle = 'Join our Community'
  } else if (projectDetail.isFreeAirdrop) {
    tagColor = 'linear-gradient(249.75deg, #9100FF 0.91%, #FF0EA9 91.5%)'
    tagTitle = 'StakeDrop'
    projectDescription = 'Stake tokens and get allocation airdropped directly to your wallet, no payment required.'
  } else if (projectDetail.isPrivate) {
    tagColor = '#616568'
    tagTitle = 'Whitelist Sale'
    projectDescription = 'Please make sure your KYC is completed before you participate in this sale.'
  } else if (projectDetail.type === IDOType.PUBLIC_SALE) {
    if (publicProjectDetail?.subscribePeriod.isLimited && publicProjectDetail?.subscribePeriod.maxTotalStake) {
      tagColor = '#050A5A'
      tagTitle = 'Standard Sale'
      projectDescription = 'This sale has a hard cap. Each wallet can receive a limited maximum allocation.'
    } else {
      tagColor = '#9100FF'
      tagTitle = 'Unlimited Sale'
      projectDescription =
        'This is an unlimited sale. Each wallet can receive unlimited allocation until the total supply is achieved.'
    }
  }

  const networkData = networkModalOptions.find((network) => network.chainID === projectDetail.chainId)

  const ProjectInformationContainer = (
    <>
      {showStatus && <Flex justifyContent="flex-end">{getStatus()}</Flex>}
      <ProjectIcon>
        <ImageWithNetwork
          src={projectInformation.image}
          chainId={isInvestPage && projectDetail.chainId}
          alt="icon"
          width="80px"
          height="80px"
        />
      </ProjectIcon>
      <ProjectTitle>{projectInformation.title}</ProjectTitle>

      {showParticipated ? (
        <ParticipatedProjectCard projectDetail={projectDetail} />
      ) : (
        <>
          {!showStatus && (
            <Flex alignSelf="center" justifyContent="center" mt="16px" paddingLeft="24px" paddingRight="24px">
              {projectInformation.socialMedia?.map((item) => {
                return (
                  <Box p={3}>
                    <Link href={item.url} target="_blank">
                      <img
                        src={SOCIAL_MEDIAS[item.name].iconSrc}
                        alt="mediumIcon"
                        onMouseOver={(e) => {
                          e.currentTarget.src = SOCIAL_MEDIAS[item.name].hoverIconSrc
                          return null
                        }}
                        onFocus={(e) => {
                          e.currentTarget.src = SOCIAL_MEDIAS[item.name].hoverIconSrc
                          return null
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.src = SOCIAL_MEDIAS[item.name].iconSrc
                          return null
                        }}
                        onBlur={(e) => {
                          e.currentTarget.src = SOCIAL_MEDIAS[item.name].iconSrc
                          return null
                        }}
                      />
                    </Link>
                  </Box>
                )
              })}
            </Flex>
          )}
          <ProjectDescription>
            {isInvestPage && projectDescription ? t(projectDescription) : projectInformation.shortDescription}
          </ProjectDescription>
          {!isInvestPage && (
            <Flex justifyContent="space-between" mt="16px">
              {projectInformation.researchLink && (
                <ExternalLinkWrap href={projectInformation.researchLink}>{t('Research')}</ExternalLinkWrap>
              )}
              {projectInformation.saleDetailsLink && (
                <ExternalLinkWrap href={projectInformation.saleDetailsLink}>{t('Sale Details')}</ExternalLinkWrap>
              )}
            </Flex>
          )}

          {!isInvestPage && (
            <Flex justifyContent="space-between" mt="36px">
              <Text>{t('Network')}</Text>
              <Box display="flex" flexDirection="row" alignItems="center">
                <>
                  <img src={`/images/icons/networks/${projectDetail.chainId}.svg`} width={26} height={26} alt="token" />
                  <Box marginRight={2} />
                  {networkData.name}
                </>
              </Box>
            </Flex>
          )}

          <Flex justifyContent="space-between" mt="16px">
            <Text>{t('Requirement')}</Text>
            <Text>{projectDetail.stepRequirement}</Text>
          </Flex>

          <Flex justifyContent="space-between" mt="16px">
            <Text>{t('Access')}</Text>
            <Text>{projectDetail.isPrivate ? 'Whitelisted' : 'Everyone'}</Text>
          </Flex>
          {projectInformation.restrictedLabel && (
            <Flex justifyContent="space-between" mt="16px">
              <Text>{t('Restriction')}</Text>
              <IFTooltip placement="right-start" title={projectInformation.restrictedLabel}>
                <Box display="flex" alignItems="center">
                  <img src="/images/HelpIcon.svg" alt="help" />
                </Box>
              </IFTooltip>
            </Flex>
          )}
          {projectDetail.type === IDOType.PUBLIC_SALE && showLimit && !projectDetail.isPrivate && (
            <Flex justifyContent="space-between" mt="16px">
              <Text>{t('Staking Cap Per User')}</Text>
              <Text>
                {(publicProjectDetail?.subscribePeriod.isLimited &&
                  publicProjectDetail?.subscribePeriod.maxTotalStake &&
                  new BigNumber(publicProjectDetail?.subscribePeriod.maxTotalStake).toNumber().toLocaleString()) ||
                  '-'}{' '}
                {publicProjectDetail?.subscribePeriod.isLimited && publicProjectDetail.stakingToken.symbol}
              </Text>
            </Flex>
          )}
          {showLimit && (
            <Flex justifyContent="space-between" mt="16px">
              <Text>{t(`Token For ${projectDetail.isFreeAirdrop ? 'Drop' : 'Sale'}`)}</Text>
              <Text>
                {Number(projectDetail.token.totalSupply).toLocaleString()} {projectDetail.token.symbol}
              </Text>
            </Flex>
          )}
          {!projectDetail.isDisabled && (
            <Flex justifyContent="space-between" mt="16px">
              <Text>{t('Pay in')}</Text>
              <Box display="flex" flexDirection="row" alignItems="center">
                {projectDetail.isFreeAirdrop ? (
                  <>{t('FREE')}</>
                ) : (
                  <>
                    <img
                      src={`/images/tokens/${projectDetail.paymentToken.symbol}.png`}
                      width={26}
                      height={26}
                      alt="token"
                    />
                    <Box marginRight={2} />
                    {projectDetail.paymentToken.symbol}
                  </>
                )}
              </Box>
            </Flex>
          )}
          {projectDetail.restrictedLabel && (
            <Flex justifyContent="space-between" mt="16px">
              <Text>{t('Restriction')}</Text>
              <IFTooltip placement="right-start" title={projectDetail.restrictedLabel}>
                <Box display="flex" alignItems="center">
                  <img src="/images/HelpIcon.svg" alt="help" />
                </Box>
              </IFTooltip>
            </Flex>
          )}
          {isInvestPage && <Box mb="32px" />}
        </>
      )}
    </>
  )

  const UpcomingProjectInformationContainer = (
    <>
      <ProjectIcon>
        <img src="/images/upcoming.svg" alt="upcoming" style={{ width: '100%' }} />
      </ProjectIcon>
      <>
        <Flex justifyContent="space-between" mt="36px">
          <Text>{t('Requirement')}</Text>
          <Text>--</Text>
        </Flex>
        <Flex justifyContent="space-between" mt="16px">
          <Text>{t('Access')}</Text>
          <Text>--</Text>
        </Flex>
        <Flex justifyContent="space-between" mt="16px">
          <Text>{t('Token For Sale')}</Text>
          <Text>--</Text>
        </Flex>
        <Flex justifyContent="space-between" mt="16px">
          <Text>{t('Restriction')}</Text>
          <Text>--</Text>
        </Flex>
        {isInvestPage && <Box mb="32px" />}
      </>
    </>
  )

  if (isInvestPage) {
    return (
      <ProjectCardBox>
        {isUpcoming ? UpcomingProjectInformationContainer : ProjectInformationContainer}
        <Box
          position="absolute"
          width="100%"
          bottom="0"
          left="0"
          padding="6px 24px"
          style={{ background: tagColor, borderBottomLeftRadius: '22px', borderBottomRightRadius: '22px' }}
        >
          <Text color="white" textAlign="center" fontWeight="500">
            {t(tagTitle)}
          </Text>
        </Box>
      </ProjectCardBox>
    )
  }
  return (
    <ProjectCardBox
      style={{
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          background: tagColor,
          width: '200px',
          height: '100px',
          position: 'absolute',
          transform: 'rotate(-45deg)',
          left: -70,
          top: -40,
        }}
      >
        <Text
          style={{ width: '87.5%', bottom: 3, position: 'absolute' }}
          color="white"
          textAlign="center"
          fontWeight="500"
          fontSize="17px"
        >
          {tagTitle?.split(' ')[0] || ''}
        </Text>
      </Box>

      {ProjectInformationContainer}
    </ProjectCardBox>
  )
}

export default ProjectCard
