import { ethers } from 'ethers'
import getRpcUrl from 'utils/getRpcUrl'
import { chainIds } from './web3React'

export const simpleRpcProviders = chainIds.reduce((acc, val) => {
  const rpcUrl = getRpcUrl(val)
  acc[val] = new ethers.providers.StaticJsonRpcProvider(rpcUrl)
  return acc
}, {})

export default null
