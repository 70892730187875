import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Token } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

type SubscriptionClosedProps = {
  token: Token
}

const SubscribeClosed: React.FC<SubscriptionClosedProps> = ({ token }) => {
  const { t } = useTranslation()
  return (
    <Box marginTop={13}>
      <Box display="flex" justifyContent="center">
        <img src="/images/idos/SubscribeClosed.svg" width="100px" alt="time sand" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Oops! You missed the subscription.')}
        </Typography>
        <Typography color="textSecondary" align="center">
          {t('Subscribe to the Public Sale by staking %symbol% token and reserve your allocation.', {
            symbol: token.symbol,
          })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <BorderedInfoCard marginTop={5}>
          <Typography variant="body2" color="textSecondary">
            {t('The following reasons may be why you cannot view the subscription page:')}
            <ul>
              <li>{t('You did not pass KYC in time')}</li>
              <li>{t('The subscription period is already over')}</li>
            </ul>
          </Typography>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default SubscribeClosed
