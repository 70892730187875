import React, { ChangeEventHandler } from 'react'
import styled from 'styled-components'
import { Box, Typography, withStyles } from '@material-ui/core'
import { Button } from 'uikit'
import SmallCountdown from 'components/SmallCountdown'

const GreyBox = withStyles({
  root: {
    borderRadius: '8px',
    padding: '16px',
    background: '#F2F4F5',
    width: '100%',
  },
})(Box)

const StyledInput = styled.input`
  border: none;
  background: transparent;
  color: #3b4346;
  font-size: 34px;
  font-weight: 500;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: #A6A8AA;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
}    
`

type InputWithTimerProps = {
  timerLabel: string
  timerEndTime: Date
  inputLabel: string
  token?: {
    name: string
    balance: string
  }
  placeholder?: string
  editable: boolean
  showMax: boolean
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onSelectMax?: VoidFunction
}

const InputWithTimer: React.FC<InputWithTimerProps> = ({
  timerLabel,
  timerEndTime,
  inputLabel,
  token,
  placeholder,
  editable,
  showMax,
  value,
  onChange,
  onSelectMax,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="370px">
      <Box alignSelf="flex-end" display="flex">
        <SmallCountdown label={timerLabel} targetTime={timerEndTime} />
      </Box>
      <GreyBox marginTop="-14px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {inputLabel}
          </Typography>
          {token && (
            <Typography variant="body2" color="textPrimary" align="right">
              Balance: {token.balance} {token.name}
            </Typography>
          )}
        </Box>
        <Box marginTop={2} display="flex" alignItems="center">
          <StyledInput
            type="number"
            onChange={onChange}
            value={value}
            disabled={!editable}
            placeholder={placeholder}
            onWheel={(e) => e.currentTarget.blur()}
          />
          {showMax && (
            <Button
              scale="sm"
              style={{ background: '#E9EBEC', color: '#0AC6E5' }}
              onClick={onSelectMax}
              disabled={!editable}
            >
              MAX
            </Button>
          )}
        </Box>
      </GreyBox>
    </Box>
  )
}

export default InputWithTimer
