import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const css = `.prefix__cls-1{fill:#275ff1}`
const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <defs>
        <style>{css}</style>
      </defs>
      <g id="prefix__Group_26537" data-name="Group 26537" transform="translate(8369 395)">
        <path
          id="prefix__Subtraction_114"
          d="M17.5 35a17.5 17.5 0 01-6.812-33.625 17.5 17.5 0 0113.624 32.25A17.39 17.39 0 0117.5 35zm0-28a10.5 10.5 0 107.424 3.075A10.431 10.431 0 0017.5 7z"
          className="prefix__cls-1"
          data-name="Subtraction 114"
          transform="translate(-8369 -395)"
        />
        <rect
          id="prefix__Rectangle_5828"
          width="7"
          height="7"
          className="prefix__cls-1"
          data-name="Rectangle 5828"
          rx="2"
          transform="translate(-8355 -381)"
        />
      </g>
    </Svg>
  )
}

export default Icon
