import { ChainTokenList } from 'config/swap/types'
import JSBI from 'jsbi'
import { IDOConfig } from 'state/types'
import { Percent } from 'swap-sdk/entities/fractions/percent'
import { TokenClass } from 'swap-sdk/entities/token'
import getGasToken from 'utils/getGasToken'
import getTokenClass from 'utils/getTokenClass'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'
import { chainIds } from 'utils/web3React'
import farmsConfig from './farms'
import tokens from './tokens'
import { Chain } from './types'

const communityFarms = farmsConfig.filter((farm) => farm.isCommunity).map((farm) => farm.tokenSymbol)

const STORAGE_KEY = 'IDOS_DATA'
const CACHE_VERSION = '1'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...chainIds.reduce((acc, chainId) => {
    acc[chainId] = []
    return acc
  }, {}),
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in Chain]?: { [tokenAddress: string]: TokenClass[] } } = chainIds.reduce(
  (acc, chainId) => {
    acc[chainId] = {}
    return acc
  },
  {},
)

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in Chain]?: { [tokenAddress: string]: TokenClass[] } } = chainIds.reduce(
  (acc, chainId) => {
    acc[chainId] = {}
    return acc
  },
  {},
)

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...chainIds.reduce((acc, chainId) => {
    acc[chainId] = []
    return acc
  }, {}),
  ...[Chain.BSC_MAINNET, Chain.MOONRIVER_MAINNET].reduce((acc, chainId) => {
    acc[chainId] = [getGasToken(chainId), ...[tokens.busd, tokens.idia].map((token) => getTokenClass(token, chainId))]
    return acc
  }, {}),
}

export const SUGGESTED_BASES: ChainTokenList = {
  ...chainIds.reduce((acc, chainId) => {
    acc[chainId] = []
    return acc
  }, {}),
  [Chain.BSC_MAINNET]: [tokens.busd, tokens.idia].map((token) => getTokenClass(token, Chain.BSC_MAINNET)),
  [Chain.MOONRIVER_MAINNET]: [tokens.idia, tokens.usdc6].map((token) => getTokenClass(token, Chain.MOONRIVER_MAINNET)),
  [Chain.AVAX_MAINNET]: [tokens.idia, tokens.usdc6, tokens.usdt6].map((token) =>
    getTokenClass(token, Chain.AVAX_MAINNET),
  ),
  [Chain.MOONBEAM_MAINNET]: [tokens.idia, tokens.usdc6, tokens.usdt6].map((token) =>
    getTokenClass(token, Chain.MOONBEAM_MAINNET),
  ),
  [Chain.AURORA_MAINNET]: [tokens.idia, tokens.usdc6, tokens.usdt6].map((token) =>
    getTokenClass(token, Chain.AURORA_MAINNET),
  ),
  [Chain.BOBA_MAINNET]: [tokens.idia, tokens.usdc6, tokens.usdt6].map((token) =>
    getTokenClass(token, Chain.BOBA_MAINNET),
  ),
}

export const PINNED_PAIRS: { readonly [chainId in Chain]?: [TokenClass, TokenClass][] } = chainIds.reduce(
  (acc, chainId) => {
    acc[chainId] = [
      [getTokenClass(tokens.idia, chainId), getGasToken(Chain.BSC_MAINNET)],
      [getTokenClass(tokens.idia, chainId), getTokenClass(tokens.busd, chainId)],
      [getTokenClass(tokens.if, chainId), getTokenClass(tokens.busd, chainId)],
    ]
    return acc
  },
  {},
)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

const idosConfig = async (): Promise<IDOConfig[]> => {
  let idos: IDOConfig[] = []

  // check local storage
  const cachedData = getWithExpiry(STORAGE_KEY, CACHE_VERSION)
  if (cachedData) {
    return cachedData
  }

  // fetch data from server
  const res = await fetch(`${process.env.REACT_APP_CMS_API}/api/idos`)
  idos = await res.json()
  // save in local storage
  setWithExpiry(STORAGE_KEY, idos, 15 * 60 * 1000, CACHE_VERSION)
  return idos
}

export { farmsConfig, communityFarms, idosConfig }
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'
