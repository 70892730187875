import React from 'react'
import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'

export const Tabs = withStyles({
  root: {
    borderBottom: '2px solid #F2F4F5',
  },
  indicator: {
    backgroundColor: '#0AC6E5',
    height: 4,
  },
})(MaterialTabs)

interface TabProps {
  label?: string
}

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    color: '#D0D5D6',
    '&:hover': {
      color: '#D0D5D6',
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: theme.palette.text.primary,
    },
  },
  selected: {},
}))((props: TabProps) => <MaterialTab disableRipple {...props} />)

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
