import React, { useEffect, useState } from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'

type CountdownProps = {
  targetTime: Date
}

const GreyBox = withStyles({
  root: {
    borderRadius: '2px',
    padding: '11px 8px',
    background: '#F2F4F5',
  },
})(Box)

const Countdown: React.FC<CountdownProps> = ({ targetTime }) => {
  const [diffInSeconds, updateDiff] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      const now = new Date()
      updateDiff(Math.trunc((targetTime.getTime() - now.getTime()) / 1000))
    }, 1000)
  }, [targetTime, diffInSeconds])

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        <GreyBox display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            {Math.floor(diffInSeconds / (3600 * 24)) < 10
              ? `0${Math.floor(diffInSeconds / (3600 * 24))}`
              : `${Math.floor(diffInSeconds / (3600 * 24))}`}
          </Typography>
        </GreyBox>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary" align="center">
            DAYS
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop="10px" marginX="4px">
        <Typography variant="h4" color="textSecondary">
          :
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <GreyBox display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            {`0${Math.floor((diffInSeconds % (3600 * 24)) / 3600)}`.substr(-2)}
          </Typography>
        </GreyBox>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary" align="center">
            HOURS
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop="10px" marginX="4px">
        <Typography variant="h4" color="textSecondary">
          :
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <GreyBox display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            {`0${Math.floor((diffInSeconds % 3600) / 60)}`.substr(-2)}
          </Typography>
        </GreyBox>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary" align="center">
            MINS
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop="10px" marginX="4px">
        <Typography variant="h4" color="textSecondary">
          :
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <GreyBox display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            {`0${Math.floor(diffInSeconds % 60)}`.substr(-2)}
          </Typography>
        </GreyBox>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary" align="center">
            SECS
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Countdown
