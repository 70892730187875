import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'
import { useToast } from 'state/hooks'
// import { useDispatch } from 'react-redux'
import { mutate } from 'swr'
import { ethers } from 'ethers'
import { getBloctoSDK, connectorsByName } from 'utils/web3React'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import SynapsClient from '../components/SynapsModal'

// kyc session URL
const endpoint = 'https://api.impossible.finance/.netlify/functions/kycSession'

// Sign message via wallet to verify ownership of address
export const useKycSession = () => {
  // const dispatch = useDispatch()
  const { account, chainId } = useWeb3React()
  const [isLoading, setLoading] = useState(false)
  const { toastError } = useToast()
  const bloctoSDK = getBloctoSDK(56)

  const handleSigning = useCallback(async () => {
    if (!account) {
      console.info('Wallet not connected')
      return false
    }
    setLoading(true)
    try {
      // generate message for user to sign
      const currentDate = Date.now()
      const messageToSign = `Verifying wallet\nDate: ${currentDate}`
      const messageToSignBytes = ethers.utils.toUtf8Bytes(`Verifying wallet\nDate: ${currentDate}`)
      // get injections into web page
      const binanceWalletInject = (window as any).BinanceChain
      const metamaskInject = (window as any).ethereum

      // prompt user to sign message via wallet
      let provider
      const connector = connectorsByName[window.localStorage.connectorId]

      if (window.localStorage.connectorId === 'blocto' && bloctoSDK.ethereum) {
        provider = new ethers.providers.Web3Provider(bloctoSDK.ethereum)
      } else if (window.localStorage.connectorId === 'bsc' && binanceWalletInject) {
        // exact match binance wallet
        provider = new ethers.providers.Web3Provider(binanceWalletInject)
      } else if (window.localStorage.connectorId === 'injected' && metamaskInject) {
        // exact match metamask
        provider = new ethers.providers.Web3Provider(metamaskInject)
      } else if (connector instanceof WalletConnectConnector) {
        const walletConnector = connector as WalletConnectConnector
        provider = new ethers.providers.Web3Provider(walletConnector.walletConnectProvider)
      } else if (metamaskInject) {
        // try metamask injection
        provider = new ethers.providers.Web3Provider(metamaskInject)
      } else if (binanceWalletInject) {
        // try binance wallet injection
        provider = new ethers.providers.Web3Provider(binanceWalletInject)
      } else {
        throw Error('Wallet incompatible for signing. We recommend Metamask or BSC Wallet.')
      }
      const signer = provider.getSigner()
      const addr = await signer.getAddress()

      let signature

      try {
        // first try personal sign
        signature = await provider.send('personal_sign', [ethers.utils.hexlify(messageToSignBytes), addr.toLowerCase()])
      } catch (e: any) {
        console.info(e)
      }

      if (!signature) {
        try {
          // then try eth sign
          signature = await provider.send('eth_sign', [addr, ethers.utils.hexlify(messageToSignBytes)])
        } catch (e2: any) {
          console.info(e2)
          throw Error(e2.error)
        }
      }
      // const signature = await web3.eth.personal.sign(messageToSign, account, null)

      // transmit signature to kyc endpoint
      const sessionId = (
        await (
          await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({ messageToSign, signature, address: account, chainId }),
          })
        ).json()
      ).session_id

      // initialize and open synaps modal
      try {
        const Synaps = new SynapsClient(sessionId, 'workflow')
        Synaps.init()
        Synaps.on('close', () => {
          // callback on modal close
          mutate(`useKycStatus|${account}`)
          setLoading(false)
        })
        Synaps.on('finish', () => {
          // callback on flow finish (completed onboarding)
          mutate(`useKycStatus|${account}`)
          setLoading(false)
        })
        Synaps.openSession()
      } catch (e: any) {
        setLoading(false)
        toastError('Error', e.message)
        return false
      }

      return true
    } catch (e: any) {
      console.info('KYC session error', e)
      setLoading(false)
      toastError('Error', e.message)
      return false
    }
  }, [account, toastError, chainId, bloctoSDK.ethereum])

  return { onSession: handleSigning, isLoading }
}

export default useKycSession
