import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import BigNumber from 'bignumber.js'
import { Token } from 'config/constants/types'
import { IDOSaleToken } from 'state/types'
import format from 'date-fns/format'
import differenceInHours from 'date-fns/differenceInHours'
import AddToCalendar from 'components/AddToCalendar'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

type ConfirmedAllocationPeriodProps = {
  endTime: Date
  startTime: Date
  token: IDOSaleToken
  allocation: BigNumber
  totalCoin: BigNumber
  paymentToken: Token
}

const ConfirmedAllocationPeriod: React.FC<ConfirmedAllocationPeriodProps> = ({
  startTime,
  endTime,
  token,
  allocation,
  totalCoin,
  paymentToken,
}) => {
  const { t } = useTranslation()

  // TODO: Change this to use translation
  const title = `${token.symbol} Purchase begins!`
  const hourDiff = differenceInHours(endTime, startTime)
  const description = `You have ${hourDiff} hours to purchase ${token.symbol}! 
  Purchase starts from ${format(startTime, 'LLL do yyyy KK:mm aa OOOO')} to ${format(
    endTime,
    'LLL do yyyy KK:mm aa OOOO',
  )} ${window.location.href.split('?')[0]}`

  return (
    <Box>
      <Box>
        <Typography variant="body2" color="textPrimary" align="center">
          {t('Remember to return to this page when the purchase period starts to purchase your tokens.')}
        </Typography>
      </Box>
      <Box marginTop={9} display="flex" justifyContent="center" alignItems="center">
        {/* Used to center the image */}
        <AddToCalendar title={title} description={description} startTime={startTime} hide />
        <img src="/images/idos/Rocket.svg" width="100px" alt="waiting chair" />
        <AddToCalendar title={title} description={description} startTime={startTime} />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Purchase starts in')}
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={startTime} />
      </Box>
      <Box marginTop={6} display="flex" justifyContent="center">
        <BorderedInfoCard width="370px">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              {t('Your Allocation')}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {t('You will need')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginTop="6px">
            <Box display="flex" alignItems="center" flexDirection="row">
              <img src={token.image} alt="token" width="26px" />
              <Box marginRight="6px" />
              <Typography variant="h2" color="textPrimary">
                {totalCoin.isEqualTo(0) ? t('Loading...') : totalCoin.decimalPlaces(5).toNumber().toLocaleString()}
              </Typography>
            </Box>

            <Typography variant="body1" color="textPrimary">
              {allocation.decimalPlaces(5).toNumber().toLocaleString()} {paymentToken.symbol}
            </Typography>
          </Box>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default ConfirmedAllocationPeriod
