import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { registerToken } from 'utils/wallet'
import AirDropWarning from 'components/AirDropWarning'
import { AirdropInfo } from 'state/types'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

type PublicSaleEndedProps = {
  participants?: number
  token: string
  address: string
  sales?: number
  paymentToken: string
  claimedShare?: string
  airdropInfo?: AirdropInfo
}

const PublicSaleEnded: React.FC<PublicSaleEndedProps> = ({
  participants,
  token,
  address,
  sales,
  claimedShare,
  paymentToken,
  airdropInfo,
}) => {
  const { t } = useTranslation()

  return (
    <Box marginTop={8} paddingX={0}>
      {airdropInfo?.isAirdropped && (
        <AirDropWarning
          tokenName={token}
          firstDayRelease={airdropInfo.firstDayRelease}
          firstDate={airdropInfo.initialAirdrop}
          finalDate={airdropInfo.finalAirdrop}
        />
      )}
      <Box marginTop={6} display="flex" justifyContent="center">
        <img src="/images/idos/Moon.svg" width="100px" alt="time sand" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('The %token% sale has ended.', { token })}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" paddingX={0} style={{ flexWrap: 'wrap' }}>
        {participants && (
          <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {t('Participants')}
            </Typography>
            <Typography variant="h4" color="textSecondary" align="center">
              {participants?.toLocaleString()}
            </Typography>
          </BorderedInfoCard>
        )}
        {sales && (
          <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {t('Total Sales')}
            </Typography>
            <Typography variant="h4" color="textSecondary" align="center">
              {sales.toLocaleString()} {paymentToken}
            </Typography>
          </BorderedInfoCard>
        )}
        {parseInt(claimedShare) > 0 && (
          <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {airdropInfo?.isAirdropped ? t('Your Share') : t('Claimed Share')}
            </Typography>
            <Typography variant="h4" color="textSecondary" align="center">
              {parseFloat(claimedShare).toLocaleString()} {token}
            </Typography>
          </BorderedInfoCard>
        )}
      </Box>
      {address && (
        <Box display="flex" justifyContent="center" marginTop={5}>
          <Button onClick={() => registerToken(address, token, 18)} style={{ color: '#0AC6E5' }}>
            {t('Add %token% to the wallet', { token })}
            <img style={{ marginLeft: 8 }} src="/images/idos/Wallet.svg" alt="add to wallet" />
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default PublicSaleEnded
