import React from 'react'
import { Language } from 'state/types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useMatchBreakpoints } from 'uikit'

import Text from '../../../components/Text/Text'
import Dropdown from '../../../components/Dropdown/Dropdown'
import Button from '../../../components/Button/Button'
import MenuButton from './MenuButton'

interface Props {
  currentLang: Language
  langs: Language[]
  setLang: (lang: Language) => void
}

const LangSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  return (
    <Dropdown
      position="bottom-left"
      target={
        <Button paddingLeft="24px" paddingRight="0px" variant="text" endIcon={<ArrowDropDownIcon width="24px" />}>
          <Text color="textSubtle">{isMobile ? currentLang?.code.toUpperCase() : currentLang?.language}</Text>
        </Button>
      }
    >
      {langs.map((lang) => (
        <MenuButton
          key={lang.code}
          fullWidth
          onClick={() => setLang(lang)}
          // Safari fix
          style={{ minHeight: '32px', height: 'auto' }}
        >
          {lang.language}
        </MenuButton>
      ))}
    </Dropdown>
  )
}

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang)
