import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMulticallContract } from './useContract'

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const { chainId } = useWeb3React()
  const multicall = useMulticallContract(chainId)
  return useSingleCallResult(multicall, chainId, 'getCurrentBlockTimestamp')?.result?.[0]
}
