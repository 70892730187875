import { useState, useEffect, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useToast } from 'state/hooks'
import BigNumber from 'bignumber.js'
import { useDispatch } from 'react-redux'
import { fetchPublicIdoUserDataAsync } from 'state/idos'
import { useIFAMasterContract } from './useContract'
import useRefresh from './useRefresh'

// view function returns -> user stakeWeight
export const useUserStakeWeight = (masterAddress: string, trackId: number, blockNumber: number) => {
  const [userStakeWeight, setUserStakeWeight] = useState()
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const IFMasterContract = useIFAMasterContract(masterAddress)

  useEffect(() => {
    const fetchWeight = async () => {
      try {
        let res = await IFMasterContract.methods.getUserStakeWeight(trackId, account, blockNumber)
        res = await res.call()
        setUserStakeWeight(res)
      } catch (e) {
        console.error(e)
      }
    }
    if (account) {
      fetchWeight()
    }
  }, [fastRefresh, IFMasterContract.methods, account, blockNumber, trackId])

  return userStakeWeight
}

// view function returns -> total stakeWeight
export const useTotalStakeWeight = (masterAddress: string, trackId: number, blockNumber: number) => {
  const { fastRefresh } = useRefresh()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const [stakeWeight, setStakeWeight] = useState()

  useEffect(() => {
    const fetchWeight = async () => {
      try {
        let res = await IFMasterContract.methods.getTotalStakeWeight(trackId, blockNumber)
        res = await res.call()
        setStakeWeight(res)
      } catch (e) {
        console.error(e)
      }
    }
    fetchWeight()
  }, [fastRefresh, IFMasterContract.methods, blockNumber, trackId])

  return stakeWeight
}

// view function returns -> totalStake
export const useTrackTotalStake = (masterAddress: string, trackId: number) => {
  const { fastRefresh } = useRefresh()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const [checkpointCount, setCheckpointCount] = useState(new BigNumber(0))

  useEffect(() => {
    const getTotalStaked = async () => {
      try {
        let res = await IFMasterContract.methods.trackCheckpointCounts(trackId)
        const checkpoints = await res.call()
        if (checkpoints === '0') {
          setCheckpointCount(new BigNumber(0))
        } else {
          res = await IFMasterContract.methods.trackCheckpoints(trackId, checkpoints - 1)
          const totalCount = await res.call()
          setCheckpointCount(new BigNumber(totalCount.totalStaked) || new BigNumber(0))
        }
      } catch (e) {
        console.error(e)
      }
    }
    getTotalStaked()
  }, [fastRefresh, IFMasterContract.methods, trackId])

  return checkpointCount
}

// get staked amount
export const useStakeAmount = (masterAddress: string, pid: number): BigNumber => {
  const [stakedAmount, setStakedAmount] = useState(new BigNumber(0))
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const IFMasterContract = useIFAMasterContract(masterAddress)

  useEffect(() => {
    const getStakedData = async () => {
      let res = await IFMasterContract.methods.userCheckpointCounts(pid, account)
      const checkpoints = await res.call()
      if (checkpoints === '0') {
        setStakedAmount(new BigNumber(0))
      } else {
        res = await IFMasterContract.methods.userCheckpoints(pid, account, checkpoints - 1)
        const staked = await res.call()
        setStakedAmount(new BigNumber(staked.staked) || new BigNumber(0))
      }
    }
    if (account) {
      getStakedData()
    }
  }, [IFMasterContract.methods, account, fastRefresh, pid])

  return stakedAmount
}

// stake
export const useStake = (masterAddress: string, trackId: number) => {
  const { chainId, account } = useWeb3React()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const [txHash, setTxHash] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { toastError } = useToast()
  const dispatch = useDispatch()

  const handleStake = useCallback(
    async (amount: string, onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        const tx = await IFMasterContract.methods.stake(trackId, amount).send({ from: account })
        dispatch(fetchPublicIdoUserDataAsync(account, chainId))
        setTxHash(tx.transactionHash)
        setIsLoading(false)
        onSuccess()
      } catch (e) {
        console.error(e)
        setIsLoading(false)
        toastError('Error', e.message)
      }
    },
    [account, trackId, IFMasterContract, toastError, dispatch, chainId],
  )

  return { onStake: handleStake, isLoading, txHash }
}

// unstake
export const useUnstake = (masterAddress: string, trackId: number) => {
  const { chainId, account } = useWeb3React()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const [txHash, setTxHash] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { toastError } = useToast()
  const dispatch = useDispatch()

  const handleUnstake = useCallback(
    async (amount: string, onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        const tx = await IFMasterContract.methods.unstake(trackId, amount).send({ from: account })
        dispatch(fetchPublicIdoUserDataAsync(account, chainId))
        setTxHash(tx.transactionHash)
        setIsLoading(false)
        onSuccess()
      } catch (e) {
        console.error(e)
        setIsLoading(false)
        toastError('Error', e.message)
      }
    },
    [account, trackId, IFMasterContract, toastError, dispatch, chainId],
  )

  return { onUnstake: handleUnstake, isLoading, txHash }
}
