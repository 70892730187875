import React, { useState, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { Tabs, Tab, TabPanel } from 'components/Tabs/Tabs'
import { IDOConfig, PublicSaleConfig, IDOType, BonusSaleConfig } from 'state/types'
import { useWeb3React } from '@web3-react/core'
import PublicSale from './PublicSale'
import PrivateSale from './PrivateSale'
import BonusSale from './BonusSale'
import WrongNetwork from '../WrongNetwork'
import FreeAirdropSale from './FreeAirdropSale'

type MainPanelProps = {
  projectDetail: IDOConfig
}

const MainPanel: React.FC<MainPanelProps> = ({ projectDetail }) => {
  const [activeTab, setActiveTab] = useState(0)
  const handleChange = useCallback((event, newTabValue) => setActiveTab(newTabValue), [setActiveTab])
  const { account, chainId } = useWeb3React()

  const renderProjectDetail = useCallback(() => {
    const projectType = projectDetail.type
    const isCorrectNetwork = projectDetail.chainId === chainId
    if (account && !isCorrectNetwork) {
      return <WrongNetwork expectedChain={projectDetail.chainId} />
    }

    if (projectType === IDOType.PUBLIC_SALE) {
      if (projectDetail.isFreeAirdrop) {
        return <FreeAirdropSale project={projectDetail as PublicSaleConfig} />
      }
      if (projectDetail.isPrivate) {
        return <PrivateSale project={projectDetail as PublicSaleConfig} />
      }
      return <PublicSale project={projectDetail as PublicSaleConfig} />
    }
    return <BonusSale project={projectDetail as BonusSaleConfig} />
  }, [projectDetail, chainId, account])

  return (
    <Box padding={6}>
      {!projectDetail.isPrivate && (
        <Tabs value={activeTab} onChange={handleChange} aria-label="tabs">
          <Tab label="Sale" key={projectDetail.type} />
        </Tabs>
      )}
      <TabPanel value={activeTab} index={0} key="Sale">
        {renderProjectDetail()}
      </TabPanel>
    </Box>
  )
}

export default MainPanel
