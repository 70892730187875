import { Chain, Token } from './types'

const tokens: { [key: string]: Token } = {
  busd: {
    symbol: 'BUSD',
    address: {
      [Chain.BSC_MAINNET]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      [Chain.BSC_TESTNET]: '0x1df90b035a9bd57c8ab13f72ca41ad1581928b54',
      [Chain.POLYGON_MAINNET]: '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
      [Chain.MOONRIVER_MAINNET]: '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
      [Chain.AVAX_MAINNET]: '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818', // Dummy Address
      [Chain.MOONBEAM_MAINNET]: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
      [Chain.AVAX_MAINNET]: '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
      [Chain.ETH_MAINNET]: '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818', // Dummy Address
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  if: {
    symbol: 'IF',
    address: {
      [Chain.BSC_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
      [Chain.BSC_TESTNET]: '0xf9e0a9e57b6cd690cebfcce12a8fc14a9ab134be',
      [Chain.POLYGON_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
    },
    decimals: 18,
  },
  idia: {
    symbol: 'IDIA',
    address: {
      [Chain.BSC_MAINNET]: '0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89',
      [Chain.BSC_TESTNET]: '0xf9e0a9e57b6cd690cebfcce12a8fc14a9ab134be',
      [Chain.POLYGON_MAINNET]: '0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89',
      [Chain.MOONRIVER_MAINNET]: '0x2d28AA28fA1E5e6bF121CF688309Bf3faAAe3C70',
      [Chain.AVAX_MAINNET]: '0x559f7afc3312ec94a8cc0dd6275716c68d345787',
      [Chain.ETH_MAINNET]: '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818', // Dummy Address
      [Chain.MOONBEAM_MAINNET]: '0x559f7afc3312ec94a8cc0dd6275716c68d345787',
      [Chain.AURORA_MAINNET]: '0x2eFEDEBD6e29963511589731a33311196f00eaCb',
      [Chain.BOBA_MAINNET]: '0x2eFEDEBD6e29963511589731a33311196f00eaCb',
    },
    decimals: 18,
  },
  usdc: {
    symbol: 'USDC',
    address: {
      [Chain.BSC_MAINNET]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      [Chain.MOONRIVER_MAINNET]: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
    },
    decimals: 18,
  },
  usdc6: {
    symbol: 'USDC',
    address: {
      [Chain.MOONRIVER_MAINNET]: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
      [Chain.AVAX_MAINNET]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      [Chain.MOONBEAM_MAINNET]: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
      [Chain.AURORA_MAINNET]: '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
      [Chain.BOBA_MAINNET]: '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
    },
    decimals: 6,
  },
  usdt: {
    symbol: 'USDT',
    address: {
      [Chain.BSC_MAINNET]: '0x55d398326f99059ff775485246999027b3197955',
      [Chain.MOONRIVER_MAINNET]: '0xb44a9b6905af7c801311e8f4e76932ee959c663c',
    },
    decimals: 18,
  },
  usdt6: {
    symbol: 'USDT',
    address: {
      [Chain.MOONBEAM_MAINNET]: '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
      [Chain.AVAX_MAINNET]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
      [Chain.AURORA_MAINNET]: '0x4988a896b1227218e4a686fde5eabdcabd91571f',
      [Chain.BOBA_MAINNET]: '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
    },
    decimals: 18,
  },
  dai: {
    symbol: 'DAI',
    address: {
      [Chain.BSC_MAINNET]: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      [Chain.MOONRIVER_MAINNET]: '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
    },
    decimals: 18,
  },
  PAYTOKEN: {
    symbol: 'TESTPAY',
    address: {
      [Chain.BSC_MAINNET]: '',
      [Chain.BSC_TESTNET]: '0x1df90b035a9bd57c8ab13f72ca41ad1581928b54',
      [Chain.POLYGON_MAINNET]: '',
      [Chain.MOONRIVER_MAINNET]: '',
      [Chain.AVAX_MAINNET]: '', // Dummy Address
      [Chain.ETH_MAINNET]: '',
    },
    decimals: 18,
  },
  STAKINGTOKEN: {
    symbol: 'STAKING',
    address: {
      [Chain.BSC_MAINNET]: '',
      [Chain.BSC_TESTNET]: '0xf9e0a9e57b6cd690cebfcce12a8fc14a9ab134be',
      [Chain.POLYGON_MAINNET]: '',
      [Chain.MOONRIVER_MAINNET]: '',
      [Chain.AVAX_MAINNET]: '', // Dummy Address
      [Chain.ETH_MAINNET]: '',
    },
    decimals: 18,
  },
}

export default tokens
