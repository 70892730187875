/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */

import invariant from 'tiny-invariant'
import { Chain } from 'config/constants/types'
import getGasToken from 'utils/getGasToken'

import { Currency, ETHER } from './currency'
import { Pair } from './pair'
import { Price } from './fractions/price'
import { TokenClass } from './token'

export class Route {
  public readonly pairs: Pair[]

  public readonly path: TokenClass[]

  public readonly input: Currency

  public readonly output: Currency

  public readonly midPrice: Price

  public constructor(pairs: Pair[], input: Currency, output?: Currency) {
    invariant(pairs.length > 0, 'PAIRS')
    invariant(
      pairs.every((pair) => pair.chainId === pairs[0].chainId),
      'CHAIN_IDS',
    )
    invariant(
      (input instanceof TokenClass && pairs[0].involvesToken(input)) ||
        (input === ETHER && pairs[0].involvesToken(getGasToken(pairs[0].chainId))),
      'INPUT',
    )
    invariant(
      typeof output === 'undefined' ||
        (output instanceof TokenClass && pairs[pairs.length - 1].involvesToken(output)) ||
        (output === ETHER && pairs[pairs.length - 1].involvesToken(getGasToken(pairs[0].chainId))),
      'OUTPUT',
    )

    const path: TokenClass[] = [input instanceof TokenClass ? input : getGasToken(pairs[0].chainId)]
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i]
      invariant(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), 'PATH')
      const token = currentInput.equals(pair.token0) ? pair.token1 : pair.token0
      path.push(token)
    }

    this.pairs = pairs
    this.path = path
    this.midPrice = Price.fromRoute(this)
    this.input = input
    this.output = output ?? path[path.length - 1]
  }

  public get chainId(): Chain {
    return this.pairs[0].chainId
  }
}

export default null
