import React, { ReactNode } from 'react'
import { Tooltip, withStyles } from '@material-ui/core'

interface IFTooltipProps {
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  title: ReactNode
  children?: any
}

const IFStyleTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#050A5A',
    color: 'white',
    padding: 16,
    borderRadius: 8,
    fontSize: 13,
    whiteSpace: 'pre-line',
  },
}))(Tooltip)

const IFTooltip: React.FC<IFTooltipProps> = ({ title = 'title', placement = 'right-start', children }) => {
  return (
    <IFStyleTooltip enterTouchDelay={100} title={title} placement={placement}>
      {children}
    </IFStyleTooltip>
  )
}

export default IFTooltip
