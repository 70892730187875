import { useMemo } from 'react'
import useWeb3 from 'hooks/useWeb3'
import {
  getBep20Contract,
  getMasterchefContract,
  getSouschefContract,
  getIFASaleContract,
  getIFAMasterContract,
  getSolvMarketContract,
  getSolvVoucherContract,
} from 'utils/contractHelpers'
import { getENSRegistrarAddress, getMulticallAddress, getRouterAddress, getWGasAddress } from 'utils/addressHelpers'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { Chain } from 'config/constants/types'
import { getContract } from 'utils'
import multiCallAbi from 'config/abi/Multicall.json'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import WETH_ABI from 'config/abi/weth.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { ERC20_BYTES32_ABI } from 'config/abi/erc20'
import ENS_PUBLIC_RESOLVER_ABI from 'config/abi/ens-public-resolver.json'
import ENS_ABI from 'config/abi/ens-registrar.json'
import ERC20_ABI from 'config/abi/erc20.json'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

export const useMasterchef = () => {
  const web3 = useWeb3()
  return useMemo(() => getMasterchefContract(web3), [web3])
}

export const useSousChef = (id) => {
  const web3 = useWeb3()
  return useMemo(() => getSouschefContract(id, web3), [id, web3])
}

export const useIFAMasterContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getIFAMasterContract(address, web3), [address, web3])
}

export const useIFASaleContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getIFASaleContract(address, web3), [address, web3])
}

export const useSolvVoucherContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getSolvVoucherContract(address, web3), [address, web3])
}

export const useSolvMarketContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getSolvMarketContract(address, web3), [address, web3])
}

// Migrated from SWAP
// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWGasContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(getWGasAddress(chainId), WETH_ABI, false)
}

export function useBytes32TokenContract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, false)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, false)
}

export function useMulticallContract(chainId: Chain): Contract | null {
  return useContract(getMulticallAddress(chainId), multiCallAbi, false)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const web3 = useWeb3()
  const { chainId } = useWeb3React()
  const contract = useContract(getENSRegistrarAddress(chainId), ENS_ABI, withSignerIfPossible)
  if (!contract) {
    return null
  }
  return contract
}

export function useENSResolverContract(address: string): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, false)
}

export function useRouterContract(chainId: Chain): Contract | null {
  return useContract(getRouterAddress(chainId), IUniswapV2Router02ABI, true)
}
