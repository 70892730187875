import { Box, Typography, withStyles } from '@material-ui/core'
import React, { useCallback, useState, useEffect } from 'react'
import SmallCountdown from 'components/SmallCountdown'
import { Button } from 'uikit'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import Tooltip from 'views/Farms/components/Tooltip/Tooltip'
import { roundDecimals } from 'utils/formatBalance'

const GreyBox = withStyles({
  root: {
    borderRadius: '8px',
    padding: '16px',
    background: '#F2F4F5',
    width: '100%',
  },
})(Box)

const CircleGrey = withStyles({
  root: {
    borderRadius: '50%',
    padding: '16px',
    background: '#F2F4F5',
    width: '33px',
    border: 'solid 7px white',
    margin: '-20px 0',
    zIndex: 100,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})(Box)

const StyledInput = styled.input`
  border: none;
  background: transparent;
  color: #3b4346;
  font-size: 34px;
  font-weight: 500;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: #A6A8AA;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
}    
`

type Token = {
  name: string
  image: string
}

type TradeBoxProps = {
  token0Balances: BigNumber
  token0: Token
  token1: Token
  endTime: Date
  pricePerToken: number
  minimumPurchase: number
  maximumPurchase: BigNumber
  showMax?: boolean
  tooltip?: string
  disabled?: boolean
  purchasedCounter?: number
  onChange: (value: number) => void
  onError: (error?: string) => void
}

const TradeBox: React.FC<TradeBoxProps> = ({
  token0Balances,
  token0,
  token1,
  endTime,
  pricePerToken,
  minimumPurchase,
  maximumPurchase,
  showMax,
  disabled,
  purchasedCounter = 0,
  onChange,
  onError,
  tooltip,
}) => {
  const [values, setValues] = useState([])

  const checkError = useCallback(
    (stableValue) => {
      if (new BigNumber(stableValue).isGreaterThan(token0Balances)) {
        onError('Insufficient balance')
      } else if (new BigNumber(stableValue).isGreaterThan(maximumPurchase)) {
        onError('Exceeding allocation')
      } else if (stableValue < minimumPurchase) {
        onError('The purchase amount is too low to proceed.')
      } else if (parseFloat(stableValue) <= 0) {
        onError('Invalid input')
      } else {
        onError(undefined)
      }
    },
    [token0Balances, onError, minimumPurchase, maximumPurchase],
  )

  const onSelectMax = useCallback(() => {
    const defaultToken0Amount = BigNumber.min(
      BigNumber.max(new BigNumber(0), maximumPurchase),
      token0Balances,
    ).decimalPlaces(5, BigNumber.ROUND_FLOOR)
    setValues([defaultToken0Amount, defaultToken0Amount.div(new BigNumber(pricePerToken))])
    onChange(defaultToken0Amount.toNumber())
    checkError(defaultToken0Amount.toString())
  }, [setValues, maximumPurchase, pricePerToken, onChange, token0Balances, checkError])

  const onChangeToken0 = useCallback(
    (evt) => {
      const text = evt.target.value
      const bigNumberValue = new BigNumber(text)
      setValues([text, bigNumberValue.div(new BigNumber(pricePerToken))])
      onChange(text)
      checkError(text)
    },
    [pricePerToken, onChange, checkError],
  )
  const onChangeToken1 = useCallback(
    (evt) => {
      const text = evt.target.value
      const bigNumberValue = new BigNumber(text)
      const stableValue = bigNumberValue.times(new BigNumber(pricePerToken))
      setValues([stableValue.toString(), text])
      onChange(stableValue.toNumber())
      checkError(stableValue.toString())
    },
    [pricePerToken, onChange, checkError],
  )

  useEffect(() => {
    if (purchasedCounter > 0) {
      setValues(['', ''])
      onChange(0)
    }
  }, [onChange, purchasedCounter, setValues])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="370px">
      <Box alignSelf="flex-end" display="flex">
        <SmallCountdown label="Purchase ends in" targetTime={new Date(endTime)} />
      </Box>
      <GreyBox marginTop="-14px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            From
          </Typography>
          <Typography variant="body2" color="textPrimary">
            Balance: {token0Balances.decimalPlaces(5).toString()} {token0.name}
          </Typography>
        </Box>
        <Box marginTop={2} display="flex" alignItems="center">
          <img src={token0.image} alt={token0.name} width="26px" />
          <Box marginRight="6px" />
          <StyledInput
            type="number"
            onChange={onChangeToken0}
            placeholder="0"
            value={roundDecimals(values[0])}
            disabled={disabled}
          />
          {showMax && (
            <Button scale="sm" onClick={onSelectMax} disabled={disabled}>
              MAX
            </Button>
          )}
        </Box>
      </GreyBox>
      <CircleGrey>
        <Box position="absolute" paddingTop="5px" paddingLeft="2px">
          <img src="/images/idos/arrow-down.svg" alt="arrow" />
        </Box>
      </CircleGrey>
      <GreyBox>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            To
          </Typography>
          <Box display="flex" justifyContent="right" alignItems="center">
            <Box>
              <Typography variant="body2" color="textPrimary">
                1 {token1.name} = {roundDecimals(pricePerToken)} {token0.name}
              </Typography>
            </Box>
            {tooltip && (
              <Tooltip content={<div>{tooltip}</div>}>
                <img src="/images/HelpIcon.svg" alt="help" />
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box marginTop={2} display="flex" alignItems="center">
          <img src={token1.image} alt={token1.name} width="26px" />
          <Box marginRight="6px" />
          <StyledInput
            type="number"
            onChange={onChangeToken1}
            placeholder="0"
            value={roundDecimals(values[1])}
            disabled={disabled}
          />
        </Box>
      </GreyBox>
    </Box>
  )
}

export default TradeBox
