import React from 'react'
import { Box, Grid, Divider, Button, Typography } from '@material-ui/core'
import { Flex, Text } from 'uikit'
import { registerToken } from 'utils/wallet'
import styled from 'styled-components'

import { BonusSaleConfig, IDOConfig, PublicSaleConfig, IDOType } from 'state/types'
import format from 'date-fns/format'
import { useTranslation } from 'contexts/Localization'
import WalletCopy from 'components/WalletCopy'

import { BorderedInfoCard } from '../StyledComponent'

type SaleDetailsProps = {
  projectDetail: IDOConfig
}

const DividerContainer = styled.div`
  margin-left: -16px;
  margin-right: 16px;
  width: calc(100% + 32px);
`
const DetailBox = styled.div`
  display: block;
  margin: 16px 0;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
const DateText = styled.div`
  font-size: 15px;
  text-align: left;
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: right;
  }
`

const SaleDetails: React.FC<SaleDetailsProps> = ({ projectDetail }) => {
  const { t } = useTranslation()
  const { type, airdropInfo } = projectDetail
  const { purchasePeriod, subscribePeriod, claimPeriod, isFreeAirdrop } = projectDetail as PublicSaleConfig
  const { startTime, endTime, saleDetails } = projectDetail as BonusSaleConfig

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} md={6}>
        <BorderedInfoCard>
          <Flex mb="48px">
            <Text fontWeight="500" fontSize="16px">
              {t('Pool Information')}
            </Text>
          </Flex>
          {saleDetails?.highest && (
            <>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Token Sale Price')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  1 {projectDetail.token.symbol} = {saleDetails.lowest}~{saleDetails.highest}{' '}
                  {projectDetail.paymentToken.symbol}
                </Typography>
              </DetailBox>
            </>
          )}
          {purchasePeriod?.salePrice && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Public Sale Token Price')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  1 {projectDetail.token.symbol} = {purchasePeriod.salePrice} {projectDetail.paymentToken.symbol}
                </Typography>
              </DetailBox>
            </>
          )}
          {type === IDOType.BONUS_SALE && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Purchase Opens')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {format(new Date(startTime), 'LLL do yyyy KK:mm aa OOO')}
                </Typography>
              </DetailBox>
            </>
          )}
          {type === IDOType.BONUS_SALE && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Purchase Closes')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {format(new Date(endTime), 'LLL do yyyy KK:mm aa OOO')}
                </Typography>
              </DetailBox>
            </>
          )}
          {type === IDOType.BONUS_SALE && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Claimable')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {format(new Date(endTime), 'LLL do yyyy KK:mm aa OOO')}
                </Typography>
              </DetailBox>
            </>
          )}
          {subscribePeriod && subscribePeriod.startTime && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Subscription starts')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {format(new Date(subscribePeriod.startTime), 'LLL do yyyy KK:mm aa OOO')}
                </Typography>
              </DetailBox>
            </>
          )}
          {subscribePeriod && subscribePeriod.endTime && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Subscription ends')}
                </Typography>
                <DateText>
                  <Box>
                    <Typography variant="body2" color="textPrimary">
                      {`${t('Estimated')}: ${format(new Date(subscribePeriod.endTime), 'LLL do yyyy KK:mm aa OOO')}`}
                    </Typography>
                  </Box>
                  {purchasePeriod.allocSnapshotBlock && (
                    <Box>
                      <Text fontSize="13px" color="#A6A8AA">
                        {`${t('Block Number')}: ${purchasePeriod.allocSnapshotBlock.toString()}`}
                      </Text>
                    </Box>
                  )}
                </DateText>
              </DetailBox>
            </>
          )}
          {purchasePeriod && purchasePeriod.startTime && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Purchase opens')}
                </Typography>
                <DateText>
                  <Box>
                    <Typography variant="body2" color="textPrimary">
                      {`${format(new Date(purchasePeriod.startTime), 'LLL do yyyy KK:mm aa OOO')}`}
                    </Typography>
                  </Box>
                  {purchasePeriod.startBlock && (
                    <Box>
                      <Text fontSize="13px" color="#A6A8AA">
                        {`${t('Block Number')}: ${purchasePeriod.startBlock.toString()}`}
                      </Text>
                    </Box>
                  )}
                </DateText>
              </DetailBox>
            </>
          )}
          {purchasePeriod && purchasePeriod.endTime && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Purchase closes')}
                </Typography>
                <DateText>
                  <Typography variant="body2" color="textPrimary">
                    {`${format(new Date(purchasePeriod.endTime), 'LLL do yyyy KK:mm aa OOO')}`}
                  </Typography>
                  {purchasePeriod.endBlock && (
                    <Box>
                      <Text fontSize="13px" color="#A6A8AA">
                        {`${t('Block Number')}: ${purchasePeriod.endBlock.toString()}`}
                      </Text>
                    </Box>
                  )}
                </DateText>
              </DetailBox>
            </>
          )}
          {airdropInfo?.isAirdropped && airdropInfo?.initialAirdrop && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t(`${airdropInfo?.finalAirdrop ? 'Initial ' : ''}Airdrop`)}
                </Typography>
                <DateText>
                  <Typography variant="body2" color="textPrimary">
                    {`${t('Estimated')}: ${format(new Date(airdropInfo.initialAirdrop), 'LLL do yyyy KK:mm aa OOO')}`}
                  </Typography>
                </DateText>
              </DetailBox>
            </>
          )}
          {airdropInfo?.isAirdropped && airdropInfo?.finalAirdrop && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Final Airdrop')}
                </Typography>
                <DateText>
                  <Typography variant="body2" color="textPrimary">
                    {`${t('Estimated')}: ${format(new Date(airdropInfo.finalAirdrop), 'LLL do yyyy KK:mm aa OOO')}`}
                  </Typography>
                </DateText>
              </DetailBox>
            </>
          )}
          {type === IDOType.PUBLIC_SALE && !airdropInfo?.isAirdropped && (
            <>
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <DetailBox>
                <Typography variant="body2" color="textPrimary">
                  {t('Claimable')}
                </Typography>
                <DateText>
                  {claimPeriod.startTimeTBD ? (
                    <Typography variant="body2" color="textPrimary">
                      {t('TBD')}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body2" color="textPrimary">
                        {`${t('Estimated')}: ${format(new Date(claimPeriod.startTime), 'LLL do yyyy KK:mm aa OOO')}`}
                      </Typography>
                      {claimPeriod.startBlock && (
                        <Box>
                          <Text fontSize="13px" color="#A6A8AA">
                            {`${t('Block Number')}: ${claimPeriod.startBlock.toString()}`}
                          </Text>
                        </Box>
                      )}
                    </>
                  )}
                </DateText>
              </DetailBox>
            </>
          )}
        </BorderedInfoCard>
      </Grid>
      <Grid item xs={12} lg={6} md={6}>
        <BorderedInfoCard>
          <Flex mb="48px">
            <Text fontWeight={500} fontSize="16px">
              {t('Token Information')}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" my="16px">
            <Text>{t('Token')}</Text>
            <Text>{projectDetail.token.name}</Text>
          </Flex>
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <Flex justifyContent="space-between" my="16px">
            <Text>{t('Symbol')}</Text>
            <Text>{projectDetail.token.symbol}</Text>
          </Flex>
          <DividerContainer>
            <Divider />
          </DividerContainer>
          {projectDetail?.token?.address && (
            <>
              <Flex justifyContent="space-between" my="16px">
                <Text>{t('Address')}</Text>
                <Text style={{ display: 'flex' }}>
                  <WalletCopy walletAddress={projectDetail.token.address} />
                  <Button
                    onClick={() =>
                      registerToken(
                        projectDetail.token.address,
                        projectDetail.token.symbol,
                        projectDetail.token.decimals,
                      )
                    }
                    style={{ minWidth: 30, padding: 0, background: '#F2F4F5' }}
                  >
                    <img src="/images/idos/walletBlack.svg" alt="add to wallet" />
                  </Button>
                </Text>
              </Flex>
              <DividerContainer>
                <Divider />
              </DividerContainer>
            </>
          )}

          <Flex justifyContent="space-between" my="16px">
            <Text>{t(`Token For ${projectDetail.isFreeAirdrop ? 'Drop' : 'Sale'}`)}</Text>
            <Text>
              {parseFloat(projectDetail.token.totalSupply).toLocaleString()} {projectDetail.token.symbol}
            </Text>
          </Flex>
        </BorderedInfoCard>
      </Grid>
    </Grid>
  )
}

export default SaleDetails
