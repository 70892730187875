import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { IDOType } from 'state/types'
import PublicSale from './PublicSale'
import BonusSale from './BonusSale'

interface MatchParams {
  address: string
  id: string
  type: string
}

const IDODetailPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const saleType = (match.params.type as unknown) as IDOType
  const saleID = match.params.id

  return saleType === IDOType.PUBLIC_SALE ? <PublicSale id={saleID} /> : <BonusSale id={saleID} />
}

export default withRouter(IDODetailPage)
