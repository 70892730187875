import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal } from 'uikit'
import { useUnstake } from 'hooks/useIFAMaster'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { getFullDisplayBalance, getBalanceInWei } from 'utils/formatBalance'
import { useToast } from 'state/hooks'

interface WithdrawModalProps {
  max: BigNumber
  onDismiss?: () => void
  tokenName?: string
  masterAddress: string
  trackId?: number
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ max, masterAddress, tokenName = '', trackId, onDismiss }) => {
  const [val, setVal] = useState('')
  const [error, setError] = useState(true)
  const { onUnstake, isLoading } = useUnstake(masterAddress, trackId)
  const { toastSuccess } = useToast()

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const onUnstakeSuccess = useCallback(() => {
    toastSuccess(`You've unstaked ${val} ${tokenName}`)
    onDismiss()
  }, [val, tokenName, toastSuccess, onDismiss])

  const handleUnstake = useCallback(() => {
    const amountInWei = getBalanceInWei(new BigNumber(val))
    onUnstake(amountInWei, onUnstakeSuccess)
  }, [val, onUnstake, onUnstakeSuccess])

  const checkError = useCallback(
    (amount: string) => {
      if (amount === '' || parseFloat(amount) <= 0) setError(true)
      else if (parseFloat(amount) > parseFloat(fullBalance)) setError(true)
      else setError(false)
    },
    [fullBalance],
  )

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
      checkError(e.currentTarget.value)
    },
    [setVal, checkError],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
    checkError(fullBalance)
  }, [fullBalance, setVal, checkError])

  return (
    <Modal title="Unstake tokens" onDismiss={onDismiss}>
      <ModalInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
        inputTitle="Input"
        balanceText="Staked"
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%">
          Cancel
        </Button>
        <Button disabled={isLoading || error} onClick={handleUnstake} width="100%">
          {isLoading ? 'Pending Confirmation' : 'Confirm'}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default WithdrawModal
