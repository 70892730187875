import React, { useCallback } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { useToast } from 'state/hooks'
import { useMerge, useClaim } from 'hooks/useSolv'
import { BorderedInfoCard } from '../../StyledComponent'

type ClaimProps = {
  nftBalance: number
  claimableShare: number
  voucherAddress: string
}

type MergeClaimTokensButtonProps = {
  voucherAddress: string
  nftBalance: number
  claimableShare: number
}

const MergeTokens: React.FC<MergeClaimTokensButtonProps> = ({ voucherAddress, nftBalance }) => {
  const { onMerge, isLoading } = useMerge(voucherAddress)
  const { onClaim, isLoading: isClaimLoading } = useClaim(voucherAddress)
  const { toastSuccess } = useToast()

  const onClaimSuccess = useCallback(() => {
    toastSuccess(`Successfully redeemed tokens`)
  }, [toastSuccess])

  const onMergeSuccess = useCallback(() => {
    toastSuccess(`Successfuly merge all purchases`)
    onClaim(onClaimSuccess)
  }, [onClaim, onClaimSuccess, toastSuccess])

  const onClickClaim = useCallback(() => {
    onClaim(onClaimSuccess)
  }, [onClaim, onClaimSuccess])

  const onClick = useCallback(() => {
    onMerge(onMergeSuccess)
  }, [onMerge, onMergeSuccess])

  return (
    <Button
      onClick={nftBalance > 1 ? onClick : onClickClaim}
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      fullWidth
      disabled={isLoading || isClaimLoading}
    >
      Claim
    </Button>
  )
}

const Claim: React.FC<ClaimProps> = ({ claimableShare, nftBalance, voucherAddress }) => {
  return (
    <Box marginTop={13} paddingX={0}>
      <Box display="flex" justifyContent="center">
        <img src="/images/idos/Hammer.svg" width="100px" alt="time sand" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          The Bonus Sale has ended.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" paddingX={0} style={{ flexWrap: 'wrap' }}>
        {claimableShare > 0 && (
          <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              Claimable Share
            </Typography>
            <Typography variant="h4" color="textSecondary" align="center">
              {claimableShare}
            </Typography>
          </BorderedInfoCard>
        )}
      </Box>
      <Box marginTop={6} display="flex" alignItems="center" flexDirection="column">
        <Box width="300px">
          {nftBalance >= 1 && (
            <MergeTokens nftBalance={nftBalance} claimableShare={claimableShare} voucherAddress={voucherAddress} />
          )}
        </Box>
      </Box>
      <Box>
        {nftBalance >= 1 && (
          <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              Claim requires two actions. The first transaction batches the non-fungible purchases together and the
              second transaction sends this to your wallet.
            </Typography>
          </BorderedInfoCard>
        )}
      </Box>
    </Box>
  )
}

export default Claim
