import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

const SaleSlider = withStyles({
  root: {
    color: '#D0D5D6',
    height: 14,
  },
  thumb: {
    height: '35px !important',
    width: '35px !important',
    background: 'url("/images/idos/Thunder.svg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    marginLeft: '-16px !important',
    marginTop: '-10px !important',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 14,
    borderRadius: 9,
    background: 'linear-gradient(270deg, #8CA2F1 24.25%, #8DDBE9 85.96%)',
  },
  rail: {
    height: 14,
    borderRadius: 9,
  },
})(Slider)

export default SaleSlider
