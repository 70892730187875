/* eslint-disable import/no-cycle */
import gasTokens from 'config/constants/gasTokens'
import { Chain } from 'config/constants/types'
import { TokenClass } from 'swap-sdk/entities/token'
import { getWGasAddress } from './addressHelpers'

const getGasToken = (chainId: Chain): TokenClass => {
  const symbol = gasTokens[chainId] || 'BNB'
  const gasSymbol = `W${symbol}`
  const gasAddress = getWGasAddress(chainId)
  return new TokenClass(chainId, gasAddress, 18, gasSymbol, `Wrapped ${symbol}`)
}

export default getGasToken
