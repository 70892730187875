import { Chain } from './types'

const networkModalOptions = [
  {
    chainID: Chain.BSC_MAINNET,
    iconSrc: '/images/BSC.svg',
    name: 'Binance',
    fullName: 'Binance Smart Chain',
    backgroundColor: '#3B4346',
    alertBorder: '#F0B90B',
    alertBackground: '#F4E8C2',
  },
  // {
  //   chainID: Chain.POLYGON_MAINNET,
  //   iconSrc: '/images/Polygon.svg',
  //   name: 'Polygon',
  //   backgroundColor: '#8247E5',
  //   alertBorder: '#627EEA',
  //   alertBackground: '#E2E9FF',
  // },
  {
    chainID: Chain.MOONRIVER_MAINNET,
    iconSrc: '/images/Moonriver.svg',
    name: 'Moonriver',
    backgroundColor: '#301748',
    alertBorder: '#301748',
    alertBackground: '#F5F5F5',
  },
  // {
  //   chainID: Chain.AVAX_MAINNET,
  //   iconSrc: '/images/Avalanche.svg',
  //   name: 'Avalanche',
  //   backgroundColor: '#e84142',
  //   alertBorder: '#E84142',
  //   alertBackground: '#F8F1F1',
  // },
  // {
  //   chainID: Chain.MOONBEAM_MAINNET,
  //   iconSrc: '/images/Moonbeam.svg',
  //   name: 'Moonbeam',
  //   backgroundColor: '#53CBC8',
  //   alertBorder: '#53CBC8',
  //   alertBackground: '#53CBC84D',
  // },
  // {
  //   chainID: Chain.AURORA_MAINNET,
  //   iconSrc: '/images/Aurora.svg',
  //   name: 'Aurora',
  //   backgroundColor: '#53CBC8',
  //   alertBorder: '#53CBC8',
  //   alertBackground: '#53CBC84D',
  // },
  // {
  //   chainID: Chain.BOBA_MAINNET,
  //   iconSrc: '/images/Boba.svg',
  //   name: 'Boba',
  //   backgroundColor: '#53CBC8',
  //   alertBorder: '#53CBC8',
  //   alertBackground: '#53CBC84D',
  // },
  // {
  //   chainID: Chain.ETH_MAINNET,
  //   iconSrc: '/images/Ethereum.svg',
  //   name: 'Ethereum',
  //   backgroundColor: '#627EEA',
  //   alertBorder: '#627EEA',
  //   alertBackground: '#E2E9FF',
  // },
]

export default networkModalOptions
