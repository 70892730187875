import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal } from 'uikit'
import { useStake, useStakeAmount } from 'hooks/useIFAMaster'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { getBalanceInWei, convertFromWei } from 'utils/formatBalance'
import { useToast } from 'state/hooks'
import { Chain, Token } from 'config/constants/types'
import { Box, Typography } from '@material-ui/core'
import GetToken from 'components/GetToken'
import { getAddress } from 'utils/addressHelpers'
import styled from 'styled-components'

const StakeMoreTokenTypography = styled.span`
  display: inline-block;
  text-align: center;
  background-color: #f2f4f5;
  padding: 5px 10px;
  margin-top: 8px;
  font-size: 14px;
  color: #616568;
  border-radius: 4px;
`
const StakeMoreTokenNumber = styled.span`
  font-size: 16px;
  font-weight: 700;
`

interface StakeModalProps {
  chainId?: Chain
  max: BigNumber
  onDismiss?: () => void
  tokenName?: string
  masterAddress: string
  trackId?: number
  token?: Token
  maxTotalStake: BigNumber | null
}

const StakeModal: React.FC<StakeModalProps> = ({
  chainId,
  max,
  masterAddress,
  tokenName = '',
  trackId,
  token,
  maxTotalStake,
  onDismiss,
}) => {
  const [val, setVal] = useState('')
  const [error, setError] = useState(true)
  const { onStake, isLoading } = useStake(masterAddress, trackId)
  const { toastSuccess } = useToast()

  const stakedAmountInWei = useStakeAmount(masterAddress, trackId)
  const stakedAmount = convertFromWei(stakedAmountInWei, token?.decimals)

  const fullBalance = useMemo(() => {
    return max.toFixed()
  }, [max])

  const onStakeSuccess = useCallback(() => {
    toastSuccess(`You've staked ${val} ${tokenName}`)
    onDismiss()
  }, [val, tokenName, toastSuccess, onDismiss])

  const handleStake = useCallback(() => {
    const amountInWei = getBalanceInWei(new BigNumber(val))
    onStake(amountInWei, onStakeSuccess)
  }, [val, onStake, onStakeSuccess])

  const checkError = useCallback(
    (amount: string) => {
      if (amount === '' || parseFloat(amount) <= 0) setError(true)
      else if (parseFloat(amount) > parseFloat(fullBalance)) setError(true)
      else setError(false)
    },
    [fullBalance],
  )

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
      checkError(e.currentTarget.value)
    },
    [setVal, checkError],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
    checkError(fullBalance)
  }, [fullBalance, setVal, checkError])

  const insufficientBalanceModalAction = useMemo(() => {
    return (
      <ModalActions>
        <Button disabled width="100%">
          Insufficient IDIA Balance
        </Button>
      </ModalActions>
    )
  }, [])
  const sufficientBalanceModalAction = useMemo(() => {
    return (
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%">
          Cancel
        </Button>
        <Button disabled={isLoading || error} onClick={handleStake} width="100%">
          {isLoading ? 'Pending Confirmation' : 'Confirm'}
        </Button>
      </ModalActions>
    )
  }, [onDismiss, handleStake, isLoading, error])
  return (
    <Modal title="Stake tokens" onDismiss={onDismiss}>
      <ModalInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
        inputTitle="Amount"
        balanceText="Balance"
        showErrorMessage={false}
        marginBottom={8}
      />
      {maxTotalStake && maxTotalStake.minus(stakedAmount).isGreaterThan(0) && (
        <Box display="flex" justifyContent="center">
          <Typography variant="body2" color="textSecondary">
            {stakedAmount.isGreaterThan(0)
              ? `You have staked ${stakedAmount.toNumber().toLocaleString()} ${
                  token?.symbol
                } out of maximum of ${maxTotalStake.toNumber().toLocaleString()} ${token?.symbol}. `
              : `You can stake up to ${maxTotalStake.toNumber().toLocaleString()} ${token?.symbol}.`}
          </Typography>
        </Box>
      )}
      {stakedAmount.isGreaterThan(0) && (
        <Box textAlign="center">
          <StakeMoreTokenTypography>
            You can stake
            <StakeMoreTokenNumber>{` ${max.toNumber().toLocaleString()} `}</StakeMoreTokenNumber>
            more IDIA.
          </StakeMoreTokenTypography>
        </Box>
      )}
      {max.isEqualTo(0) ? insufficientBalanceModalAction : sufficientBalanceModalAction}
      {chainId && token && <GetToken symbol={token.symbol} address={getAddress(token.address, chainId)} />}
    </Modal>
  )
}

export default StakeModal
