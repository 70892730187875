import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Flex, Text, LinkExternal } from 'uikit'
import { IDOConfig } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { BorderedInfoCard } from '../StyledComponent'

type MainPanelProps = {
  projectDetail: IDOConfig
}
const ExternalLinkWrap = styled(LinkExternal)`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: left;
  text-decoration: none;
  width: 100%;
  color: #0ac6e5;
  & > svg {
    fill: #0ac6e5;
  }
`

const SaleDetails: React.FC<MainPanelProps> = ({ projectDetail }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12}>
          <BorderedInfoCard>
            <Flex mb="32px">
              <Text fontWeight={500} fontSize="22px">
                {t('Project Overview')}
              </Text>
            </Flex>
            <Flex my="16px">
              <Typography
                variant="body2"
                component="div"
                color="textSecondary"
                dangerouslySetInnerHTML={{ __html: projectDetail.projectInformation.projectOverview }}
              />
            </Flex>
            {projectDetail.projectInformation.featureList && (
              <Box>
                <Flex my="32px">
                  <Text fontWeight={500} fontSize="16px">
                    {t('Feature List')}
                  </Text>
                </Flex>
                <Flex my="16px">
                  <Text>{projectDetail.projectInformation.featureList}</Text>
                </Flex>
              </Box>
            )}
            {projectDetail.projectInformation.whitepaperLink && (
              <ExternalLinkWrap href={projectDetail.projectInformation.whitepaperLink}>
                {t('View Whitepaper')}
              </ExternalLinkWrap>
            )}
          </BorderedInfoCard>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SaleDetails
