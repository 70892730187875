import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import format from 'date-fns/format'

import differenceInMonths from 'date-fns/differenceInMonths'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Text } from 'uikit'

import { BorderedInfoCard } from '../../views/IDO/Detail/Components/StyledComponent'

interface AirDropWarningProps {
  firstDayRelease?: string
  firstDate?: string
  finalDate?: string
  tokenName?: string
}

const AirDropWarning: React.FC<AirDropWarningProps> = ({ tokenName, firstDayRelease, firstDate, finalDate }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const diff = differenceInMonths(new Date(finalDate), new Date(firstDate))

  return (
    <BorderedInfoCard padding={5.5} marginTop={2} marginX={matches ? 10 : 0}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box marginTop={2} display="flex" flexDirection="row">
            <img src="/images/alert.png" alt="token" width="26px" style={{ height: '26px' }} />
            <Box marginLeft={2} />
            <Typography variant="h4" color="textPrimary">
              IMPORTANT
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="caption" color="textSecondary" component="span">
            <Text fontSize="13px">
              {finalDate
                ? `Please note that ${firstDayRelease} of purchased ${tokenName} will be airdropped to you on ${format(
                    new Date(firstDate),
                    'LLL do yyyy KK:mm aa OOO',
                  )}, with the remaining airdropped on ${format(
                    new Date(finalDate),
                    'LLL do yyyy KK:mm aa OOO',
                  )}}, ${diff} months after the first drop.`
                : `Please note that purchased ${tokenName} will be airdropped to you on ${format(
                    new Date(firstDate),
                    'LLL do yyyy KK:mm aa OOO',
                  )}`}
            </Text>
            {/* <Link
              href="https://impossible.freshdesk.com/en/support/solutions/articles/80000801997-if-launchpad-format-faq#2.-How-is-Allocation-calculated?"
              target="_blank"
              style={{ color: '#0AC6E5' }}
            >
              FAQ
            </Link> */}
          </Typography>
        </Grid>
      </Grid>
    </BorderedInfoCard>
  )
}

export default AirDropWarning
