import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import Clipboard from 'react-clipboard.js'

const WalletCopy = ({ walletAddress }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const clickIcon = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Clipboard data-clipboard-text={walletAddress} className={classes.clipboard} onClick={clickIcon}>
        <Box className={classes.btnBox}>
          <p className={classes.addr}>{`${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`}</p>
          <img className={classes.mr} src="/images/idos/copy.svg" alt="copy" />
        </Box>
      </Clipboard>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={open}
        autoHideDuration={3000}
        message="Copied"
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  clipboard: {
    margin: 0,
    marginRight: 5,
    border: 'none',
    borderRadius: 5,
    background: '#F2F4F5',
    padding: '0 5px',
    cursor: 'pointer',
  },
  btnBox: {
    display: 'flex',
  },
  addr: {
    margin: 'auto',
  },
  mr: {
    marginLeft: 8,
  },
}))

export default WalletCopy
