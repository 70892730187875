import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import { AirdropInfo } from 'state/types'
import AirDropWarning from 'components/AirDropWarning'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

type PreAirdropProps = {
  token: string
  shares: string
  endTime: Date
  airdropInfo?: AirdropInfo
}

const PreAirdrop: React.FC<PreAirdropProps> = ({ token, shares, endTime, airdropInfo }) => {
  const { t } = useTranslation()
  return (
    <Box marginTop={5}>
      {airdropInfo?.isAirdropped && (
        <AirDropWarning
          tokenName={token}
          firstDayRelease={airdropInfo.firstDayRelease}
          firstDate={airdropInfo.initialAirdrop}
          finalDate={airdropInfo.finalAirdrop}
        />
      )}
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={10}>
        <img src="/images/idos/Moon.svg" width="100px" alt="time sand" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {airdropInfo?.finalAirdrop && 'Initial'} {token} airdrop in
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={endTime} />
      </Box>

      <Box display="flex" justifyContent="center">
        <BorderedInfoCard style={{ padding: '24px 33px 20px 33px' }} marginTop={6}>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('Total Share')}
          </Typography>
          <Typography variant="h3" color="textPrimary" align="center">
            {parseFloat(shares).toLocaleString()} {token}
          </Typography>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default PreAirdrop
