import { Chain } from './types'

export default {
  syrup: {
    [Chain.BSC_TESTNET]: '0xEc4070F7Cb3Cb944Af729D8c23F3350dF24f1003',
    [Chain.BSC_MAINNET]: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    [Chain.POLYGON_MAINNET]: '',
    [Chain.MOONRIVER_MAINNET]: '',
    [Chain.AVAX_MAINNET]: '',
  },
  masterChef: {
    [Chain.BSC_TESTNET]: '0x683B1d0CC12c8862Ce102773B4E8fFd6370d3eDA',
    [Chain.BSC_MAINNET]: '0x3cafa8d80566a0dd500311ba7758772cec538e50',
    [Chain.POLYGON_MAINNET]: '',
    [Chain.MOONRIVER_MAINNET]: '',
    [Chain.AVAX_MAINNET]: '',
  },
  wgas: {
    [Chain.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [Chain.BSC_TESTNET]: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f',
    [Chain.POLYGON_MAINNET]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    [Chain.MOONRIVER_MAINNET]: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    [Chain.AVAX_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [Chain.ETH_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7', // Dummy
    [Chain.MOONBEAM_MAINNET]: '0xAcc15dC74880C9944775448304B263D191c6077F',
    [Chain.AURORA_MAINNET]: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    [Chain.BOBA_MAINNET]: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  },
  mulltiCall: {
    [Chain.BSC_MAINNET]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    [Chain.BSC_TESTNET]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    [Chain.POLYGON_MAINNET]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d',
    [Chain.AVAX_MAINNET]: '0x03debf8E506da9BaA859a9A349003C74F79A437C',
    [Chain.ETH_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [Chain.MOONRIVER_MAINNET]: '0x7a8eaD64B79C466d8A9Bcfd2a7B7BF938F9Cb542',
    [Chain.MOONBEAM_MAINNET]: '0x6477204E12A7236b9619385ea453F370aD897bb2',
    [Chain.AURORA_MAINNET]: '0x56E59989BCaf633ee60aBc4437F82A91A765f4f5',
    [Chain.BOBA_MAINNET]: '0xaeD5b25BE1c3163c907a471082640450F928DDFE',
  },
  eth: {
    [Chain.BSC_MAINNET]: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    [Chain.BSC_TESTNET]: '0xebb6891b622c3938ebf5fdec3fcf0c697f8ae4e5',
    [Chain.POLYGON_MAINNET]: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    [Chain.MOONRIVER_MAINNET]: '0x98878b06940ae243284ca214f92bb71a2b032b8a', // Dummy Address
    [Chain.AVAX_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [Chain.ETH_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  },
  router: {
    [Chain.BSC_MAINNET]: '0x8f2A0d8865D995364DC6843D51Cf6989001f989e',
  },
  ensRegistrar: {
    [Chain.BSC_MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  },
}
