import { Chain } from 'config/constants/types'
import { BASE_BSC_SCAN_URLS } from 'config'
import { nodes } from 'utils/getRpcUrl'

const networkParams = {
  [Chain.BSC_MAINNET]: [
    {
      chainId: `0x${Chain.BSC_MAINNET.toString(16)}`,
      chainName: 'BSC Mainnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BSC_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BSC_MAINNET]],
    },
  ],
  [Chain.BSC_TESTNET]: [
    {
      chainId: `0x${Chain.BSC_TESTNET.toString(16)}`,
      chainName: 'BSC Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BSC_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BSC_TESTNET]],
    },
  ],
  [Chain.POLYGON_MAINNET]: [
    {
      chainId: `0x${Chain.POLYGON_MAINNET.toString(16)}`,
      chainName: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.POLYGON_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.POLYGON_MAINNET]],
    },
  ],
  [Chain.MOONRIVER_MAINNET]: [
    {
      chainId: `0x${Chain.MOONRIVER_MAINNET.toString(16)}`,
      chainName: 'Moonriver',
      nativeCurrency: {
        name: 'MOVR',
        symbol: 'MOVR',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MOONRIVER_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MOONRIVER_MAINNET]],
    },
  ],
  [Chain.AVAX_MAINNET]: [
    {
      chainId: `0x${Chain.AVAX_MAINNET.toString(16)}`,
      chainName: 'Avalanche',
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.AVAX_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.AVAX_MAINNET]],
    },
  ],
  [Chain.ETH_MAINNET]: [
    {
      chainId: `0x${Chain.ETH_MAINNET.toString(16)}`,
      chainName: 'Ethereum',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ETH_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ETH_MAINNET]],
    },
  ],
  [Chain.MOONBEAM_MAINNET]: [
    {
      chainId: `0x${Chain.MOONBEAM_MAINNET.toString(16)}`,
      chainName: 'Moonbeam',
      nativeCurrency: {
        name: 'GLMR',
        symbol: 'GLMR',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MOONBEAM_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MOONBEAM_MAINNET]],
    },
  ],
  [Chain.AURORA_MAINNET]: [
    {
      chainId: `0x${Chain.AURORA_MAINNET.toString(16)}`,
      chainName: 'Aurora',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.AURORA_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.AURORA_MAINNET]],
    },
  ],
  [Chain.BOBA_MAINNET]: [
    {
      chainId: `0x${Chain.BOBA_MAINNET.toString(16)}`,
      chainName: 'Boba',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BOBA_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BOBA_MAINNET]],
    },
  ],
}

export default networkParams
