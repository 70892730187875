import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import TradeBox from 'components/TradeBox'
import { Token } from 'config/constants/types'
import { useToast } from 'state/hooks'
import { convertFromWei, getBalanceInWei, getBalanceNumber, roundDecimals } from 'utils/formatBalance'
import { IDOSaleToken, SolvMarketSale, BonusSaleDetail } from 'state/types'
import { useSolvSaleApprove } from 'hooks/useApprove'
import BigNumber from 'bignumber.js'
import GetToken from 'components/GetToken'
import SaleSlider from 'components/SaleSlider'
import { useBonusIdoUser } from 'state/idos/hooks'
import { useCurrentPrice, usePurchaseByInput } from 'hooks/useSolv'

type BonusPeriodProps = {
  address: string
  marketAddress: string
  saleId: number
  id: string
  endTime: Date
  paymentToken: Token
  saleToken: IDOSaleToken
  solvSaleInfo: SolvMarketSale
  passedKYC: boolean
  purchasedAmount: BigNumber
  saleDetails: BonusSaleDetail
}

const BonusPeriod: React.FC<BonusPeriodProps> = ({
  marketAddress,
  saleId,
  endTime,
  paymentToken,
  saleToken,
  solvSaleInfo,
  passedKYC,
  id,
  purchasedAmount,
  saleDetails,
}) => {
  const userData = useBonusIdoUser(id)
  const [existingError, setError] = useState(undefined)
  const { toastSuccess } = useToast()

  const priceInWei = useCurrentPrice(saleId, marketAddress)
  const price = getBalanceNumber(priceInWei)

  const { onApprove, isLoading: approveLoading } = useSolvSaleApprove(paymentToken.address, marketAddress)
  const handleApprove = useCallback(onApprove, [onApprove])
  const [orderAmount, setOrderAmount] = useState('')
  const { onPurchase, isLoading: purchaseLoading } = usePurchaseByInput(saleId, marketAddress)

  const onOrderSuccess = useCallback(() => {
    toastSuccess(
      `You've successfully purchased ${(parseFloat(orderAmount) / price).toLocaleString()} ${saleToken.symbol}`,
      `You can claim the ${saleToken.symbol} when the sale ends.`,
    )
  }, [toastSuccess, orderAmount, price, saleToken.symbol])

  const onOrder = useCallback(() => {
    onPurchase(getBalanceInWei(new BigNumber(orderAmount)), onOrderSuccess)
  }, [onPurchase, orderAmount, onOrderSuccess])

  const onChange = useCallback((value) => {
    setOrderAmount(value)
  }, [])

  const onError = useCallback(
    (error) => {
      setError(error)
    },
    [setError],
  )
  const { totalInWei, unitsInWei } = solvSaleInfo

  if (!userData) {
    return null
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { paymentTokenAllowanceInWei, paymentTokenBalanceInWei, purchasedUnits } = userData
  const paymentTokenAllowance = paymentTokenAllowanceInWei
  let paymentTokenBalance = new BigNumber(0)

  if (paymentTokenBalanceInWei) {
    paymentTokenBalance = convertFromWei(paymentTokenBalanceInWei)
  }

  const total = getBalanceNumber(totalInWei)
  const units = getBalanceNumber(unitsInWei)

  const d = Math.floor(saleDetails.intervalInSeconds / 86400)
  const h = Math.floor((saleDetails.intervalInSeconds % 86400) / 3600)
  const m = Math.floor((saleDetails.intervalInSeconds % 3600) / 60)
  const s = Math.floor(saleDetails.intervalInSeconds % 60)

  const decreasingInterval = [
    d > 0 ? `${d} d` : undefined,
    h > 0 ? `${h} h` : undefined,
    m > 0 ? `${m} m` : undefined,
    s > 0 ? `${s} s` : undefined,
  ].filter((val) => val)

  const decreasingValue =
    (saleDetails.highest - saleDetails.lowest) / (saleDetails.durationInSeconds / saleDetails.intervalInSeconds)

  return (
    <Box marginTop={4}>
      <Box margin="auto" maxWidth="600px">
        <Typography variant="body2" color="textPrimary" align="left">
          Amount sold: {roundDecimals(total - units)}/{total}
        </Typography>
        <SaleSlider
          disabled
          defaultValue={totalInWei.minus(unitsInWei).dividedBy(totalInWei).times(100).decimalPlaces(2).toNumber()}
          value={totalInWei.minus(unitsInWei).dividedBy(totalInWei).times(100).decimalPlaces(2).toNumber()}
        />
      </Box>
      <Box display="flex" justifyContent="center" marginTop={10}>
        <TradeBox
          token0Balances={paymentTokenBalance}
          token0={{ name: paymentToken.symbol, image: `/images/tokens/${paymentToken.symbol}.png` }}
          token1={{ name: saleToken.symbol, image: saleToken.image }}
          endTime={endTime}
          pricePerToken={price}
          minimumPurchase={getBalanceNumber(solvSaleInfo.minInWei) * price}
          maximumPurchase={
            solvSaleInfo.maxInWei.isEqualTo(0)
              ? new BigNumber(units).times(new BigNumber(price))
              : convertFromWei(solvSaleInfo.maxInWei.minus(purchasedAmount)).times(new BigNumber(price))
          }
          tooltip={
            saleDetails && saleDetails.isDeclining
              ? `${saleToken.symbol} price decreasing by ${decreasingValue} ${paymentToken.symbol} every ${decreasingInterval}`
              : ''
          }
          onChange={onChange}
          onError={onError}
        />
      </Box>

      {existingError && (
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="error">
            Error: {existingError}
          </Typography>
        </Box>
      )}
      <Box>
        <Box display="flex" justifyContent="center" marginTop={10}>
          <Box width="300px">
            {paymentTokenAllowance && paymentTokenAllowance.isGreaterThan(0) ? (
              <Button
                onClick={onOrder}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
                disabled={!passedKYC || existingError || purchaseLoading}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!passedKYC ? 'Require KYC' : purchaseLoading ? 'Confirming...' : 'Purchase'}
              </Button>
            ) : (
              <Button
                onClick={handleApprove}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
                disabled={!passedKYC || approveLoading}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!passedKYC ? 'Require KYC' : !approveLoading ? `Approve ${paymentToken.symbol}` : 'Approving'}
              </Button>
            )}
          </Box>
        </Box>
        <GetToken symbol={paymentToken.symbol} address={paymentToken.address[process.env.REACT_APP_CHAIN_ID]} />
      </Box>
    </Box>
  )
}

export default BonusPeriod
