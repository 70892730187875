import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66743 6.59957C6.78126 6.45639 6.95419 6.37295 7.1371 6.37295H12.6284C12.8113 6.37295 12.9842 6.45639 13.0981 6.59957C13.2119 6.74275 13.2542 6.93004 13.2129 7.10824L12.3743 10.7317L18.3066 18.043C18.4523 18.2227 18.4818 18.4702 18.3823 18.6791C18.2828 18.888 18.072 19.0211 17.8406 19.0211H2.29792C2.06983 19.0211 1.86082 18.8917 1.75959 18.6873C1.65837 18.4829 1.68179 18.2388 1.82003 18.0574L7.39374 10.7427L6.55255 7.10824C6.51131 6.93004 6.55361 6.74275 6.66743 6.59957ZM3.50943 17.8211H16.5807L11.2577 11.2601C11.1411 11.1164 11.0974 10.927 11.1391 10.7468L11.8737 7.57295H7.89183L8.62641 10.7468C8.66668 10.9208 8.62735 11.1037 8.5191 11.2457L3.50943 17.8211Z"
        fill="#00FFB9"
      />
      <path
        d="M13.4784 3.49745C13.4784 4.34916 12.7879 5.03962 11.9362 5.03962C11.0845 5.03962 10.394 4.34916 10.394 3.49745C10.394 2.64574 11.0845 1.95529 11.9362 1.95529C12.7879 1.95529 13.4784 2.64574 13.4784 3.49745V3.49745Z"
        fill="#00FFB9"
      />
      <path
        d="M8.94609 1.95529C8.94609 2.56233 8.45399 3.05443 7.84695 3.05443C7.23991 3.05443 6.7478 2.56233 6.7478 1.95529C6.7478 1.34825 7.23991 0.856144 7.84695 0.856144C8.45399 0.856144 8.94609 1.34825 8.94609 1.95529V1.95529Z"
        fill="#00FFB9"
      />
    </Svg>
  )
}

export default Icon
