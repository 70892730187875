import React, { useEffect } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import { Spinner, Text } from 'uikit'
import useRefresh from 'hooks/useRefresh'
import LegalLink from 'components/LegalLink'
import { BonusSaleConfig } from 'state/types'
import { useBonusSaleFromId, useIdosInitiated } from 'state/idos/hooks'
import { fetchIdoUserDataAsync } from 'state/idos'
import { useBlockInitialised } from 'state/block/hooks'
import { useTranslation } from 'contexts/Localization'
import MainPanel from './Components/MainPanel'
import ProjectCard from '../../../components/ProjectCard/ProjectCard'
import BottomPanel from './Components/BottomPanel/index'

const Card = styled.div<{ height?: number | string }>`
  background: ${({ theme }) => theme.colors.card};
  border-radius: 0;
  ${({ theme }) => theme.mediaQueries.md} {
    border-radius: 22px;
  }
  height: ${({ height }) => height};
`

const DetailContainer = styled.div`
  ${({ theme }) => theme.mediaQueries.xs} {
    margin: 12px 0;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 120px 32px 64px;
  }
`

const BackBox = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0;
  }
`

interface BonusSaleProps {
  id: string
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const BonusSale: React.FC<BonusSaleProps> = ({ id }) => {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch()
  const query = useQuery()
  const { slowRefresh } = useRefresh()
  const project = useBonusSaleFromId(id)
  const isCorrectNetwork = project?.chainId === chainId
  const dataInitialised = useIdosInitiated()
  const blockInitialised = useBlockInitialised()
  const { t } = useTranslation()

  useEffect(() => {
    if (account && isCorrectNetwork && dataInitialised && blockInitialised) {
      dispatch(fetchIdoUserDataAsync(account, chainId))
    }
  }, [account, dispatch, slowRefresh, chainId, isCorrectNetwork, blockInitialised, dataInitialised])

  if (!dataInitialised) {
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  if (!project) {
    return (
      <Box marginTop={30} marginX={8} marginBottom={16}>
        <Typography variant="h4">{t('Project Not Found')}</Typography>
      </Box>
    )
  }

  const tabText = query.get('tab') || 'live'
  return (
    <DetailContainer>
      {!project.isPrivate && (
        <Box>
          <ReactRouterLink to={`/launchpad?tab=${tabText}`}>
            <BackBox>
              <ArrowBackwardIcon />{' '}
              <Text marginLeft={3} fontSize="20px">
                {t(`${tabText.charAt(0).toUpperCase()}${tabText.slice(1)} Projects`)}
              </Text>
            </BackBox>
          </ReactRouterLink>
        </Box>
      )}
      <Box marginTop={6}>
        <Grid container direction="row" spacing={4}>
          <Grid container item lg={4} direction="row">
            <Grid item xs={12}>
              <Box marginBottom={4}>
                <Card>
                  <ProjectCard projectDetail={project as BonusSaleConfig} showStatus={false} />
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={8}>
            <Grid item xs={12}>
              <Card height="100%">
                <MainPanel projectDetail={project} />
              </Card>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Card>
                <BottomPanel projectDetail={project} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <LegalLink />
    </DetailContainer>
  )
}

export default BonusSale
