import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import AddToCalendar from 'components/AddToCalendar'
import format from 'date-fns/format'
import { differenceInHours } from 'date-fns'

type PreSaleProps = {
  hasCompletedKYC: boolean
  startTime: Date
  endTime: Date
  projectName: string
}

const PreSale: React.FC<PreSaleProps> = ({ startTime, projectName, endTime }) => {
  const title = `${projectName} Bonus sale begins!`
  const hourDiff = differenceInHours(startTime, endTime)
  const description = `You have ${hourDiff} hours to join ${projectName} Bonus Sale! Sale starts from ${format(
    startTime,
    'LLL do yyyy KK:mm aa OOOO',
  )} to ${format(endTime, 'LLL do yyyy KK:mm aa OOOO')} ${window.location.href.split('?')[0]}`
  return (
    <Box marginTop={13}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {/* Used to center the image */}
        <AddToCalendar title={title} description={description} startTime={startTime} hide />
        <img src="/images/idos/HandSale.svg" width="100px" alt="waiting chair" />
        <AddToCalendar title={title} description={description} startTime={startTime} />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          Bonus Sale starts in
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={startTime} />
      </Box>

      <Box marginTop={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          For The Bonus Sale, the price of a given token starts at an initial price and drops by a fixed amount
          periodically.
        </Typography>
      </Box>
    </Box>
  )
}

export default PreSale
