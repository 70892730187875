// Set of helper functions to facilitate wallet setup

import networkParams from 'config/constants/networkParams'
import { Chain } from 'config/constants/types'

import getProvider from './getProvider'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId: Chain) => {
  const provider = getProvider()

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: networkParams[chainId],
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error('Please make sure you are using supported wallet')
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await (window as WindowChain).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
      },
    },
  })

  return tokenAdded
}
