import React, { useEffect, lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ResetCSS } from 'uikit'
import { ThemeProvider } from '@material-ui/core/styles'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import useGetDocumentTitlePrice from 'hooks/useGetDocumentTitlePrice'
import { useFetchPublicData } from 'state/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { useFetchInitIdosPublicData } from 'state/idos/hooks'
import Swap from 'views/Swap'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import IDODetail from './views/IDO/Detail'
import history from './routerHistory'
import ScrollToTop from './ScrollToTop'
import theme from './theme'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const Farms = lazy(() => import('./views/Farms'))
const Invest = lazy(() => import('./views/Invest'))
const NotFound = lazy(() => import('./views/NotFound'))
// const WillBeBack = lazy(() => import('./views/WillBeBack'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useFetchInitIdosPublicData()
  usePollBlockNumber()
  useEagerConnect()
  useFetchPublicData()
  useGetDocumentTitlePrice()

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ResetCSS />
        <GlobalStyle />
        <Menu>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact>
                <Redirect to="/launchpad" />
              </Route>
              <Route path="/project/:type/:id" component={IDODetail} />
              <Route path="/ido/:address" component={IDODetail} />
              <Route exact strict path="/swap" component={Swap} />
              <Route path="/launchpad">
                <Invest />
              </Route>
              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </SuspenseWithChunkError>
        </Menu>
        <EasterEgg iterations={2} />
        <ToastListener />
      </ThemeProvider>
    </Router>
  )
}

export default React.memo(App)
