import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { getBep20Contract } from 'utils/contractHelpers'
import { Address } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'

const useTokenBalance = (address: Address) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { chainId, account } = useWeb3React()
  const web3 = useWeb3()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      if (!getAddress(address, chainId)) {
        console.error(`Fetching an undefined address on chain ${chainId}`)
        return
      }
      const contract = getBep20Contract(getAddress(address, chainId), web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(new BigNumber(res))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, address, web3, fastRefresh, chainId])

  return balance
}

export const useBurnedBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { slowRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
      setBalance(new BigNumber(res))
    }

    fetchBalance()
  }, [web3, tokenAddress, slowRefresh])

  return balance
}

export default useTokenBalance
