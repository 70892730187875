import React, { useState, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { Tabs, Tab, TabPanel } from 'components/Tabs/Tabs'
import { IDOConfig } from 'state/types'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'contexts/Localization'
import SaleDetails from './SaleDetails'
import ProjectInfo from './ProjectInfo'

type MainPanelProps = {
  projectDetail: IDOConfig
}

const MainPanel: React.FC<MainPanelProps> = ({ projectDetail }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [activeTab, setActiveTab] = useState(0)
  const handleChange = useCallback((event, newTabValue) => setActiveTab(newTabValue), [setActiveTab])
  const TABS = [
    {
      key: 'sale',
      title: 'Sale Details',
      detail: <SaleDetails projectDetail={projectDetail} />,
    },
    {
      key: 'info',
      title: 'Project Info',
      detail: <ProjectInfo projectDetail={projectDetail} />,
    },
  ]

  return (
    <Box padding={isSmall ? 4 : 8}>
      <Tabs value={activeTab} onChange={handleChange} aria-label="tabs">
        {TABS.map((tab) => {
          return <Tab label={t(tab.title)} key={tab.title} />
        })}
      </Tabs>
      {TABS.map((tab, index) => {
        return (
          <TabPanel value={activeTab} index={index} key={tab.title}>
            {tab.detail}
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default MainPanel
