import { Toast } from 'uikit'
import BigNumber from 'bignumber.js'
// eslint-disable-next-line import/no-cycle
import { Chain, FarmConfig, Nft, Team, Token } from 'config/constants/types'

export type TranslatableText =
  | string
  | {
      id: number
      fallback: string
      data?: {
        [key: string]: string | number
      }
    }

// Block
export interface BlockStateMap {
  [key: number]: BlockState
  initialised: boolean
}
export interface BlockState {
  currentBlock: number
  initialBlock: number
}

export type Language = {
  code: string
  language: string
  locale: string
}

export interface Farm extends FarmConfig {
  tokenAmount?: BigNumber
  quoteTokenAmount?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  userData?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}

export interface Profile {
  userId: number
  points: number
  teamId: number
  nftAddress: string
  tokenId: number
  isActive: boolean
  username: string
  nft?: Nft
  team: Team
  hasRegistered: boolean
}

// Slices states

export interface ToastsState {
  data: Toast[]
}

export interface AppState {
  isNetworkUnavailable: boolean
}

export interface FarmsState {
  data: Farm[]
}

export type MultipleIdosState = {
  [IDOType.BONUS_SALE]: BonusSaleConfig[]
  [IDOType.PUBLIC_SALE]: PublicSaleConfig[]
}

export interface IdosState {
  data: MultipleIdosState
  initialised: boolean
  syncedOnchain: boolean
}

// API Price State
export interface PriceList {
  [key: string]: number
}

export interface PriceApiResponse {
  /* eslint-disable camelcase */
  update_at: string
  prices: PriceList
}

export interface PriceState {
  isLoading: boolean
  lastUpdated: string
  data: PriceList
}

export interface ChainIdState {
  chainId: Chain
}

// Global state

export interface State {
  chainId: ChainIdState
  farms: FarmsState
  idos: IdosState
  toasts: ToastsState
  prices: PriceState
  blocks: BlockStateMap
  app: AppState
}

// IDOs

export enum KYCStatus {
  'NOT_STARTED' = 'NOT_STARTED',
  'VERIFIED' = 'VERIFIED',
  'PENDING' = 'PENDING',
  'REJECTED' = 'REJECTED',
  'FINAL_REJECTED' = 'FINAL_REJECTED',
}

export type IDOList = IDOConfig[]

export enum StepRequirementEnum {
  'KYC' = 'KYC',
}

export enum IDOType {
  PUBLIC_SALE = 'IDO1',
  BONUS_SALE = 'IDO2',
}

export type SocialMediaType = {
  name?: string
  icon?: string
  url: string
}

export type PublicSalePurchaseType = {
  startTime: string
  startBlock?: string
  endTime: string
  endBlock?: string
  allocSnapshotBlock?: number
  minimumPurchase?: number
  maximumPurchase?: number
  hasWithdrawn?: boolean
  paymentReceived?: number
  salePrice?: number
  saleAmount?: number
  saleTokenAllocationOverride?: number
  totalPaymentReceived?: number
}

export type BonusSaleType = {
  saleId: number
  tokenId: number
  purchasePeriod: PublicSalePurchaseType
}

export type IDOSaleToken = {
  name: string
  symbol: string
  image: string
  address?: string
  totalSupply: string
  decimals: number
}

export type IDOUserDataType = {
  publicSale?: {
    stakingTokenAllowance: BigNumber
    paymentTokenAllowance: BigNumber
    hasWithdrawn: boolean
    paymentReceived: BigNumber
    paymentTokenBalance: BigNumber
    stakingTokenBalance: BigNumber
    solvPaymentTokenAllowance: BigNumber
    userStakeWeights: number
  }
  isWhitelisted?: boolean
}
export interface IDOConfig {
  id: string
  type: IDOType
  projectInformation: {
    title: string
    image: string
    shortDescription: string
    projectOverview: string
    socialMedia: SocialMediaType[]
    whitepaperLink?: string
    researchLink?: string
    saleDetailsLink?: string
    featureList: string
    restrictedLabel?: string
  }
  chainId: number
  startTime: string
  endTime: string
  stepRequirement: [StepRequirementEnum]
  paymentToken: Token
  token: IDOSaleToken
  isPrivate?: boolean
  isFreeAirdrop?: boolean
  isArchived?: boolean
  isDisabled?: boolean

  airdropInfo?: AirdropInfo

  // Remove this when refactoring bonus sale
  saleAddress?: string

  restrictedLabel?: string
  restrictedCountries?: string[]
}

export type AirdropInfo = {
  isAirdropped?: boolean
  initialAirdrop?: string
  finalAirdrop?: string
  firstDayRelease?: string
}

export type PublicSaleUserDataType = {
  stakingTokenAllowanceInWei: BigNumber
  paymentTokenAllowanceInWei: BigNumber
  hasWithdrawn: boolean
  paymentReceivedInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
  stakingTokenBalanceInWei: BigNumber
  userStakeWeight: number
  isWhitelisted?: boolean
  // TODO: Move this out when we have BonusSaleUserDataType
  solvPaymentTokenAllowance: BigNumber
}

export interface PublicSaleConfig extends IDOConfig {
  trackId: number
  saleAddress?: string
  masterAddress: string
  stakingToken: Token
  subscribePeriod: {
    startTime: string
    endTime: string
    isLimited?: boolean
    maxTotalStake?: BigNumber
    endBlock?: string
  }
  purchasePeriod: PublicSalePurchaseType
  claimPeriod: {
    startTimeTBD?: boolean
    startTime: string
    purchaserCount: number
    startBlock?: string
  }
  userData?: PublicSaleUserDataType
}

// Currently cannot got this data from SC
export type BonusSaleDetail = {
  highest?: number
  lowest?: number
  isDeclining?: boolean
  durationInSeconds?: number
  intervalInSeconds?: number

  intervalUnit?: INTERVAL_UNIT
  intervalValue?: number
}

export enum INTERVAL_UNIT {
  'SECOND' = 'second',
  'MINUTE' = 'minute',
  'HOUR' = 'hour',
  'DAY' = 'day',
}

export type BonusSaleUserDataType = {
  paymentTokenAllowanceInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
  isWhitelisted: boolean
  // nftBalance: number
  purchasedUnits: BigNumber
  // claimableAmount: BigNumber
  // totalUnclaimedAmount: BigNumber
}

export interface BonusSaleConfig extends IDOConfig {
  saleId: number
  marketAddress: string
  voucherAddress: string
  vestingPoolAddress: string
  saleInfo?: SolvMarketSale
  saleDetails?: BonusSaleDetail
  userData?: BonusSaleUserDataType
  postSaleStats?: PostSaleStats
}

export interface PostSaleStats {
  numSales: string
  numParticipants: string
}

export interface BonusSaleIDO extends IDOConfig {
  bonusSale: BonusSaleType
}

export type SolvMarketSale = {
  tokenId: number
  startTime: number
  seller: string
  totalInWei: BigNumber
  unitsInWei: BigNumber
  minInWei: BigNumber
  maxInWei: BigNumber
  icToken: string
  currency: string
  useAllowList: boolean
}
