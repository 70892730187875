import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import getRpcUrl from 'utils/getRpcUrl'
import { Chain } from 'config/constants/types'
import { chainIds } from './web3React'

const web3NoAccounts = chainIds.reduce((acc, val) => {
  acc[val] = new Web3(new Web3.providers.HttpProvider(getRpcUrl(val), { timeout: 10000 } as HttpProviderOptions))
  return acc
}, {})

const getWeb3NoAccount = (chainId: Chain) => {
  return web3NoAccounts[chainId]
}

export { getWeb3NoAccount }
export default web3NoAccounts
