import BigNumber from 'bignumber.js/bignumber'
import { AverageBlockTime, Chain } from './constants/types'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BASE_BSC_SCAN_URLS = {
  [Chain.BSC_MAINNET]: 'https://bscscan.com',
  [Chain.BSC_TESTNET]: 'https://testnet.bscscan.com',
  [Chain.POLYGON_MAINNET]: 'https://polygonscan.com',
  [Chain.MOONRIVER_MAINNET]: 'https://blockscout.moonriver.moonbeam.network/',
  [Chain.AVAX_MAINNET]: 'https://snowtrace.io/',
  [Chain.ETH_MAINNET]: 'https://etherscan.io/',
  [Chain.MOONBEAM_MAINNET]: 'https://blockscout.moonbeam.network/',
  [Chain.AURORA_MAINNET]: 'https://aurorascan.dev/',
  [Chain.BOBA_MAINNET]: 'https://blockexplorer.boba.network/',
}

export const AVG_BLOCK_TIME: AverageBlockTime = {
  [Chain.BSC_MAINNET]: 3.0596,
  [Chain.BSC_TESTNET]: 3.0596,
  [Chain.POLYGON_MAINNET]: 2,
  [Chain.ETH_MAINNET]: 13.29,
  [Chain.AVAX_MAINNET]: 1.978,
  [Chain.MOONRIVER_MAINNET]: 13.56,
  [Chain.MOONBEAM_MAINNET]: 12.45,
  [Chain.AURORA_MAINNET]: 1.2,
  [Chain.BOBA_MAINNET]: 23.8,
}

export const CAKE_PER_BLOCK = new BigNumber(0.014467)
export const BLOCKS_PER_YEAR = new BigNumber(10512000)
export const CAKE_POOL_PID = 0
export const BASE_EXCHANGE_URL = process.env.REACT_APP_SWAP_DOMAIN
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
