import React from 'react'
import { Box, Typography } from '@material-ui/core'
import convertToCurrentTZ from 'utils/formatDate'

type MissedPurchaseProps = {
  endTime: Date
}

const MissedPurchase: React.FC<MissedPurchaseProps> = ({ endTime }) => (
  <Box marginTop={26} paddingX={0}>
    <Box display="flex" justifyContent="center">
      <img src="/images/idos/SubscribeClosed.svg" width="100px" alt="time sand" />
    </Box>
    <Box marginTop={9}>
      <Typography color="textSecondary" variant="subtitle1" align="center">
        Oops! You just missed it.
      </Typography>
    </Box>
    <Box marginTop={9}>
      <Typography color="textSecondary" variant="body1" align="center">
        The sale ended on {convertToCurrentTZ(endTime.toString())}.
      </Typography>
    </Box>
  </Box>
)

export default MissedPurchase
