import React from 'react'
import { Box, Button, withStyles } from '@material-ui/core'
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined'
import addHours from 'date-fns/addHours'
import format from 'date-fns/format'
import styled from 'styled-components'

interface AddToCalendarProps {
  startTime?: Date
  title?: string
  description?: string
  hide?: boolean
}

const RemindMeButton = withStyles({
  root: {
    padding: '6px',
  },
})(Button)

const RemindMe = styled.div`
  font-size: 14px;
`

const AddToCalendar: React.FC<AddToCalendarProps> = ({ startTime, title, description, hide }) => {
  const start = new Date(startTime)
  const startStr = format(start, "yyyyMMdd'T'kkmmss")
  const end = addHours(start, 1)
  const endStr = format(end, "yyyyMMdd'T'kkmmss")
  const calendarLink = `https://www.google.com/calendar/event?action=TEMPLATE&dates=${startStr}%2F${endStr}&text=${title}&details=${description}`

  return (
    <Box display="flex" justifyContent="center" visibility={hide ? 'hidden' : 'visible'}>
      <a href={calendarLink} target="_blank" rel="noreferrer noopener">
        <RemindMeButton variant="contained" color="primary">
          <RemindMe>Remind Me</RemindMe>
          <AddAlertOutlinedIcon fontSize="small" htmlColor="white" />
        </RemindMeButton>
      </a>
    </Box>
  )
}

export default AddToCalendar
