import React from 'react'
import { Flex } from 'uikit'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'contexts/Localization'

interface StepsProps {
  currentStep?: number
  lastDoneStep?: number
  steps?: StepProps[]
}

interface StepProps {
  title?: string
}
const StepsBox = styled.div`
  background: #f2f4f5;
  border-radius: 22px;
  margin: 4px;
  padding: 8px 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 16px;
    padding: 8px 24px;
  }
`

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const IndexCircle = styled.div<{ status: string }>`
  border-radius: 50%;
  background: ${(props) => getColor(props.status)};
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  text-align: center;
`

const Checkmark = styled.div`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: white;
    content: '';
    transform: translateX(4px) rotate(-45deg);
    transform-origin: left bottom;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 2px;
    background-color: white;
    content: '';
    transform: translateX(4px) rotate(-45deg);
    transform-origin: left bottom;
  }
`
const StepsContainer = styled.div`
  display: block;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
  }
`

const StepsText = styled.div<{ status: string }>`
  margin-right: 8px;
  font-size: 14px;
  color: ${(props) => getColor(props.status)};
  ${({ theme }) => theme.mediaQueries.md} {
    margin-right: 24px;
  }
`

const NextBox = styled.div<{ status: string }>`
  margin-right: 8px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-right: 24px;
  }
  font-weight: 500;
  color: ${(props) => getColor(props.status)};
`

const getColor = (status) => {
  if (status === 'IN_PROGRESS') {
    return '#3B4346'
  }
  if (status === 'DONE') {
    return '#0AC6E5'
  }
  return '#D0D5D6'
}

const Steps: React.FC<StepsProps> = ({ steps, currentStep = 0, lastDoneStep = -1 }) => {
  const { t } = useTranslation()

  return (
    <StepsBox>
      <Flex>
        {steps.map((step, index) => {
          let status = 'NOT_DONE'
          if (currentStep === index) {
            status = 'IN_PROGRESS'
          }
          if (index <= lastDoneStep) {
            status = 'DONE'
          }
          return (
            <Box>
              <Flex alignItems="center">
                <StepsContainer>
                  <CircleContainer>
                    <IndexCircle status={status}>{lastDoneStep >= index ? <Checkmark /> : index + 1}</IndexCircle>
                  </CircleContainer>
                  <StepsText status={status}>{t(step.title)}</StepsText>
                </StepsContainer>
                {index < steps.length - 1 && <NextBox status={status}>{'>'}</NextBox>}
              </Flex>
            </Box>
          )
        })}
      </Flex>
    </StepsBox>
  )
}

export default Steps
