import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useWithdraw } from 'hooks/useIFASale'
import { useTranslation } from 'contexts/Localization'
import { BorderedInfoCard } from '../../StyledComponent'

type ClaimProps = {
  token: string
  shares: string
  saleAddress: string
}

const Claim: React.FC<ClaimProps> = ({ token, shares, saleAddress }) => {
  const { onWithdraw, isLoading } = useWithdraw(saleAddress)
  const { t } = useTranslation()

  return (
    <Box marginTop={10}>
      <Box display="flex" justifyContent="center">
        <img src="/images/idos/Moon.svg" width="100px" alt="time sand" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('You can claim your %token% now', { token })}
        </Typography>
      </Box>
      <Box marginTop={6} display="flex" justifyContent="center">
        <BorderedInfoCard style={{ padding: '24px 33px 20px 33px' }}>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('Claimable Share')}
          </Typography>
          <Typography variant="h3" color="textPrimary" align="center">
            {parseFloat(shares).toLocaleString()} {token}
          </Typography>
        </BorderedInfoCard>
      </Box>

      <Box marginTop={6} display="flex" alignItems="center" flexDirection="column">
        <Box width="300px">
          <Button
            onClick={onWithdraw}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            fullWidth
            disabled={isLoading}
          >
            {t('Claim')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Claim
