import React from 'react'
import { Button, Modal } from 'uikit'
import ModalActions from 'components/ModalActions'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'

interface DisclaimerModalProps {
  onDismiss?: () => void
  onOrder?: () => void
  saleTypeAddress?: string
}

export const DISCLAIMER_CACHE_KEY = 'DISCLAIMER_CACHE'

const ModalContent = styled.div`
  margin-bottom: 16px;
  max-width: 500px;
  max-height: 400px;
  overflow: scroll;
`

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ onDismiss, onOrder, saleTypeAddress }) => {
  return (
    <Modal title="Disclaimer" onDismiss={onDismiss}>
      <ModalContent>
        <Box paddingX={2}>
          <Typography variant="body1" color="textSecondary">
            Impossible Finance is a decentralized peer-to-peer protocol that people can use to create liquidity and
            trade tokens. There are multiple versions of the Impossible Finance protocol, each of which is made up of
            free, public, open-source or source-available software including a set of smart contracts that are deployed
            on Binance Smart Chain. Your use of the Impossible Finance protocol involves various risks, including, but
            not limited to, losses while digital assets are being supplied to the Impossible Finance protocol and losses
            due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Impossible
            Finance protocol, you should review the relevant documentation to make sure you understand how the
            Impossible Finance protocol works. Additionally, just as you can access email protocols, such as SMTP,
            through multiple email clients, you can access the Impossible Finance protocol through multiple web and
            mobile interfaces. You are responsible for conducting your own diligence on those interfaces to understand
            the fees and risks involved.
            <br />
            <br />
            THE IMPOSSIBLE FINANCE PROTOCOL IS PROVIDED ”AS IS” AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. he
            Impossible Finance protocol is run by smart contracts deployed on the Binance Smart Chain. Upgrades and
            modifications to the protocol are managed in a community-driven way by holders of the Impossible Finance
            governance token. No developer or entity involved in creating the Impossible Finance protocol will be liable
            for any claims or damages whatsoever associated with your use, inability to use, or your interaction with
            other users of the Impossible Finance protocol, including any direct, indirect, incidental, special,
            exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else
            of value.
          </Typography>
        </Box>
      </ModalContent>
      <ModalActions>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={async () => {
              onOrder()
              const disclaimerCache = JSON.parse(localStorage.getItem(DISCLAIMER_CACHE_KEY)) || {}
              disclaimerCache[saleTypeAddress] = 'true'
              localStorage.setItem(DISCLAIMER_CACHE_KEY, JSON.stringify(disclaimerCache))
              onDismiss()
            }}
            height="52px"
            width="300px"
          >
            Agree
          </Button>
        </Box>
      </ModalActions>
    </Modal>
  )
}

export const hasAgreed = (saleTypeAddress) => {
  const disclaimerCache = localStorage.getItem(DISCLAIMER_CACHE_KEY)
  return disclaimerCache ? JSON.parse(disclaimerCache)[saleTypeAddress] : false
}

export default DisclaimerModal
