import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Invest',
    href: `/launchpad`,
    icon: 'InvestIcon',
    items: null,
  },
  {
    label: 'Trade',
    href: `${process.env.REACT_APP_SWAP_DOMAIN}/#/swap`,
    icon: 'SwapIcon',
    items: null,
  },
  {
    label: 'Earn',
    icon: 'LiquidityIcon',
    items: [
      {
        label: 'Liquidity',
        href: `${process.env.REACT_APP_SWAP_DOMAIN}/#/pool`,
      },
      // {
      //   label: "Staking",
      //   href: `/farms`,
      // },
    ],
  },
  {
    label: 'Migration',
    href: `${process.env.REACT_APP_MIGRATE_DOMAIN}/#/migrate`,
    isSidebar: true,
    isNew: true,
    icon: 'MigrationIcon',
    items: null,
  },
]

export default config
