import React from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Text, Button, Input, InputProps, Flex, Link } from 'uikit'
import useI18n from '../../hooks/useI18n'

interface ModalInputProps {
  max: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  addLiquidityUrl?: string
  inputTitle?: string
  balanceText?: string
  showErrorMessage?: boolean
  marginBottom?: number
}

const getBoxShadow = ({ isWarning = false, theme }) => {
  if (isWarning) {
    return theme.shadows.warning
  }

  return theme.shadows.inset
}

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 0;
  width: 100%;
`

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 60px;
  margin: 0 8px;
  padding: 0 8px;
  font-size: 24px;
  font-weight: 500;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 80px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
  }
`

const StyledErrorMessage = styled(Text)`
  position: absolute;
  bottom: -22px;
  a {
    display: inline;
  }
`

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  balanceText = 'Balance',
  showErrorMessage = true,
  marginBottom = 20,
}) => {
  const TranslateString = useI18n()
  const isBalanceZero = max === '0' || !max
  const isMoreThanMax = parseFloat(value) > parseFloat(max)
  const displayBalance = isBalanceZero ? '0' : new BigNumber(max).decimalPlaces(2).toNumber()

  return (
    <div style={{ position: 'relative', marginBottom }}>
      <StyledTokenInput isWarning={isBalanceZero}>
        <Flex justifyContent="space-between" pl="16px">
          <Text fontSize="16px">{inputTitle}</Text>
          <Text fontSize="16px">
            {TranslateString(1120, balanceText)}: {displayBalance.toLocaleString()} {symbol}
          </Text>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-around" marginTop={20}>
          <StyledInput onChange={onChange} placeholder="0" value={value} type="number" />
          <Button
            scale="sm"
            onClick={onSelectMax}
            mr="8px"
            style={{ background: '#E9EBEC', color: '#0AC6E5', alignSelf: 'flex-start' }}
          >
            {TranslateString(452, 'Max')}
          </Button>
        </Flex>
      </StyledTokenInput>
      {showErrorMessage && isBalanceZero && (
        <StyledErrorMessage fontSize="14px" color="failure" style={{ position: 'relative' }}>
          No tokens to stake:{' '}
          <Link fontSize="14px" bold={false} href={addLiquidityUrl} external color="failure">
            {TranslateString(999, 'Get')} {symbol}
          </Link>
        </StyledErrorMessage>
      )}
      {showErrorMessage && isMoreThanMax && (
        <StyledErrorMessage fontSize="14px" color="failure" style={{ position: 'relative' }}>
          Input amount is more than max tokens.
        </StyledErrorMessage>
      )}
    </div>
  )
}

export default ModalInput
