import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { Chain } from 'config/constants/types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCurrentBlock } from 'state/block/hooks'
import { useToast } from 'state/hooks'
import { fetchPublicIdoUserDataAsync } from 'state/idos'
import { computeMerkleProof, getAddressIndex } from 'utils/merkleUtil'
import { useIFASaleContract } from './useContract'
import useRefresh from './useRefresh'
import { fetchWhitelistedList } from './useWhitelistedPrivateSale'

// // withdraw purchased sale
export const useWithdraw = (address: string) => {
  const { chainId, account } = useWeb3React()
  const IFASaleContract = useIFASaleContract(address)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const handleWithdraw = useCallback(async () => {
    try {
      setIsLoading(true)
      const tx = await IFASaleContract.methods.withdraw().send({ from: account })
      dispatch(fetchPublicIdoUserDataAsync(account, chainId))
      setIsLoading(false)

      return tx.transactionHash
    } catch (err) {
      setIsLoading(false)
      console.error(err)
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, address, dispatch, fetchPublicIdoUserDataAsync])

  return { onWithdraw: handleWithdraw, isLoading }
}

// // purchase no whitelist
export const usePurchase = (address: string) => {
  const { chainId, account } = useWeb3React()
  const IFASaleContract = useIFASaleContract(address)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const handlePurchase = useCallback(
    async (paymentAmount: string) => {
      try {
        setIsLoading(true)
        const tx = await IFASaleContract.methods.purchase(paymentAmount).send({ from: account })
        dispatch(fetchPublicIdoUserDataAsync(account, chainId))
        setIsLoading(false)

        return tx.transactionHash
      } catch (err) {
        setIsLoading(false)
        console.error(err)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, address, dispatch],
  )

  return { onPurchase: handlePurchase, isLoading }
}

// purchase with whitelist
export const useWhitelistedPurchase = (address: string) => {
  const { chainId, account } = useWeb3React()
  const IFASaleContract = useIFASaleContract(address)
  const [isLoading, setIsLoading] = useState(false)
  const { toastError } = useToast()
  const dispatch = useDispatch()

  const handlePurchase = useCallback(
    async (paymentAmount: string, isWhitelistSale: boolean, onSuccess?: VoidFunction) => {
      try {
        setIsLoading(true)
        const confirmedAddresses = await fetchWhitelistedList(address, isWhitelistSale)
        if (confirmedAddresses.findIndex((val) => val.toLowerCase() === account.toLowerCase()) === -1) {
          throw Error(
            'This address is not whitelisted. If you believe this is a mistake please reach out to our customer support.',
          )
        }
        const merkleProof = computeMerkleProof(confirmedAddresses, getAddressIndex(confirmedAddresses, account))
        const tx = await IFASaleContract.methods.whitelistedPurchase(paymentAmount, merkleProof).send({ from: account })
        dispatch(fetchPublicIdoUserDataAsync(account, chainId))
        setIsLoading(false)
        onSuccess()
        return tx.transactionHash
      } catch (err) {
        setIsLoading(false)
        console.error(err)
        toastError(err.message)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, address, dispatch, toastError],
  )

  return { onPurchase: handlePurchase, isLoading }
}

export const useGetMaxPaymentInWei = (address: string, chainId: Chain, allocSnapshotBlock: number) => {
  const { account } = useWeb3React()
  const IFASaleContract = useIFASaleContract(address)
  const { fastRefresh } = useRefresh()
  const blockNumber = useCurrentBlock(chainId)
  const [value, setValue] = useState(new BigNumber(0))

  useEffect(() => {
    const getMaxPayment = async () => {
      try {
        const tx = await IFASaleContract.methods.getMaxPayment(account)
        const res = await tx.call()
        setValue(new BigNumber(res))
      } catch (err) {
        console.error(err)
      }
      return null
    }

    if (account && blockNumber > allocSnapshotBlock) {
      getMaxPayment()
    }
  }, [account, address, fastRefresh, IFASaleContract.methods, blockNumber, allocSnapshotBlock])

  return value
}
