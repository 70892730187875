import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

const Restricted: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box marginTop={13}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img src="/images/idos/attention2.svg" width="100px" alt="attention" />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Regions ineligible to participate this project')}
        </Typography>
      </Box>
      <Box marginTop={5} paddingX={20}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t(
            'Citizens and residents of jurisdictions, countries, and regions, including the US, Mainland China, Hong Kong, Macau, Taiwan, and UN-sanctioned jurisdictions are excluded from participation.',
          )}
        </Typography>
      </Box>
      <Box marginTop={9} display="flex" justifyContent="center">
        <BorderedInfoCard>
          <Typography variant="caption" color="textSecondary">
            {t('We apologize for any inconvenience and disappointment.')}
          </Typography>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default Restricted
