import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { registerToken } from 'utils/wallet'
import { BorderedInfoCard } from '../../StyledComponent'

type PostSaleProps = {
  participants: number
  token: string
  address: string
  sales: number
  claimedShare?: number
  claimedNFT?: string
}

const PostSale: React.FC<PostSaleProps> = ({ participants, token, address, sales, claimedShare, claimedNFT }) => (
  <Box marginTop={13} paddingX={0}>
    <Box display="flex" justifyContent="center">
      <img src="/images/idos/Hammer.svg" width="100px" alt="time sand" />
    </Box>
    <Box marginTop={6}>
      <Typography color="textSecondary" variant="subtitle1" align="center">
        The Bonus Sale has ended.
      </Typography>
    </Box>
    <Box display="flex" justifyContent="center" paddingX={0} style={{ flexWrap: 'wrap' }}>
      {participants > 0 && (
        <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            Participants
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            {participants}
          </Typography>
        </BorderedInfoCard>
      )}
      {sales > 0 && (
        <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            Total Sales
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            {sales}
          </Typography>
        </BorderedInfoCard>
      )}
      {claimedShare && claimedShare > 0 && (
        <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            Claimed Share
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            {claimedShare} {token}
          </Typography>
        </BorderedInfoCard>
      )}
      {claimedNFT && (
        <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            Claimed Share
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            {claimedNFT} NFT
          </Typography>
        </BorderedInfoCard>
      )}
    </Box>
    {address && (
      <Box display="flex" justifyContent="center" marginTop={5}>
        <Button onClick={() => registerToken(address, token, 18)} style={{ color: '#0AC6E5' }}>
          Add {token} to the wallet
          <img style={{ marginLeft: 8 }} src="/images/idos/Wallet.svg" alt="add to wallet" />
        </Button>
      </Box>
    )}
  </Box>
)

export default PostSale
