import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 29 29"
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;}
	.st1{fill:url(#SVGID_1_);}
	.st2{fill:url(#SVGID_00000064315940778353023060000004710369091671004802_);}
	
		.st3{opacity:0.14;clip-path:url(#SVGID_00000014608638229506211450000018083766731788899201_);fill:url(#SVGID_00000016070033760859132270000012792168074110245030_);enable-background:new    ;}`}
      </style>
      <path
        className="st0"
        d="M29,25.2c0,2.1-1.7,3.8-3.8,3.8H3.8C1.7,29,0,27.3,0,25.2V3.8C0,1.7,1.7,0,3.8,0h21.4C27.3,0,29,1.7,29,3.8
	C29,3.8,29,25.2,29,25.2z"
      />
      <g id="logo">
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="19.5"
          y1="299.00897"
          x2="19.5"
          y2="308.974"
          gradientTransform="matrix(1 0 0 1 0 -284.55402)"
        >
          <stop offset="0" style={{ stopColor: '#FF4681' }} />
          <stop offset="1" style={{ stopColor: '#9B44F6' }} />
        </linearGradient>
        <path className="st1" d="M24.5,24.4h-10l10-9.9V24.4z" />

        <linearGradient
          id="SVGID_00000140698195206820721380000016067253275782577853_"
          gradientUnits="userSpaceOnUse"
          x1="-0.14064"
          y1="294.26361"
          x2="19.79137"
          y2="314.19464"
          gradientTransform="matrix(1 0 0 1 0 -284.55402)"
        >
          <stop offset="0" style={{ stopColor: '#FF4681' }} />
          <stop offset="0.739" style={{ stopColor: '#9B44F6' }} />
        </linearGradient>
        <path
          style={{ fill: 'url(#SVGID_00000140698195206820721380000016067253275782577853_)' }}
          d="M24.5,24.4H4.6V4.5L24.5,24.4z"
        />
      </g>
      <g>
        <defs>
          <polygon
            id="SVGID_00000169543458738121992910000008831013647947328699_"
            points="24.5,24.5 14.5,24.5 24.5,14.6 		"
          />
        </defs>
        <clipPath id="SVGID_00000012462347012444229690000010345743295536369539_">
          <use xlinkHref="#SVGID_00000169543458738121992910000008831013647947328699_" style={{ overflow: 'visible' }} />
        </clipPath>

        <linearGradient
          id="SVGID_00000104701913482443738750000003449081274866345149_"
          gradientUnits="userSpaceOnUse"
          x1="-831.89545"
          y1="-494.34326"
          x2="-829.50244"
          y2="-491.95129"
          gradientTransform="matrix(0.8685 1.214 -0.8089 0.5787 344.54001 1315.39844)"
        >
          <stop offset="0" style={{ stopColor: '#000000' }} />
          <stop offset="1" style={{ stopColor: '#000000' }} />
        </linearGradient>

        <path
          style={{
            opacity: 0.14,
            clipPath: 'url(#SVGID_00000012462347012444229690000010345743295536369539_)',
            fill: 'url(#SVGID_00000104701913482443738750000003449081274866345149_)',
          }}
          d="
		M24.5,24.5l-5-5l0.9-0.8L24.5,24.5z"
        />
      </g>
    </Svg>
  )
}

export default Icon
