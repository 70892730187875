import React, { useEffect, useState } from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'

type CountdownProps = {
  targetTime: Date
  label: string
}

const TimerBox = withStyles({
  root: {
    borderRadius: '8px',
    background: '#3B4346',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 16px 16px 10px',
  },
})(Box)

const SmallCountdown: React.FC<CountdownProps> = ({ targetTime, label }) => {
  const [diffInSeconds, updateDiff] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      const now = new Date()
      updateDiff(Math.trunc((targetTime.getTime() - now.getTime()) / 1000))
    }, 1000)
  }, [targetTime, diffInSeconds])

  const counter = [
    `0${Math.floor(diffInSeconds / (3600 * 24))}`.substr(-2),
    `0${Math.floor((diffInSeconds % (3600 * 24)) / 3600)}`.substr(-2),
    `0${Math.floor((diffInSeconds % 3600) / 60)}`.substr(-2),
    `0${Math.floor(diffInSeconds % 60)}`.substr(-2),
  ]
  return (
    <Box display="flex" flexDirection="row">
      <Box paddingTop="2px">
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
      </Box>

      <Box marginRight={1} />
      <TimerBox>
        <img src="/images/idos/timer.svg" width="18px" alt="timer" />
        <Box marginRight={3} />
        <Typography variant="caption"> {counter.join(' : ')}</Typography>
      </TimerBox>
    </Box>
  )
}

export default SmallCountdown
