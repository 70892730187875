/* eslint-disable no-case-declarations */

import { createSlice } from '@reduxjs/toolkit'
import { getActiveIdos, getBonusSales, getIdosByChain, getPublicSales } from 'utils/idosHelpers'
import { Chain } from 'config/constants/types'
import BigNumber from 'bignumber.js'
import { BonusSaleConfig, IDOType, PublicSaleConfig, IdosState } from 'state/types'
import {
  fetchIdoPaymentUserAllowances,
  fetchIdoStakingUserAllowances,
  fetchIdosUserHasWithdrawn,
  fetchIdosUserPaymentReceived,
  fetchIdoUserPaymentTokenBalances,
  fetchIdoUserStakingTokenBalances,
  fetchIdoUserWhitelisted,
  fetchUserStakeWeights,
} from './fetchPublicIdosUser'
import {
  fetchBonusIdoUserPaymentTokenBalances,
  fetchBonusIdoPaymentUserAllowances,
  fetchBonusUserWhitelisted,
  fetchPurchasedUnits,
} from './fetchBonusIdosUser'
import { fetchPublicIdos, fetchBonusIdos } from './fetchIdos'

const initialState: IdosState = {
  data: {
    [IDOType.PUBLIC_SALE]: [],
    [IDOType.BONUS_SALE]: [],
  },
  initialised: false,
  syncedOnchain: false,
}

export const idosSlice = createSlice({
  name: 'Idos',
  initialState,
  reducers: {
    initIdosPublicData: (state, action) => {
      state.data = action.payload
      state.syncedOnchain = false
      state.initialised = true
    },
    syncIdosPublicData: (state, action) => {
      state.data = action.payload
      state.syncedOnchain = true
    },
    setPublicIdoUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      state.data[IDOType.PUBLIC_SALE] = state.data[IDOType.PUBLIC_SALE].map((currentConfig) => {
        const userData = arrayOfUserDataObjects.find((val) => val.id === currentConfig.id)
        return { ...currentConfig, userData }
      })
    },
    setIdosBonusData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      state.data[IDOType.BONUS_SALE] = state.data[IDOType.BONUS_SALE].map((currentConfig) => {
        const userData = arrayOfUserDataObjects.find((val) => val.id === currentConfig.id)
        return { ...currentConfig, userData }
      })
    },
  },
})

export const { initIdosPublicData, syncIdosPublicData, setIdosBonusData, setPublicIdoUserData } = idosSlice.actions

export const fetchInitIdosPublicDataAsync = () => async (dispatch) => {
  const publicIdos = await getPublicSales()
  const bonusIdos = await getBonusSales()
  dispatch(
    initIdosPublicData({
      [IDOType.PUBLIC_SALE]: publicIdos,
      [IDOType.BONUS_SALE]: bonusIdos,
    }),
  )
}

export const fetchIdosPublicDataAsync = () => async (dispatch, getState) => {
  const { blocks, idos } = getState()
  const publicIdos = await fetchPublicIdos(idos.data[IDOType.PUBLIC_SALE], blocks)
  const bonusIdos = await fetchBonusIdos(idos.data[IDOType.BONUS_SALE])
  dispatch(
    syncIdosPublicData({
      [IDOType.PUBLIC_SALE]: publicIdos,
      [IDOType.BONUS_SALE]: bonusIdos,
    }),
  )
}

export const fetchIdoUserDataAsync = (account, chainId: Chain) => async (dispatch) => {
  dispatch(fetchPublicIdoUserDataAsync(account, chainId))
  dispatch(fetchBonusIdoUserDataAsync(account, chainId))
}

export const fetchPublicIdoUserDataAsync = (account, chainId: Chain) => async (dispatch, getState) => {
  const { currentBlock } = getState().blocks[chainId]
  const { idos } = getState()
  const publicSaleObj = idos.data[IDOType.PUBLIC_SALE]
  const publicSales = getIdosByChain(getActiveIdos(publicSaleObj), chainId) as PublicSaleConfig[]

  const userHasWithdrawn = await fetchIdosUserHasWithdrawn(publicSales, account, chainId)
  const usePaymentReceived = await fetchIdosUserPaymentReceived(publicSales, account, chainId)
  const usePaymentTokenBalances = await fetchIdoUserPaymentTokenBalances(publicSales, account, chainId)
  const userStakingTokenBalances = await fetchIdoUserStakingTokenBalances(publicSales, account, chainId)
  const userStakingTokenAllowances = await fetchIdoStakingUserAllowances(publicSales, account, chainId)
  const userPaymentTokenAllowances = await fetchIdoPaymentUserAllowances(publicSales, account, chainId)
  const idoUserWhitelisted = await fetchIdoUserWhitelisted(publicSales, account, chainId)
  const userStakeWeights = await fetchUserStakeWeights(publicSales, account, chainId, currentBlock)

  const arrayOfUserDataObjects = publicSales.map((publicSale) => {
    return {
      id: publicSale.id,
      hasWithdrawn: (userHasWithdrawn[publicSale.id] && userHasWithdrawn[publicSale.id][0]) || false,
      paymentReceivedInWei: usePaymentReceived[publicSale.id] || new BigNumber(0).toJSON(),
      paymentTokenBalanceInWei: usePaymentTokenBalances[publicSale.id],
      stakingTokenBalanceInWei: userStakingTokenBalances[publicSale.id],
      stakingTokenAllowanceInWei: userStakingTokenAllowances[publicSale.id],
      paymentTokenAllowanceInWei: userPaymentTokenAllowances[publicSale.id],
      userStakeWeight: userStakeWeights[publicSale.id] || new BigNumber(0).toJSON(),
      isWhitelisted: idoUserWhitelisted[publicSale.id] || false,
    }
  })

  dispatch(setPublicIdoUserData({ arrayOfUserDataObjects }))
}

export const fetchBonusIdoUserDataAsync = (account, chainId: Chain) => async (dispatch) => {
  const bonusSaleObj = await getBonusSales()
  const bonusSales = getIdosByChain(getActiveIdos(bonusSaleObj), chainId) as BonusSaleConfig[]

  const idoUserWhitelisted = await fetchBonusUserWhitelisted(bonusSales, account, chainId)
  const userPurchasedUnits = await fetchPurchasedUnits(bonusSales, account, chainId)
  const usePaymentTokenBalances = await fetchBonusIdoUserPaymentTokenBalances(bonusSales, account, chainId)
  const userPaymentTokenAllowances = await fetchBonusIdoPaymentUserAllowances(bonusSales, account, chainId)

  const arrayOfUserDataObjects = bonusSales.map((bonusSale, index) => {
    return {
      id: bonusSale.id,
      isWhitelisted: idoUserWhitelisted[index][0],
      purchasedUnits: userPurchasedUnits[index],
      paymentTokenAllowanceInWei: userPaymentTokenAllowances[index],
      paymentTokenBalanceInWei: usePaymentTokenBalances[index],
    }
  })

  dispatch(setIdosBonusData({ arrayOfUserDataObjects }))
}

export default idosSlice.reducer
