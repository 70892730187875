import React from 'react'
import { Flex } from 'uikit'
import { Box, ButtonTypeMap, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import { PublicSaleConfig, IDOConfig } from 'state/types'
import { convertFromWei } from 'utils/formatBalance'
import { useStakeAmount } from 'hooks/useIFAMaster'
import { useWithdraw } from 'hooks/useIFASale'
import { useIdoUser } from 'state/idos/hooks'
import styled from 'styled-components'
import { RoundedButton } from 'views/IDO/Detail/Components/StyledComponent'
import { StakedToken } from '../../views/IDO/Detail/Components/StakingInformationCard'

const ClaimButton = withStyles<
  'root',
  Record<string, never>,
  ButtonTypeMap['props'] & { isSmall: boolean; onClick: (e: React.MouseEvent<HTMLButtonElement>) => void }
>({
  root: {
    width: ({ isSmall }) => (isSmall ? '100%' : '114px'),
    height: ({ isSmall }) => (isSmall ? '38px' : '42px'),
    marginTop: ({ isSmall }) => (isSmall ? '10px' : '0'),
    alignSelf: 'center',
  },
})(RoundedButton)

const Divider = styled.div`
  background-color: #e9ebec;
  height: 1px;
  margin: 16px auto;
  width: 100%;
`

interface ParticipatedProjectCardProps {
  projectDetail: IDOConfig
}

const ParticipatedProjectCard: React.FC<ParticipatedProjectCardProps> = ({ projectDetail }) => {
  // TODO split this by projectDetail sale type
  const publicProjectDetail = projectDetail as PublicSaleConfig
  const { airdropInfo } = projectDetail
  const userData = useIdoUser(publicProjectDetail.id)
  const stakedAmount = useStakeAmount(publicProjectDetail.masterAddress, publicProjectDetail.trackId)
  const hasWithdrawn = userData && userData.hasWithdrawn
  const paymentReceived = userData && convertFromWei(userData.paymentReceivedInWei)
  const purchasePeriod = publicProjectDetail && publicProjectDetail.purchasePeriod
  const claimPeriod = publicProjectDetail && publicProjectDetail.claimPeriod
  const salePrice = purchasePeriod && purchasePeriod.salePrice

  let shares = '-'
  if (salePrice && paymentReceived) {
    shares = paymentReceived.dividedBy(salePrice).decimalPlaces(5).toString()
  }
  const { onWithdraw, isLoading } = useWithdraw(publicProjectDetail.saleAddress)
  const now = new Date()
  const claimStart = claimPeriod.startTime
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Flex justifyContent="space-between" mt="50px" flexDirection={isSmall ? 'column' : 'row'}>
        <Flex flexDirection={isSmall ? 'row' : 'column'} justifyContent="space-between">
          <Box marginBottom={isSmall ? 0 : 2}>
            <Typography variant="body2" color="textSecondary">
              {airdropInfo?.isAirdropped ? 'Purchased Token' : 'Claimable Tokens'}
            </Typography>
          </Box>
          <Typography variant="h4">{shares}</Typography>
        </Flex>
        {!airdropInfo?.isAirdropped && (
          <ClaimButton
            onClick={(e) => {
              e.preventDefault()
              onWithdraw()
            }}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            isSmall={isSmall}
            disabled={isLoading || hasWithdrawn || now < new Date(claimStart)}
          >
            {hasWithdrawn ? 'Claimed' : 'Claim'}
          </ClaimButton>
        )}
      </Flex>
      <Divider />
      <StakedToken
        masterAddress={publicProjectDetail.masterAddress}
        tokenName={publicProjectDetail.stakingToken.symbol}
        trackId={publicProjectDetail.trackId}
        tokenDecimals={publicProjectDetail.stakingToken.decimals}
        stakedAmountInWei={stakedAmount}
        projectDetail={publicProjectDetail}
        allowStakeMore
      />
      <Box marginTop={6} />
      <Box mb={8} />
    </>
  )
}

export default ParticipatedProjectCard
