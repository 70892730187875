import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M14.6049 4.36197L14.2799 5.8775L14.1556 6.45696C14.0555 6.92379 14.5143 7.31487 14.9594 7.14213L17.2807 6.24122C17.6829 6.0851 17.7938 5.56809 17.4909 5.26073L15.7432 3.48719C15.4081 3.14712 14.8293 3.31568 14.7292 3.78251L14.6049 4.36197Z"
        fill="#00FFB9"
      />
      <path
        d="M5.48687 14.231L5.81189 12.7154L5.93616 12.136C6.03628 11.6691 5.57751 11.2781 5.13241 11.4508L2.81113 12.3517C2.40885 12.5078 2.29797 13.0248 2.60085 13.3322L4.34857 15.1057C4.68369 15.4458 5.26248 15.2772 5.3626 14.8104L5.48687 14.231Z"
        fill="#00FFB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1811 4.22501C13.9281 4.33281 14.5875 4.42796 15.1612 4.46934C15.4917 4.49319 15.7403 4.78045 15.7164 5.11096C15.6926 5.44148 15.4053 5.69008 15.0748 5.66623C14.4546 5.62149 13.7334 5.51736 12.973 5.40757C12.728 5.3722 12.479 5.33625 12.228 5.3015C11.1799 5.15642 10.0594 5.02601 8.92067 5.01436C6.64428 4.99108 4.35697 5.44259 2.49518 7.14742C2.25079 7.37121 1.87126 7.3545 1.64747 7.11011C1.42368 6.86572 1.44039 6.48619 1.68478 6.26241C3.8594 4.27112 6.49833 3.78952 8.93295 3.81443C10.1498 3.82687 11.3309 3.96589 12.3926 4.11284C12.6655 4.15062 12.9283 4.18854 13.1811 4.22501Z"
        fill="#00FFB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91071 14.3679C6.16369 14.2601 5.50428 14.165 4.93065 14.1236C4.60013 14.0997 4.35153 13.8125 4.37538 13.482C4.39922 13.1515 4.68649 12.9029 5.017 12.9267C5.63724 12.9714 6.35841 13.0756 7.11883 13.1854C7.36376 13.2207 7.61276 13.2567 7.86377 13.2914C8.91191 13.4365 10.0324 13.5669 11.1711 13.5786C13.4475 13.6019 15.7348 13.1503 17.5966 11.4455C17.841 11.2217 18.2205 11.2384 18.4443 11.4828C18.6681 11.7272 18.6514 12.1067 18.407 12.3305C16.2324 14.3218 13.5935 14.8034 11.1588 14.7785C9.94205 14.7661 8.76086 14.627 7.69923 14.4801C7.42631 14.4423 7.1635 14.4044 6.91071 14.3679Z"
        fill="#00FFB9"
      />
    </Svg>
  )
}

export default Icon
