import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from 'uikit'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataAsync,
  fetchIdosPublicDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
} from './actions'
import { State, Farm } from './types'
import { useBlockInitialised } from './block/hooks'
import { useIdosInitiated } from './idos/hooks'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  const dataInitialised = useIdosInitiated()
  const blockInitialised = useBlockInitialised()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
  }, [dispatch, slowRefresh])

  useEffect(() => {
    if (dataInitialised && blockInitialised) {
      dispatch(fetchIdosPublicDataAsync())
    }
  }, [dispatch, dataInitialised, blockInitialised, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 2 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(1).div(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceStaxBusd = (): BigNumber => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  const pid = 1 // IF-BUSD LP
  const farm = useFarmFromPid(pid)
  if (chainId === 97 || !farm) {
    return ZERO
  }
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastCustom: (
        title: string,
        action: any,
        description?: string,
        toastBackground?: string,
        toastIcon?: string,
        toastBorder?: string,
      ) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.CUSTOM,
          title,
          description,
          action,
          alwaysShow: true,
          toastBackground,
          toastIcon,
          toastBorder,
        })
      },
      toastAnnouncement: (title: string, action: any, description?: string, toastBackground?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.ANNOUNCEMENT,
          title,
          description,
          action,
          alwaysShow: true,
          toastBackground,
        })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// App
export const useAppStateUnavailableNetwork = (): boolean => {
  const isUnavailable = useSelector((state: State) => state.app.isNetworkUnavailable)
  return isUnavailable
}
