import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import { Token } from 'config/constants/types'
import AddToCalendar from 'components/AddToCalendar'
import { MINIMUM_IDIA, MINIMUM_IF } from 'config/constants/kyc'

import { useTranslation } from 'contexts/Localization'
import format from 'date-fns/format'
import { differenceInHours } from 'date-fns'

import { BorderedInfoCard } from '../../StyledComponent'

type PreSaleProps = {
  hasCompletedKYC: boolean
  endTime: Date
  subscriptionEndTime: Date
  token: Token
  projectToken: string
}

const PreSale: React.FC<PreSaleProps> = ({ hasCompletedKYC, endTime, token, subscriptionEndTime, projectToken }) => {
  const { t } = useTranslation()
  // TODO: Update this one
  const title = `${projectToken} Subscription begins!`
  const hourDiff = differenceInHours(subscriptionEndTime, endTime)
  const description = `You have ${hourDiff} hours to stake ${token.symbol} to secure ${projectToken} allocation! 
  Staking starts from ${format(endTime, 'LLL do yyyy KK:mm aa OOOO')} to ${format(
    subscriptionEndTime,
    'LLL do yyyy KK:mm aa OOOO',
  )} ${window.location.href.split('?')[0]}`
  return (
    <Box marginTop={13}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {/* Used to center the image */}
        <AddToCalendar title={title} description={description} startTime={endTime} hide />
        <img src="/images/idos/SubscribeChair.svg" width="100px" alt="waiting chair" />
        <AddToCalendar title={title} description={description} startTime={endTime} />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          {t('Subscription starts in')}
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={endTime} />
      </Box>
      <Box marginTop={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {!hasCompletedKYC
            ? t('Subscribe to the Public Sale by staking %symbol% token and reserve your allocation.', {
                symbol: token.symbol,
              })
            : t(
                "Subscribe by staking %symbol% tokens to receive token purchase allocation based on your stakeweight (amount and time staked) relative to all participants' stakeweight.",
                { symbol: token.symbol },
              )}
        </Typography>
      </Box>
      {!hasCompletedKYC && (
        <Box marginTop={9} display="flex" justifyContent="center">
          <BorderedInfoCard width="350px">
            <Typography variant="caption" color="textSecondary">
              <ul>
                <li>
                  {t(
                    'This sale requires you to pass KYC. Your identity is tied to the connected wallet you use during KYC.',
                  )}
                </li>
                <li>
                  {t('You must hold at least %min_if% or %min_idia% IDIA in your wallet to proceed with the KYC.', {
                    min_if: MINIMUM_IF,
                    min_idia: MINIMUM_IDIA,
                  })}
                </li>
                <li>
                  {t('You can only use a wallet that has passed KYC to subscribe, get allocation, purchase and claim.')}
                </li>
              </ul>
            </Typography>
          </BorderedInfoCard>
        </Box>
      )}
    </Box>
  )
}

export default PreSale
