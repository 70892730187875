import React from 'react'
import { PublicSaleConfig, KYCStatus } from 'state/types'
import Steps from 'components/Steps/Steps'
import styled from 'styled-components'
import { useTotalStakeWeight, useUserStakeWeight } from 'hooks/useIFAMaster'
import BigNumber from 'bignumber.js'
import { Box } from '@material-ui/core'
import { Spinner } from 'uikit'
import { convertFromWei } from 'utils/formatBalance'
import { useKycStatus } from 'hooks/useKycStatus'
import { useCurrentBlock } from 'state/block/hooks'
import { useGetMaxPaymentInWei } from 'hooks/useIFASale'
import { useIdoUser } from 'state/idos/hooks'
import { useTranslation } from 'contexts/Localization'
import PreSale from './PreSale'
import SubscribeClosed from './SubscribeClosed'
import PreClaim from './PreClaim'
import PreAirdrop from './PreAirdrop'
import PublicSaleEnded from './PublicSaleEnded'
import SubscriptionPeriod from './SubscriptionPeriod'
import ConfirmedAllocationPeriod from './ConfirmedAllocationPeriod'
import PurchasePeriod from './PurchasePeriod'
import NoStakeSubscription from './NoStakeSubscription'
import Restricted from './Restricted'
import Claim from './Claim'

type PublicSaleProps = {
  project: PublicSaleConfig
}

const STEPS = [
  {
    title: 'Subscribe',
    status: 'DONE',
  },
  {
    title: 'Allocation',
    status: 'IN_PROGRESS',
  },
  {
    title: 'Purchase',
    status: 'NOT_DONE',
  },
  {
    title: 'Claim',
    status: 'NOT_DONE',
  },
]

const StepsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PublicSale: React.FC<PublicSaleProps> = ({ project }) => {
  const { id, purchasePeriod, masterAddress, subscribePeriod, claimPeriod, chainId } = project
  const userData = useIdoUser(id)
  const { t } = useTranslation()

  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)
  const paymentReceived = convertFromWei(paymentReceivedInWei)
  const hasWithdrawn = (userData && userData.hasWithdrawn) || false
  const now = new Date()
  const blockNumber = useCurrentBlock(project.chainId)

  const { allocSnapshotBlock } = purchasePeriod
  const validBlockNumber = allocSnapshotBlock
    ? Math.min(allocSnapshotBlock, Math.max(0, blockNumber - 10))
    : Math.max(0, blockNumber - 10)

  const userStakeWeight = new BigNumber(useUserStakeWeight(masterAddress, project.trackId, validBlockNumber))
  const totalStakeWeight = new BigNumber(useTotalStakeWeight(masterAddress, project.trackId, validBlockNumber))

  const hasStaked = userStakeWeight.isGreaterThan(0)
  const allocationPaymentInWei = useGetMaxPaymentInWei(
    project.saleAddress,
    project.chainId,
    purchasePeriod.allocSnapshotBlock,
  )
  const estimatedAllocation = purchasePeriod.maximumPurchase
    ? BigNumber.min(
        userStakeWeight.div(totalStakeWeight).times(purchasePeriod.saleAmount),
        new BigNumber(purchasePeriod.maximumPurchase).dividedBy(purchasePeriod.salePrice),
      )
    : userStakeWeight.div(totalStakeWeight).times(purchasePeriod.saleAmount)

  const { status: kycStatus, country } = useKycStatus()
  if (!purchasePeriod) return <div>{t('No public sale available<')}</div>
  if (blockNumber === 0) {
    return (
      <Box display="flex" marginTop="15vw" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  if (
    kycStatus === KYCStatus.VERIFIED &&
    project.restrictedCountries?.length > 0 &&
    (project.restrictedCountries?.includes(country) || !country)
  ) {
    return <Restricted />
  }
  if (now < new Date(subscribePeriod.startTime)) {
    return (
      <PreSale
        endTime={new Date(subscribePeriod.startTime)}
        subscriptionEndTime={new Date(subscribePeriod.endTime)}
        hasCompletedKYC={kycStatus === KYCStatus.VERIFIED}
        token={project.stakingToken}
        projectToken={project.token.symbol}
      />
    )
  }
  let currentStep
  let lastDoneStep
  let Component

  // allocation calculation
  const { salePrice } = purchasePeriod
  const allocationInPayment = convertFromWei(allocationPaymentInWei, project.paymentToken.decimals)
  const tokenAllocation = allocationInPayment.div(salePrice)
  const fullDisplayshares = convertFromWei(paymentReceivedInWei, project.paymentToken.decimals)
    .dividedBy(salePrice)
    .decimalPlaces(5)
    .toString()

  // missed KYC or staking
  if (
    (!hasStaked || kycStatus !== KYCStatus.VERIFIED) &&
    now > new Date(subscribePeriod.endTime) &&
    now < new Date(purchasePeriod.endTime)
  ) {
    currentStep = 0
    lastDoneStep = -1
    Component = <SubscribeClosed token={project.stakingToken} />
  } else if (
    // Subscription Period but have not kyced
    kycStatus !== KYCStatus.VERIFIED &&
    now > new Date(subscribePeriod.startTime) &&
    now < new Date(subscribePeriod.endTime)
  ) {
    currentStep = undefined
    lastDoneStep = undefined
    Component = (
      <NoStakeSubscription
        endTime={new Date(subscribePeriod.endTime)}
        hasCompletedKYC={false}
        token={project.stakingToken}
      />
    )
  } else if (
    // Subscription Period
    now > new Date(subscribePeriod.startTime) &&
    now < new Date(subscribePeriod.endTime)
  ) {
    currentStep = 0
    lastDoneStep = hasStaked ? 0 : -1
    Component = (
      <SubscriptionPeriod
        id={id}
        masterAddress={masterAddress}
        chainId={chainId}
        trackId={project.trackId}
        token={project.stakingToken}
        endTime={new Date(subscribePeriod.endTime)}
        estimatedAllocation={estimatedAllocation}
        saleToken={project.token}
        maxTotalStake={subscribePeriod?.isLimited && new BigNumber(subscribePeriod?.maxTotalStake)}
      />
    )
  } else if (
    // when user has purchase but still waiting for claim period
    now > new Date(subscribePeriod.endTime) &&
    now < new Date(claimPeriod.startTime) &&
    paymentReceived.isGreaterThan(0) &&
    allocationInPayment.isLessThanOrEqualTo(0.01)
  ) {
    if (project.airdropInfo?.isAirdropped) {
      currentStep = 3
      lastDoneStep = 2
      Component = (
        <PreAirdrop
          shares={fullDisplayshares}
          airdropInfo={project.airdropInfo}
          token={project.token.symbol}
          endTime={new Date(project.airdropInfo.initialAirdrop)}
        />
      )
    } else {
      currentStep = 2
      lastDoneStep = 2
      Component = (
        <PreClaim shares={fullDisplayshares} token={project.token.symbol} endTime={new Date(claimPeriod.startTime)} />
      )
    }
  } else if (
    // Waiting for alocation period to purchasing period
    now > new Date(subscribePeriod.endTime) &&
    now < new Date(purchasePeriod.endTime)
  ) {
    if (now > new Date(subscribePeriod.endTime) && now < new Date(purchasePeriod.startTime)) {
      currentStep = 1
      lastDoneStep = 1
      Component = (
        <ConfirmedAllocationPeriod
          startTime={new Date(purchasePeriod.startTime)}
          endTime={new Date(purchasePeriod.endTime)}
          token={project.token}
          allocation={allocationInPayment}
          totalCoin={tokenAllocation}
          paymentToken={project.paymentToken}
        />
      )
    } else {
      currentStep = 2
      lastDoneStep = 1

      Component = (
        <PurchasePeriod
          id={id}
          saleToken={project.token}
          address={project.saleAddress}
          paymentToken={project.paymentToken}
          airdropInfo={project.airdropInfo}
          publicSalePurchase={purchasePeriod}
          allocation={allocationInPayment.plus(paymentReceived)}
          paymentReceived={paymentReceived}
          passedKYC={kycStatus === KYCStatus.VERIFIED}
          chainId={chainId}
        />
      )
    }
  } else if (paymentReceived.isGreaterThan(0)) {
    currentStep = 3
    lastDoneStep = hasWithdrawn ? 3 : 2
    if (hasWithdrawn || project.airdropInfo?.isAirdropped) {
      Component = (
        <PublicSaleEnded
          participants={claimPeriod.purchaserCount}
          token={project.token.symbol}
          airdropInfo={project.airdropInfo}
          address={project.token.address}
          paymentToken={project.paymentToken.symbol}
          sales={purchasePeriod.totalPaymentReceived}
          claimedShare={fullDisplayshares}
        />
      )
    } else {
      Component = <Claim saleAddress={project.saleAddress} token={project.token.symbol} shares={fullDisplayshares} />
    }
  } else {
    Component = (
      <PublicSaleEnded
        participants={claimPeriod.purchaserCount}
        token={project.token.symbol}
        airdropInfo={project.airdropInfo}
        address={project.token.address}
        sales={purchasePeriod.totalPaymentReceived}
        paymentToken={project.paymentToken.symbol}
      />
    )
  }

  return (
    <Box>
      {currentStep !== undefined && lastDoneStep !== undefined && (
        <StepsBox>
          <Steps steps={STEPS} currentStep={currentStep} lastDoneStep={lastDoneStep} />
        </StepsBox>
      )}
      {Component}
    </Box>
  )
}

export default PublicSale
