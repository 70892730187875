import { useEffect, useState } from 'react'

export const fetchDefaultWhitelistedList = async (): Promise<string[]> => {
  try {
    const whitelistedList = await (
      await fetch(`/json/kycedAddresses.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    ).json()

    return whitelistedList
  } catch (e) {
    return []
  }
}

export const fetchWhitelistedListOfAddress = async (saleAddress: string): Promise<string[]> => {
  try {
    const whitelistedList = await (
      await fetch(`/json/privateSales/${saleAddress}.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    ).json()

    return whitelistedList
  } catch (e) {
    return []
  }
}

export const fetchWhitelistedList = async (saleAddress: string, isWhitelistSale: boolean): Promise<string[]> => {
  let confirmedAddresses = []
  if (isWhitelistSale) {
    confirmedAddresses = await fetchWhitelistedListOfAddress(saleAddress)
  } else {
    confirmedAddresses = await fetchDefaultWhitelistedList()
  }

  return confirmedAddresses
}

export const useWhitelistedPrivateSale = (saleAddress: string): string[] => {
  const [addresses, setAddresses] = useState([])
  useEffect(() => {
    const fetchJSON = async () => {
      try {
        const response = await fetchWhitelistedListOfAddress(saleAddress)

        setAddresses(response)
      } catch (e) {
        console.error(`failed to fetch json for id ${saleAddress}`)
        setAddresses([])
      }
    }
    fetchJSON()
  }, [saleAddress])

  return addresses
}

export const fetchIsWhitelisted = async (account: string, saleAddresses: string[]) => {
  const isWhitelisted = await Promise.all(
    saleAddresses.map(async (saleAddress: string) => {
      const whitelistedList = await fetchWhitelistedListOfAddress(saleAddress)
      return whitelistedList.findIndex((val) => val.toLowerCase() === account.toLowerCase()) !== -1
    }),
  )
  return isWhitelisted
}

export default useWhitelistedPrivateSale
