import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { registerToken } from 'utils/wallet'
import AirDropWarning from 'components/AirDropWarning'
import { AirdropInfo } from 'state/types'
import { BorderedInfoCard } from '../../StyledComponent'

type PrivateSaleEndedProps = {
  token: string
  address: string
  claimedShare?: string
  airdropInfo?: AirdropInfo
}

const PrivateSaleEnded: React.FC<PrivateSaleEndedProps> = ({ token, address, claimedShare, airdropInfo }) => (
  <Box marginTop={13} paddingX={0}>
    {airdropInfo?.isAirdropped && (
      <AirDropWarning
        tokenName={token}
        firstDayRelease={airdropInfo.firstDayRelease}
        firstDate={airdropInfo.initialAirdrop}
        finalDate={airdropInfo.finalAirdrop}
      />
    )}
    <Box display="flex" justifyContent="center" marginTop={8}>
      <img src="/images/idos/gleam-moon.svg" width="100px" alt="time sand" />
    </Box>
    <Box marginTop={6}>
      <Typography color="textSecondary" variant="subtitle1" align="center">
        The {token} sale has ended.
      </Typography>
    </Box>
    <Box display="flex" justifyContent="center" paddingX={0} style={{ flexWrap: 'wrap' }}>
      {parseInt(claimedShare) > 0 && (
        <BorderedInfoCard marginTop={5} marginLeft={5} marginRight={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {airdropInfo?.isAirdropped ? 'Your share' : 'Claimed Share'}
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            {claimedShare} {token}
          </Typography>
        </BorderedInfoCard>
      )}
    </Box>
    <Box display="flex" justifyContent="center" marginTop={5}>
      <Button onClick={() => registerToken(address, token, 18)} style={{ color: '#0AC6E5' }}>
        Add {token} to the wallet
        <img style={{ marginLeft: 8 }} src="/images/idos/Wallet.svg" alt="add to wallet" />
      </Button>
    </Box>
  </Box>
)

export default PrivateSaleEnded
