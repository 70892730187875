import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useModal } from 'uikit'
import TradeBox from 'components/TradeBox'
import { Chain, Token } from 'config/constants/types'
import { useToast } from 'state/hooks'
import { convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import { IDOSaleToken, PublicSalePurchaseType, AirdropInfo } from 'state/types'
import { useSaleApprove } from 'hooks/useApprove'
import { useWhitelistedPurchase } from 'hooks/useIFASale'

import BigNumber from 'bignumber.js'
import GetToken from 'components/GetToken'
import AirDropWarning from 'components/AirDropWarning'
import { useIdoUser } from 'state/idos/hooks'
import { getAddress } from 'utils/addressHelpers'
import DisclaimerModal, { hasAgreed } from '../../DisclaimerModal'

type PurchasePeriodProps = {
  id: string
  address: string
  chainId: Chain
  paymentToken: Token
  saleToken: IDOSaleToken
  publicSalePurchase: PublicSalePurchaseType
  allocation: BigNumber
  paymentReceived: BigNumber
  passedKYC: boolean
  airdropInfo?: AirdropInfo
  isWhitelistSale?: boolean
}

const PurchasePeriod: React.FC<PurchasePeriodProps> = ({
  id,
  address,
  chainId,
  paymentToken,
  saleToken,
  publicSalePurchase,
  allocation,
  paymentReceived,
  passedKYC,
  airdropInfo,
  isWhitelistSale,
}) => {
  const userData = useIdoUser(id)

  const paymentTokenAllowanceInWei = userData?.paymentTokenAllowanceInWei || new BigNumber(0)
  const paymentTokenBalanceInWei = userData?.paymentTokenBalanceInWei || new BigNumber(0)
  const [existingError, setError] = useState(undefined)

  const [purchaseCounter, setPurchaseCounter] = useState(0)
  let paymentTokenBalance = new BigNumber(0)

  if (paymentTokenBalanceInWei) {
    paymentTokenBalance = convertFromWei(paymentTokenBalanceInWei)
  }
  const { onApprove, isLoading: approveLoading } = useSaleApprove(paymentToken.address, address)
  const handleApprove = useCallback(onApprove, [onApprove])
  const [orderAmount, setOrderAmount] = useState('')
  const { onPurchase, isLoading: purchaseLoading } = useWhitelistedPurchase(address)
  const { toastSuccess } = useToast()

  const onOrderSuccess = useCallback(() => {
    setPurchaseCounter(purchaseCounter + 1)
    toastSuccess(
      `You've successfully purchased ${(parseFloat(orderAmount) / publicSalePurchase.salePrice).toLocaleString()} ${
        saleToken.symbol
      }`,
      airdropInfo?.isAirdropped ? `` : `You can claim the ${saleToken.symbol} when the sale ends.`,
    )
  }, [purchaseCounter, toastSuccess, orderAmount, publicSalePurchase.salePrice, saleToken.symbol, airdropInfo])

  const onOrder = useCallback(async () => {
    onPurchase(getBalanceInWei(new BigNumber(orderAmount)), isWhitelistSale, onOrderSuccess)
  }, [isWhitelistSale, onPurchase, orderAmount, onOrderSuccess])

  const onChange = useCallback((value) => {
    setOrderAmount(value)
  }, [])

  const onError = useCallback(
    (error) => {
      setError(error)
    },
    [setError],
  )

  const maximumPurchase = allocation
  const maximumPurchaseInSaleToken = maximumPurchase.dividedBy(publicSalePurchase.salePrice)
  const availablePurchase = maximumPurchase.minus(paymentReceived)
  const paymentReceivedInSaleToken = paymentReceived.dividedBy(publicSalePurchase.salePrice)
  const availablePurchaseInSaleToken = availablePurchase.dividedBy(publicSalePurchase.salePrice)
  const notes = []
  if (publicSalePurchase.minimumPurchase) {
    notes.push(`Minimum purchase: ${publicSalePurchase.minimumPurchase.toLocaleString()} ${paymentToken.symbol}`)
  }
  if (maximumPurchase) {
    if (paymentReceived.isEqualTo(0)) {
      notes.push(
        `Your total allocation is ${maximumPurchaseInSaleToken
          .decimalPlaces(3, BigNumber.ROUND_FLOOR)
          .toNumber()
          .toLocaleString()} ${saleToken.symbol}.`,
      )
    } else {
      notes.push(
        `You have purchased ${paymentReceivedInSaleToken
          .decimalPlaces(3, BigNumber.ROUND_FLOOR)
          .toNumber()
          .toLocaleString()} ${
          saleToken.symbol
        } out of your total allocation of ${maximumPurchaseInSaleToken
          .decimalPlaces(3, BigNumber.ROUND_FLOOR)
          .toNumber()
          .toLocaleString()} ${saleToken.symbol}.`,
      )
    }
  }

  const saleTypeAddress = `public_${address}`
  const isAgreed = hasAgreed(saleTypeAddress)

  const [onPresentDisclaimer] = useModal(<DisclaimerModal onOrder={onOrder} saleTypeAddress={saleTypeAddress} />)

  const getErrorMessage = (errorString) => {
    if (errorString.indexOf('Exceeding allocation') >= 0) {
      return `You cannot purchase more than ${availablePurchaseInSaleToken
        .decimalPlaces(3, BigNumber.ROUND_FLOOR)
        .toNumber()
        .toLocaleString()} ${saleToken.symbol}.`
    }
    if (errorString.indexOf('too low') >= 0) {
      return `The minimum purchase amount is 0.01 ${paymentToken.symbol} worth.`
    }
    return errorString
  }

  return (
    <Box>
      {airdropInfo?.isAirdropped && (
        <AirDropWarning
          tokenName={saleToken.symbol}
          firstDayRelease={airdropInfo.firstDayRelease}
          firstDate={airdropInfo.initialAirdrop}
          finalDate={airdropInfo.finalAirdrop}
        />
      )}
      <Box display="flex" justifyContent="center" marginTop={10}>
        <TradeBox
          token0Balances={paymentTokenBalance}
          token0={{ name: paymentToken.symbol, image: `/images/tokens/${paymentToken.symbol}.png` }}
          token1={{ name: saleToken.symbol, image: saleToken.image }}
          endTime={new Date(publicSalePurchase.endTime)}
          pricePerToken={publicSalePurchase.salePrice}
          minimumPurchase={publicSalePurchase.minimumPurchase || 0}
          maximumPurchase={maximumPurchase.minus(paymentReceived) || new BigNumber(-1)}
          onChange={onChange}
          onError={onError}
          disabled={purchaseLoading}
          purchasedCounter={purchaseCounter}
          showMax
        />
      </Box>
      <Box>
        {notes.map((note) => {
          return (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Typography variant="body2" color="textSecondary">
                {note}
              </Typography>
            </Box>
          )
        })}
      </Box>
      {paymentReceived.isGreaterThan(0) && (
        <Box display="flex" justifyContent="center">
          <Box style={{ backgroundColor: '#F2F4F5' }} px={2.5} py="1px" borderRadius={4}>
            <Typography variant="body2" color="textSecondary" component="div">
              You can purchase{' '}
              <b>{availablePurchaseInSaleToken.decimalPlaces(3, BigNumber.ROUND_FLOOR).toNumber().toLocaleString()}</b>{' '}
              more {saleToken.symbol}.
            </Typography>
          </Box>
        </Box>
      )}
      {existingError && (
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="error">
            {getErrorMessage(existingError)}
          </Typography>
        </Box>
      )}
      <Box>
        <Box display="flex" justifyContent="center" marginTop={5}>
          <Box width="300px">
            {paymentTokenAllowanceInWei && paymentTokenAllowanceInWei.isGreaterThan(0) ? (
              <Button
                onClick={isAgreed ? onOrder : onPresentDisclaimer}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
                disabled={!passedKYC || existingError || purchaseLoading || paymentTokenBalance.isEqualTo(0)}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!passedKYC ? 'Require KYC' : purchaseLoading ? 'Confirming...' : 'Purchase'}
              </Button>
            ) : (
              <Button
                onClick={handleApprove}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
                disabled={!passedKYC || approveLoading}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!passedKYC ? 'Require KYC' : !approveLoading ? `Approve ${paymentToken.symbol}` : 'Approving'}
              </Button>
            )}
          </Box>
        </Box>
        <GetToken symbol={paymentToken.symbol} address={getAddress(paymentToken.address, chainId)} />
      </Box>
    </Box>
  )
}

export default PurchasePeriod
