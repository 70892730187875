import React from 'react'
import { LinkExternal } from 'uikit'
import styled from 'styled-components'

interface GetTokenProps {
  address?: string
  symbol?: string
}

const ExternalLinkWrap = styled(LinkExternal)`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  margin: auto;
  color: #0ac6e5;
  & > svg {
    fill: #0ac6e5;
  }
`

const GetToken: React.FC<GetTokenProps> = ({ address, symbol }) => {
  return (
    <ExternalLinkWrap href={`${process.env.REACT_APP_SWAP_DOMAIN}/#/swap/${address}`}>Get {symbol}</ExternalLinkWrap>
  )
}

export default GetToken
