import React, { useMemo } from 'react'
import { Box, Divider, Typography, useMediaQuery, useTheme as useMuiTheme } from '@material-ui/core'

import useTheme from 'hooks/useTheme'
import { useStakeAmount } from 'hooks/useIFAMaster'
import { KYCStatus, PublicSaleConfig } from 'state/types'
import { convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useModal, Text } from 'uikit'
import { useKycStatus } from 'hooks/useKycStatus'
import { useKycSession } from 'hooks/useKycSession'
import { useWeb3React } from '@web3-react/core'
import UnlockButton from 'components/UnlockButton'
import tokens from 'config/constants/tokens'
import { MINIMUM_IDIA, MINIMUM_IF } from 'config/constants/kyc'
import { useGetBalance } from 'hooks/useGetBalance'
import { getAddress } from 'utils/addressHelpers'
import styled from 'styled-components'
import GetToken from 'components/GetToken'
import IFTooltip from 'components/IFTooltip'
import { useIdoUser } from 'state/idos/hooks'
import WithdrawModal from './WithdrawModal'
import { RoundedButton, VerifiedLabel } from './StyledComponent'
import StakeModal from './StakeModal'

type StakingInformationCardProps = {
  isPrivateProject: boolean
  shouldActivateKYC: boolean
  masterAddress: string
  staking?: {
    trackId: number
    tokenName: string
    tokenDecimals?: number
  }
}

const ExternalLinkWrap = styled.a`
  text-decoration: none;
  margin: auto;
  color: #0ac6e5;
`

const SynapsText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #a6a8aa;
`

const ButtonContainer = styled.div<{ isSmall: boolean }>`
  align-self: end;
  display: flex;
  ${({ isSmall }) => {
    if (isSmall) {
      return `
      width:100%;
      margin-top:10px;
      button {
        flex-grow:1;
        &:first-child {
          margin-right: 20px;
        }
      }
      `
    }
    return `
      button {
        width: 80px;
        &:first-child {
          margin-right: 14px;
        }
      }
    `
  }}
`

const StakingInformationCard: React.FC<StakingInformationCardProps> = ({
  isPrivateProject,
  shouldActivateKYC,
  staking,
  masterAddress,
}) => {
  const stakedAmountInWei = useStakeAmount(masterAddress, staking.trackId)
  const { status: kycStatus } = useKycStatus()

  return (
    <Box paddingX={4} paddingY={6}>
      <Box flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Typography variant="subtitle1">Your Status</Typography>
        </Box>

        {shouldActivateKYC || kycStatus === KYCStatus.VERIFIED ? (
          <Box paddingX={2} paddingTop={5} paddingBottom={1}>
            <KYCOpen status={kycStatus} />{' '}
          </Box>
        ) : (
          <KYCClosed />
        )}
      </Box>
      {!isPrivateProject && (
        <>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box paddingX={2}>
            <StakedToken masterAddress={masterAddress} {...staking} stakedAmountInWei={stakedAmountInWei} />
          </Box>
        </>
      )}
    </Box>
  )
}

const KYCOpen: React.FC<{ status: KYCStatus }> = ({ status }) => {
  const { theme } = useTheme()
  const { onSession, isLoading } = useKycSession()
  const { chainId, account } = useWeb3React()

  const ifBalance = useGetBalance(getAddress(tokens.if.address, chainId), account)
  const idiaBalance = useGetBalance(getAddress(tokens.idia.address, chainId), account)
  const hasMinimumIF = ifBalance.isGreaterThanOrEqualTo(getBalanceInWei(new BigNumber(MINIMUM_IF)))
  const hasMinimumIdia = idiaBalance.isGreaterThanOrEqualTo(getBalanceInWei(new BigNumber(MINIMUM_IDIA)))

  const KYCSection = (text = 'KYC') => (
    <Box>
      <IFTooltip
        placement="right-start"
        title="This project is not open to users from U.S., China and sanctioned countries."
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2">{text}</Typography>
          <img src="/images/HelpIcon.svg" alt="help" style={{ marginLeft: '4px' }} />
        </Box>
      </IFTooltip>
    </Box>
  )

  let Component: JSX.Element
  if (!account) {
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {KYCSection('Open for KYC')}
        <Box display="flex" justifyContent="center">
          <UnlockButton />
        </Box>
      </Box>
    )
  } else if (status === KYCStatus.VERIFIED) {
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {KYCSection('KYC')}
        <Box display="flex" justifyContent="center">
          <VerifiedLabel>
            <img src="/images/kyc_verified.svg" alt="kyc" />
            <Box ml="8px">KYC Verified</Box>
          </VerifiedLabel>
        </Box>
      </Box>
    )
  } else if (!hasMinimumIF && !hasMinimumIdia) {
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="body2" style={{ width: '70%' }}>
            You need to hold at least {MINIMUM_IF} {tokens.if.symbol} or {MINIMUM_IDIA} {tokens.idia.symbol} to start
            KYC.
          </Typography>
          <GetToken address={getAddress(tokens.if.address, chainId)} symbol={tokens.if.symbol} />
        </Box>
      </Box>
    )
  } else if (status === KYCStatus.REJECTED) {
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          {KYCSection('Open for KYC')}
          <SynapsText>
            Contact <ExternalLinkWrap href="mailto:support@synaps.io">Synaps</ExternalLinkWrap> for KYC support.
          </SynapsText>
        </Box>
        <RoundedButton variant="contained" color="secondary" disableElevation>
          Resubmit
        </RoundedButton>
      </Box>
    )
  } else if (status === KYCStatus.FINAL_REJECTED) {
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          {KYCSection('Open for KYC')}
          <SynapsText>
            Contact{' '}
            <ExternalLinkWrap target="_blank" rel="noopener noreferrer" href="mailto:support@synaps.io">
              Synaps
            </ExternalLinkWrap>{' '}
            for KYC support.
          </SynapsText>
        </Box>
        <RoundedButton variant="contained" color="primary" disabled disableElevation>
          Rejected
        </RoundedButton>
      </Box>
    )
  } else {
    // NONE || PENDING
    Component = (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          {KYCSection('Open for KYC')}
          <SynapsText>
            Contact <ExternalLinkWrap href="mailto:support@synaps.io">Synaps</ExternalLinkWrap> for KYC support.
          </SynapsText>
        </Box>
        <RoundedButton
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={onSession}
          style={
            status === KYCStatus.PENDING ? { backgroundColor: theme.colors.primary, color: 'white', opacity: 0.5 } : {}
          }
          disableElevation
        >
          {!status || status === KYCStatus.NOT_STARTED ? 'Verify KYC' : 'Verifying'}
        </RoundedButton>
      </Box>
    )
  }

  return Component
}

const KYCClosed: React.FC = () => (
  <Box display="flex" justifyContent="center" marginBottom={4} marginTop={8}>
    <Typography variant="body2" color="textSecondary">
      KYC verification has closed
    </Typography>
  </Box>
)

type StakedTokenProps = {
  trackId: number
  tokenName: string
  stakedAmountInWei: BigNumber
  tokenDecimals?: number
  masterAddress: string
  allowStakeMore?: boolean
  projectDetail?: PublicSaleConfig
}

export const StakedToken: React.FC<StakedTokenProps> = ({
  trackId,
  masterAddress,
  tokenName,
  stakedAmountInWei,
  tokenDecimals,
  allowStakeMore = false,
  projectDetail = null,
}) => {
  const { account } = useWeb3React()
  const [onPresentWithdraw] = useModal(
    <WithdrawModal masterAddress={masterAddress} max={stakedAmountInWei} tokenName={tokenName} trackId={trackId} />,
  )
  const maxTotalStake = projectDetail?.subscribePeriod?.isLimited
    ? new BigNumber(projectDetail?.subscribePeriod?.maxTotalStake)
    : null
  const { stakingTokenBalanceInWei } = useIdoUser(projectDetail?.id) || {}

  let stakingTokenBalance = new BigNumber(0)
  let stakedAmount = new BigNumber(0)
  if (projectDetail) {
    const { decimals } = projectDetail.stakingToken
    stakedAmount = convertFromWei(stakedAmountInWei, decimals)
    if (stakingTokenBalanceInWei) {
      stakingTokenBalance = convertFromWei(stakingTokenBalanceInWei, decimals)
    }
  }

  const max = maxTotalStake
    ? BigNumber.min(maxTotalStake.minus(stakedAmount), stakingTokenBalance)
    : stakingTokenBalance

  const [onPresentStakeMore] = useModal(
    <StakeModal
      chainId={projectDetail?.chainId}
      maxTotalStake={maxTotalStake}
      masterAddress={masterAddress}
      max={max}
      tokenName={tokenName}
      trackId={trackId}
      token={projectDetail?.stakingToken}
    />,
  )
  const addBtn = useMemo(() => {
    return (
      <RoundedButton
        onClick={(e) => {
          e.preventDefault()
          onPresentStakeMore()
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        Stake
      </RoundedButton>
    )
  }, [onPresentStakeMore])
  const removeBtn = useMemo(() => {
    return (
      <RoundedButton
        onClick={(e) => {
          e.preventDefault()
          onPresentWithdraw()
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        Unstake
      </RoundedButton>
    )
  }, [onPresentWithdraw])
  const muiTheme = useMuiTheme()
  const isSmall = useMediaQuery(muiTheme.breakpoints.down('md'))
  const allowStakeMoreOnSmallDevice = allowStakeMore && isSmall
  let Component: JSX.Element
  if (!account) {
    Component = (
      <Box display="flex" justifyContent="center" p={4}>
        <Typography variant="body2" color="textSecondary">
          Please unlock your wallet first
        </Typography>
      </Box>
    )
  } else if (stakedAmountInWei.isEqualTo(0) || projectDetail?.isPrivate) {
    Component = (
      <Box
        display="flex"
        marginTop={4}
        flexDirection={allowStakeMoreOnSmallDevice ? 'column' : 'row'}
        justifyContent={allowStakeMore ? 'space-between' : 'center'}
      >
        {allowStakeMore && (
          <Box
            display="flex"
            flexDirection={allowStakeMoreOnSmallDevice ? 'row' : 'column'}
            justifyContent={allowStakeMoreOnSmallDevice ? 'space-between' : 'flex-start'}
          >
            <Text>Staked {tokenName}</Text>
            <Text>-</Text>
          </Box>
        )}
        <Box alignSelf={allowStakeMoreOnSmallDevice ? 'flex-start' : 'flex-end'}>
          <Text color="#A6A8AA" fontSize="15px" marginTop={allowStakeMoreOnSmallDevice ? '10px' : '0'}>
            No staked tokens in this pool.
          </Text>
        </Box>
      </Box>
    )
  } else
    Component = (
      <Box
        marginTop={4}
        display="flex"
        flexDirection={allowStakeMoreOnSmallDevice ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection={allowStakeMoreOnSmallDevice ? 'row' : 'column'}
          justifyContent="space-between"
          alignItems={allowStakeMoreOnSmallDevice ? 'center' : 'flex-start'}
        >
          <Box marginBottom={allowStakeMoreOnSmallDevice ? 0 : 2}>
            <Typography variant="body2" color="textSecondary">
              Staked {tokenName}
            </Typography>
          </Box>
          <Typography variant="h4">
            {convertFromWei(stakedAmountInWei, tokenDecimals).decimalPlaces(5).toNumber().toLocaleString()}
          </Typography>
        </Box>
        {allowStakeMore ? (
          <ButtonContainer isSmall={isSmall}>
            {removeBtn}
            {addBtn}
          </ButtonContainer>
        ) : (
          <Box>{removeBtn}</Box>
        )}
      </Box>
    )

  return Component
}
export default StakingInformationCard
