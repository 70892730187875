import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Countdown from 'components/Countdown'
import AddToCalendar from 'components/AddToCalendar'
import format from 'date-fns/format'
import { useTranslation } from 'contexts/Localization'

import { BorderedInfoCard } from '../../StyledComponent'

type PreClaimProps = {
  token: string
  shares: string
  endTime: Date
}

const PreClaim: React.FC<PreClaimProps> = ({ token, shares, endTime }) => {
  const { t } = useTranslation()

  return (
    <Box marginTop={13}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {/* Used to center the image */}
        <AddToCalendar
          title={`${token} Claim begins!`}
          description={`You can claim your ${token} on ${format(endTime, 'LLL do yyyy KK:mm aa OOOO')}`}
          startTime={endTime}
          hide
        />
        <img src="/images/idos/Moon.svg" width="100px" alt="time sand" />
        <AddToCalendar
          title={`${token} Claim begins!`}
          description={`You can claim your ${token} on ${format(endTime, 'LLL do yyyy KK:mm aa OOOO')} ${
            window.location.href.split('?')[0]
          }`}
          startTime={endTime}
        />
      </Box>
      <Box marginTop={6}>
        <Typography color="textSecondary" variant="subtitle1" align="center">
          You can claim your {token} in
        </Typography>
      </Box>
      <Box marginTop={5} marginBottom={5} display="flex" justifyContent="center">
        <Countdown targetTime={endTime} />
      </Box>

      <Box display="flex" justifyContent="center">
        <BorderedInfoCard style={{ padding: '24px 33px 20px 33px' }} marginTop={6}>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('Claimable Share')}
          </Typography>
          <Typography variant="h3" color="textPrimary" align="center">
            {parseFloat(shares).toLocaleString()} {token}
          </Typography>
        </BorderedInfoCard>
      </Box>
    </Box>
  )
}

export default PreClaim
