import React from 'react'
import { BonusSaleConfig } from 'state/types'
import BigNumber from 'bignumber.js'
import { useSolvNftBalance, useTotalAmountClaimable, usePurchasedUnits } from 'hooks/useSolv'
import { getBalanceNumber } from 'utils/formatBalance'
import { useBonusIdoUser } from 'state/idos/hooks'
import PreSale from './PreSale'
import PostSale from './PostSale'
import BonusPeriod from './BonusPeriod'
import Claim from './Claim'

type BonusSaleProps = {
  project: BonusSaleConfig
}

const BonusSale: React.FC<BonusSaleProps> = ({ project }) => {
  const {
    id,
    saleAddress,
    token,
    marketAddress,
    voucherAddress,
    saleId,
    saleInfo,
    saleDetails,
    postSaleStats,
  } = project

  const userData = useBonusIdoUser(id)
  const { isWhitelisted, purchasedUnits } = userData || { isWhitelisted: false, purchasedUnits: new BigNumber(0) }
  const numTokens = useSolvNftBalance(voucherAddress) // 0 => display no button, 1 => display claim button, 2 => display merge and claim button
  const claimableBalance = useTotalAmountClaimable(voucherAddress)
  const purchasedAmount = usePurchasedUnits(saleId, marketAddress)

  const now = new Date()

  if (!saleInfo) return <div>No bonus sale available</div>
  let Component

  if (now < new Date(project.startTime)) {
    Component = (
      <PreSale
        projectName={project.projectInformation.title}
        endTime={new Date(project.endTime)}
        startTime={new Date(project.startTime)}
        hasCompletedKYC={false}
      />
    )
  } else if (now > new Date(project.startTime) && now < new Date(project.endTime)) {
    Component = (
      <BonusPeriod
        marketAddress={marketAddress}
        saleId={saleId}
        id={id}
        saleToken={token}
        address={saleAddress}
        paymentToken={project.paymentToken}
        endTime={new Date(project.endTime)}
        solvSaleInfo={saleInfo}
        passedKYC={!saleInfo.useAllowList || (saleInfo.useAllowList && isWhitelisted)}
        purchasedAmount={purchasedUnits}
        saleDetails={saleDetails}
      />
    )
  } else if (numTokens > 0 || getBalanceNumber(claimableBalance) > 0 || !postSaleStats) {
    Component = (
      <Claim
        voucherAddress={voucherAddress}
        claimableShare={getBalanceNumber(claimableBalance)}
        nftBalance={numTokens}
      />
    )
  } else {
    Component = (
      <PostSale
        participants={parseInt(postSaleStats.numParticipants)}
        token={project.token.symbol}
        address={project.token.address}
        sales={parseInt(postSaleStats.numSales)}
        claimedShare={getBalanceNumber(purchasedAmount)}
      />
    )
  }
  return Component
}

export default BonusSale
