import IFAllocationSale from 'config/abi/IFAllocationSale.json'
import IFAllocationMaster from 'config/abi/IFAllocationMaster.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { fetchIsWhitelisted } from 'hooks/useWhitelistedPrivateSale'
import { PublicSaleConfig } from 'state/types'
import { Chain } from 'config/constants/types'
import { filterNullSaleAddress } from 'utils/idosHelpers'

export const fetchIdosUserHasWithdrawn = async (publicSales: PublicSaleConfig[], account: string, chainId: Chain) => {
  const nonNullableSales = filterNullSaleAddress(publicSales)

  const calls = nonNullableSales.map((idoConfig) => {
    const { saleAddress } = idoConfig
    return { address: saleAddress, name: 'hasWithdrawn', params: [account] }
  })

  const hasWithdrawn = await multicall(IFAllocationSale, calls, chainId)
  return nonNullableSales.reduce((acc, sale, idx) => {
    acc[sale.id] = hasWithdrawn[idx]
    return acc
  }, {})
}

export const fetchIdosUserPaymentReceived = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const nonNullableSales = filterNullSaleAddress(publicSales)
  const calls = nonNullableSales.map((idoConfig) => {
    const { saleAddress } = idoConfig
    return { address: saleAddress, name: 'paymentReceived', params: [account] }
  })
  const paymentReceived = await multicall(IFAllocationSale, calls, chainId)

  return nonNullableSales.reduce((acc, sale, idx) => {
    acc[sale.id] = paymentReceived[idx]
    return acc
  }, {})
}

export const fetchIdoUserPaymentTokenBalances = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const calls = publicSales.map((idoConfig) => {
    const tokenAddress = getAddress(idoConfig.paymentToken.address, chainId)
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls, chainId)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })

  return publicSales.reduce((acc, sale, idx) => {
    acc[sale.id] = parsedTokenBalances[idx]
    return acc
  }, {})
}

export const fetchIdoUserStakingTokenBalances = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const calls = publicSales.map((idoConfig: PublicSaleConfig) => {
    const { stakingToken } = idoConfig
    const tokenAddress = getAddress(stakingToken.address, chainId)
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls, chainId)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })

  return publicSales.reduce((acc, sale, idx) => {
    acc[sale.id] = parsedTokenBalances[idx]
    return acc
  }, {})
}

export const fetchIdoStakingUserAllowances = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const calls = publicSales.map((idoConfig) => {
    const { masterAddress, stakingToken } = idoConfig
    const lpContractAddress = getAddress(stakingToken.address, chainId)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterAddress] }
  })

  const rawAllowances = await multicall(erc20ABI, calls, chainId)
  const parsedAllowances = rawAllowances.map((balance) => {
    return new BigNumber(balance).toJSON()
  })

  return publicSales.reduce((acc, sale, idx) => {
    acc[sale.id] = parsedAllowances[idx]
    return acc
  }, {})
}

export const fetchUserStakeWeights = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
  currentBlockNumber: number,
) => {
  const calls = publicSales.map((idoConfig) => {
    const { masterAddress, trackId } = idoConfig
    const blockNumber = Math.min(
      idoConfig.purchasePeriod.allocSnapshotBlock || Number.MAX_SAFE_INTEGER,
      Math.max(0, currentBlockNumber - 10),
    )
    return {
      address: masterAddress,
      name: 'getUserStakeWeight',
      params: [trackId, account, blockNumber],
    }
  })

  const rawUserStakeWeights = await multicall(IFAllocationMaster.abi, calls, chainId)
  const parsedStakeWeights = rawUserStakeWeights.map((stakeWeight) => new BigNumber(stakeWeight).toJSON())
  return publicSales.reduce((acc, sale, idx) => {
    acc[sale.id] = parsedStakeWeights[idx]
    return acc
  }, {})
}

export const fetchIdoPaymentUserAllowances = async (
  publicSales: PublicSaleConfig[],
  account: string,
  chainId: Chain,
) => {
  const nonNullableSales = filterNullSaleAddress(publicSales)
  const calls = nonNullableSales.map((idoConfig) => {
    const lpContractAddress = getAddress(idoConfig.paymentToken.address, chainId)
    const { saleAddress } = idoConfig
    return { address: lpContractAddress, name: 'allowance', params: [account, saleAddress] }
  })

  const rawAllowances = await multicall(erc20ABI, calls, chainId)
  const parsedAllowances = rawAllowances.map((balance) => {
    return new BigNumber(balance).toJSON()
  })

  return nonNullableSales.reduce((acc, sale, idx) => {
    acc[sale.id] = parsedAllowances[idx]
    return acc
  }, {})
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchIdoUserWhitelisted = async (publicSales: PublicSaleConfig[], account: string, chainId: Chain) => {
  const whitelistedList = await fetchIsWhitelisted(
    account,
    publicSales.map((val) => {
      return val.saleAddress
    }),
  )

  return publicSales.reduce((acc, sale, idx) => {
    acc[sale.id] = whitelistedList[idx]
    return acc
  }, {})
}
