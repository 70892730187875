/**
 * @param {Object} periods Convert to local timezone and return dd-mm-yyyy
 * @return {string} '31-05-2000'
 */
const convertToCurrentTZ = (utcDate: string) => {
  const localDate = new Date(utcDate)
  let month = `${localDate.getMonth() + 1}`
  let day = `${localDate.getDate()}`
  const year = localDate.getFullYear()
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }

  const hour = localDate.getHours().toString().length < 2 ? `0${localDate.getHours()}` : localDate.getHours()
  const minutes = localDate.getMinutes().toString().length < 2 ? `0${localDate.getMinutes()}` : localDate.getMinutes()
  const seconds = localDate.getSeconds().toString().length < 2 ? `0${localDate.getSeconds()}` : localDate.getSeconds()

  return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`
}

export default convertToCurrentTZ
